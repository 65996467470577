import { useState } from "react";
import { typeWriter } from "../functions/typing";

export const useTypeWritterFlow = ({ flowItems, onEnd = () => {} }) => {
  const [results, setResults] = useState(flowItems.map(() => ""));

  const start = (shouldType = false) => {
    if (shouldType) {
      onEnd();
      onEnd = () => {};
    }

    return flowItems.reverse().reduce(
      (currentTypeWritter, flowItem, i) => () =>
        typeWriter({
          ...flowItem,
          setText: (text) =>
            setResults((currentResults) => {
              const newResults = [...currentResults];
              newResults[newResults?.length - 1 - i] = text;
              return newResults;
            }),
          onEnd: currentTypeWritter,
        }),
      onEnd
    );
  };

  return { results, start };
};
