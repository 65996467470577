import { createCustomer, updateCustomer } from "@/api/query/customer";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const CustomerEdit = ({ edit, setEdit }) => {
  const nameRef = useRef();
  const surnameRef = useRef();
  const passowrdRef = useRef();
  const emailRef = useRef();
  const activeRef = useRef();
  const firstLoginRef = useRef();

  const createCustomerMutation = useMutation({
    mutationFn: (value) => createCustomer(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateCustomerMutation = useMutation({
    mutationFn: (value) => updateCustomer(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.surname = surnameRef.current.value;
    payload.password = passowrdRef.current.value;
    payload.email = emailRef.current.value;
    payload.active = activeRef.current.checked;
    payload.isFirstLogin = firstLoginRef.current.checked;

    if (edit._id) {
      return updateCustomerMutation.mutate(payload);
    }

    createCustomerMutation.mutate(payload);
  };

  return (
    <>
      {(updateCustomerMutation.isLoading ||
        createCustomerMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>{edit._id ? "Edit" : "Create"} Customer</Title>
        <Wrapper onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            name="Name"
            width={100}
            required
            value={edit.name}
          />
          <Input
            inputRef={surnameRef}
            name="Surname"
            width={100}
            required
            value={edit.surname}
          />
          <Input
            inputRef={emailRef}
            name="Email"
            width={100}
            type="email"
            required
            value={edit.email}
          />
          <Input
            inputRef={passowrdRef}
            name="Password"
            width={100}
            type="password"
            value={null}
            required={!edit._id}
          />
          <Input
            inputRef={activeRef}
            name="Active"
            width={100}
            type="checkbox"
            checked={edit.active}
          />
          <Input
            inputRef={firstLoginRef}
            name="First login"
            width={100}
            type="checkbox"
            checked={edit.isFirstLogin}
          />
          <ButtonWrapper>
            <CustomSaveButton />
          </ButtonWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default CustomerEdit;
