import React, { useState } from "react";
import RobotTalkIcon from "@/common/images/robot-talk.svg";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { CONVERSATION_CONFIG } from "./constants/conversationConfig";
import {
  ContentWrapper,
  DemoSectionWrapper,
  Height,
  Logo,
  MessageBox,
} from "./demoScript.styles";

import RecordingSection1 from "@/common/recordings/section-1.mp3";
import RecordingSection1a from "@/common/recordings/section-1A.mp3";
import RecordingSection2 from "@/common/recordings/section-2.mp3";
import RecordingSection2a from "@/common/recordings/section-2A.mp3";
import Recording3 from "@/common/recordings/section-3.mp3";
import Recording4 from "@/common/recordings/section-4.mp3";
import Recording4A from "@/common/recordings/section-4A.mp3";
import Recording5A from "@/common/recordings/section-5A.mp3";
import Recording5B from "@/common/recordings/section-5B.mp3";
import Recording5C from "@/common/recordings/section-5C.mp3";
import Recording6 from "@/common/recordings/section-6.mp3";
import Recording7 from "@/common/recordings/section-7.mp3";
import Recording7B from "@/common/recordings/section-7B.mp3";

import PermisionPopUp from "./components/PermisionPopUp/PermisionPopUp";

const DemoScript = ({ setPageNumber }) => {
  const [data, setData] = useState({});
  const [conversationParts, setConversationParts] = useState(1);
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);
  const [showPermisionPopUp, setShowPermisionPopUp] = useState();

  return (
    <>
      {showPermisionPopUp && <PermisionPopUp />}
      <DemoSectionWrapper>
        <ContentWrapper>
          <Logo src={RobotTalkIcon} />
          <DemoConversationContext.Provider
            value={{
              data,
              setData,
              setConversationParts,
              showButtoReccord,
              setShowButtoReccord,
              showLoading,
              setShowLoading,
              setPageNumber,
              showText2,
              setShowText2,
              RecordingSection1,
              RecordingSection1a,
              RecordingSection2,
              RecordingSection2a,
              Recording3,
              Recording4,
              Recording4A,
              Recording5A,
              Recording5B,
              Recording5C,
              Recording6,
              Recording7,
              Recording7B,
              showPermisionPopUp,
              setShowPermisionPopUp,
            }}
          >
            <MessageBox style={{ padding: "0 5px 0px 0px" }}>
              {CONVERSATION_CONFIG.filter(
                (conv) => conv.id <= conversationParts
              ).map((conv) => conv.component)}
              <Height />
            </MessageBox>
          </DemoConversationContext.Provider>
        </ContentWrapper>
      </DemoSectionWrapper>
    </>
  );
};

export default DemoScript;
