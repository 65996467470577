import React from "react";
import PopUp from "./PopUp";
import styled from "styled-components";
import CustomDeleteButton from "./custom/buttons/CustomDeleteButton";
import CustomSaveButton from "./custom/buttons/CustomSaveButton";
import CustomEditButton from "./custom/buttons/CustomEditButton";
import { Color } from "../colors/colors";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Title = styled.h2`
  margin: 30px;
  max-width: 300px;
  text-align: center;
  color: ${Color.Dark};
`;

const ConfirmDecision = ({ setDecision, decision }) => {
  return (
    <PopUp>
      <Title> Are you sure that u want to {decision}?</Title>
      <ButtonWrapper>
        <CustomDeleteButton onClick={() => setDecision(false)} text={"No"} />
        <CustomEditButton onClick={() => setDecision(true)} text="Yes" />
      </ButtonWrapper>
    </PopUp>
  );
};

export default ConfirmDecision;
