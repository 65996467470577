import React from "react";
import Header from "../Header2";
import BaseHeader from "../BaseHeader";

const Layout = ({ children, newHeader = false }) => {
  return (
    <>
      {newHeader ? <BaseHeader /> : <Header />}
      <main>{children}</main>
    </>
  );
};

export default Layout;
