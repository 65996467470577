import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import { getBase64 } from "@/common/functions/getBase64";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import Image from "@/common/icons/image-icon.svg";
import { createCategory, updateCategory } from "@/api/query/category";
import {
  createFlashcardCategory,
  updateFlashcardCategory,
} from "@/api/query/flashcard-category";
import SelectInput from "@/common/components/custom/SelectInput";
import Loading from "@/common/components/Loading";
import { getWordLessonTopType } from "@/api/query/word-lesson-top-type";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
`;

const SaveButton = styled.button`
  margin: 10px 0 5px 0;
  align-self: center;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #3280fc;
  outline: none;
  border: none;
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

const AdminFlashcardCategoryEdit = ({ setEdit, edit }) => {
  const nameRef = useRef();
  const imageRef = useRef();
  const [hobbies, setHobbies] = useState();
  const [selectedHobby, setSelectedHobby] = useState();
  const [image, setImage] = useState(edit?.file?.base64);

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createFlashcardCategory(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updateFlashcardCategory(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const findHobbyMutation = useMutation({
    mutationFn: (value) => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      const mapedHobbies = data.map((h) => ({ label: h.name, value: h }));
      setHobbies(() => mapedHobbies);

      if (edit?.wordLessonTopType?._id) {
        setSelectedHobby(() =>
          mapedHobbies.find((m) => m.value._id === edit.wordLessonTopType._id)
        );
      }
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();
    if (!selectedHobby) {
      return;
    }

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.wordLessonTopType = selectedHobby.value._id;
    payload.base64 = image;

    if (edit._id) {
      return updateCategoryMutation.mutate(payload);
    }

    createCategoryMutation.mutate(payload);
  };

  const handleSelectFile = async () => {
    const file = imageRef.current.files[0];
    const base64 = await getBase64(file);

    setImage(base64);
  };

  useEffect(() => {
    findHobbyMutation.mutate();
  }, []);

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading ||
        findHobbyMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Category {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={200}
            width={100}
            required
            requiredSign
            value={edit.name}
          />
          <SelectInput
            name={"Hobby"}
            selectWidth={228}
            width={100}
            selected={selectedHobby}
            setSelected={setSelectedHobby}
            options={hobbies}
          />
          <ImageContainer>
            <ImageWrapper>
              <ImageInput
                id="file-input"
                type="file"
                name="cv"
                ref={imageRef}
                onChange={handleSelectFile}
              />
              <label htmlFor="file-input">
                <img
                  alt=""
                  src={image || Image}
                  style={{ width: "100%", height: "auto" }}
                />
              </label>
            </ImageWrapper>
          </ImageContainer>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminFlashcardCategoryEdit;
