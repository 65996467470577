import React, { useContext, useEffect, useState } from "react";
import { Wrapper } from "./Home.styles";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { findUserInfoForDashboard } from "@/api/query/customer";
import Loading from "@/common/components/Loading";
import ImageDefault from "@/common/icons/image-icon.svg";
import MicSpeak from "@/common/icons/mic-speak.png";
import FlashCardHome from "@/common/icons/flashcard_home.png";
import WordsHome from "@/common/icons/word_home.png";
import CustomBar from "@/common/charts/Bar";
import FiledStar from "@/common/icons/filed-star.svg";
import BlankStar from "@/common/icons/blank-star.svg";
import FotoMode from "@/common/icons/foto_mode_home.png";
import useQueryMemo from "@/common/hooks/urlMemo";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { english } from "@/common/data/english";

const CurrentWrapper = styled(Wrapper)`
  @media (min-width: 600px) {
    gap: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-y: auto;
  }

  @media (max-width: 600px) {
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;

  @media (max-width: 600px) {
    justify-content: left;
    margin-left: 30px;
  }
`;

const ImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const UserName = styled.h2`
  margin-left: 20px;
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
`;

const MiddleInformations = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 80px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

const ChatInfo = styled.p`
  margin: 0;
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  text-align: left;
`;
const ChatBar = styled(Link)`
  margin-top: 10px;
  display: inline-flex;
  padding: 6px 6px 6px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 297px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
  text-decoration: none;

  @media (max-width: 600px) {
    gap: 200px;
  }
`;

const ChatText = styled.div`
  color: #868686;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const DailyWordCounter = styled.div`
  width: 380px;
  height: fit-content;
  flex-shrink: 0;
  padding: 20px 15px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);

  @media (max-width: 600px) {
    width: 310px;
  }
`;

const SliderBarInfo = styled.div`
  color: #868686;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-align: center;
`;

const BarBlank = styled.div`
  width: 370px;
  height: 20px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 31px;
  background: rgba(79, 167, 180, 0.2);

  @media (max-width: 600px) {
    width: 300px;
  }
`;

const BarFill = styled.div`
  width: 0px;
  margin-top: -20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 19px;
  background: #01d894;
`;

const ForYou = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const SubTitle = styled.p`
  margin: 0;
  color: #05275d;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
`;

const TaskWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 600px) {
    gap: 20px;
    justify-content: left;
    margin-left: 10%;
  }

  @media (max-width: 400px) {
    margin-left: 5%;
  }
`;

const gradientAnimation = keyframes`
    0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const WrapperComponent = styled.div`
  border-radius: 15px;
  display: inline-block;
  padding: 4px; /* Adjust the padding as needed */
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
  background: linear-gradient(to right, #bf41fb, #06cff1, #8befd1);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 2s linear infinite;
`;

const TaskElement = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  width: 150px;
  border-radius: 20px; /* Make it a circle with rounded corners */
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const TaskTitle = styled.h3`
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */

  @media (max-width: 600px) {
    margin-top: -10px;
  }
`;

const BlankSpace = styled.div`
  @media (max-width: 600px) {
    height: 20px;
  }
`;

const SubtTitleTask = styled.div`
  color: #05275d;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  width: 140px;
  margin-top: -15px;
`;

const HeadWrapper = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const YourStatistics = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px 60px;
  max-width: 800px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);

  @media (max-width: 600px) {
    padding: 20px 0px;
    margin: 10px;
  }
`;

const TitleStats = styled.h3`
  color: #05275d;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
`;

const LeftStats = styled.div``;
const RightStats = styled.div``;

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 180px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 10px;
    max-width: 350px;
  }
`;

const StarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 300px;
  gap: 20px;
`;

const StarElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const FeatureAI = styled.div`
  padding: 3px 5px 10px 5px;
  border-radius: 10px 0px 10px 0px;
  background: #fff;
  height: 8px;

  color: #05275d;
  font-family: Work Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  margin: 0;
`;

const TASK = [
  {
    title: english.app.home.scena,
    rounded: true,
    roundNumber: 0,
    link: "/lesson/",
  },
  {
    title: english.app.home.imageMode,
    image: FotoMode,
    link: "/learn/foto-mode",
  },
  {
    title: english.app.home.scena,
    rounded: true,
    roundNumber: 1,
    link: "/lesson/",
  },
  {
    title: english.app.home.words,
    image: WordsHome,
    link: "/word-lesson-type",
  },
  {
    title: english.app.home.flashcards,
    image: FlashCardHome,
    link: "/learn/new-flashcard-list/",
  },
];

const Home = () => {
  const [user, setUser] = useState();
  const [firstLogin, setFirstLogin] = useState();
  const queryMemo = useQueryMemo();

  const countWidth = (current, max) => {
    const percent = Math.min(1, current / max) * 100;
    return `${percent}%`;
  };

  const userMutation = useMutation({
    mutationFn: () => findUserInfoForDashboard(),
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });

  function getGradient(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    gradient.addColorStop(0.9, "#01D894");
    gradient.addColorStop(0, "#06CFF1");
    return gradient;
  }

  const { handleSendIPstats } = useContext(IpStatsContext);

  useEffect(() => {
    if (queryMemo.get("utm_source")) {
      if (queryMemo.get("utm_source") === "mail_lastcall") {
        handleSendIPstats(IP_STATS_ENUM.MAIL_BEFORE_EXPIRED);
      } else {
        handleSendIPstats(IP_STATS_ENUM.MAIL_UP_SALE_BACK);
      }
    }

    window.document.title = "Sarai - home";
    userMutation.mutate();
  }, []);

  return (
    <>
      {userMutation.isLoading && <Loading />}
      <CurrentWrapper>
        <TopWrapper>
          <ImageWrapper>
            <img
              style={{ width: "100%", height: "auto" }}
              src={user?.user_profile?.base64 || ImageDefault}
            />
          </ImageWrapper>
          <UserName>
            {english.app.home.hello} {user?.name}!
          </UserName>
        </TopWrapper>
        <MiddleInformations>
          <div className="animation__grow">
            <ChatInfo>What would you like to chat about today?</ChatInfo>
            <ChatBar to="/learn/chat">
              <ChatText>{english.app.home.saySth}</ChatText>
              <img src={MicSpeak} />
            </ChatBar>
          </div>
          <DailyWordCounter>
            <ChatInfo></ChatInfo>
            <div style={{ marginTop: "10px" }}>
              <BarBlank />
              <BarFill
                style={{
                  width: user
                    ? countWidth(user?.todayWord, user?.dailyWords)
                    : 0,
                }}
              />
            </div>
            <SliderBarInfo>
              {user?.todayWord} / {user?.dailyWords}
            </SliderBarInfo>
          </DailyWordCounter>
        </MiddleInformations>
        <ForYou>
          <SubTitle>{english.app.home.forYou}</SubTitle>
          <TaskWrapper>
            {user &&
              TASK.map((t) => {
                const linkId =
                  (t?.rounded && user?.rounded[t.roundNumber]?._id) || "";

                return !t.rounded ? (
                  <TaskElement
                    to={`${t.link}${linkId}`}
                    className="animation__grow"
                  >
                    <div
                      style={{
                        background: "white",
                        borderRadius: "12px",
                      }}
                    >
                      <HeadWrapper style={{ marginBottom: "5px" }}>
                        <TaskTitle style={{ marginTop: "20px" }}>
                          {t.title}
                        </TaskTitle>
                      </HeadWrapper>
                      <div
                        style={{
                          backgroundImage: `url(${t.image}`,
                          width: "150px",
                          height: "130px",
                          overflow: "hidden",
                          borderBottomLeftRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }}
                      />
                    </div>
                  </TaskElement>
                ) : (
                  <WrapperComponent className="animation__grow">
                    <TaskElement to={`${t.link}${linkId}`}>
                      <div
                        style={{ background: "white", borderRadius: "12px" }}
                      >
                        <HeadWrapper>
                          <TaskTitle
                            style={{
                              marginTop: "-10px",
                            }}
                          >
                            {t?.title}
                          </TaskTitle>
                          <SubtTitleTask
                            style={{
                              lineHeight: "1",
                            }}
                          >
                            {user?.rounded[t.roundNumber]?.name}
                          </SubtTitleTask>
                        </HeadWrapper>
                        <div
                          style={{
                            backgroundImage: `url(${
                              user?.rounded[t.roundNumber]?.image?.base64
                            })`,
                            width: "150px",
                            height: "125px",
                            overflow: "hidden",
                            backgroundPosition: "center",
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                        <FeatureAI
                          style={{
                            float: "right",
                            marginTop: "-21px",
                          }}
                        >
                          AI featured
                        </FeatureAI>
                      </div>
                    </TaskElement>
                  </WrapperComponent>
                );
              })}
          </TaskWrapper>
        </ForYou>
        {user && (
          <YourStatistics>
            <TitleStats>{english.app.home.lastActivity}</TitleStats>
            <ChartsWrapper>
              <LeftStats>
                <TaskTitle>{english.app.home.saidWords}</TaskTitle>
                <div
                  style={{
                    height: "180px",
                  }}
                >
                  <CustomBar
                    data={{
                      labels: [...user?.stats?.resultArr.map((d) => d.label)],
                      datasets: [
                        {
                          label: "BRONZE",
                          data: [...user?.stats?.resultArr.map((d) => d.words)],
                          borderRadius: 15,
                          fill: true,
                          backgroundColor: function (context) {
                            const chart = context.chart;
                            const { ctx, chartArea } = chart;
                            if (!chartArea) return;
                            return getGradient(ctx, chartArea);
                          },
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      events: [],
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            display: true,
                            stepSize: 1,
                          },
                          grid: {
                            display: false,
                            drawBorder: false,
                          },
                        },
                        x: {
                          grid: {
                            display: false,
                            drawBorder: false,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </LeftStats>
              <RightStats>
                <BlankSpace />
                <TaskTitle>{english.app.home.savedStars} </TaskTitle>
                <StarsWrapper>
                  {user?.stats?.stars?.map((s) => (
                    <StarElement>
                      <img src={s.words === 0 ? BlankStar : FiledStar} />
                      <p
                        style={{
                          margin: "0",
                          marginTop: "-50px",
                          color: "#868686",
                        }}
                      >
                        {s.words}
                      </p>
                      <p
                        style={{
                          color: "#868686",
                          margin: "0",
                          fontWeight: s.words === 0 ? "300" : "500",
                        }}
                      >
                        {" "}
                        {s.label}
                      </p>
                    </StarElement>
                  ))}
                </StarsWrapper>
              </RightStats>
            </ChartsWrapper>
          </YourStatistics>
        )}
      </CurrentWrapper>
    </>
  );
};

export default Home;
