import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "image-generator";

export const getDailyImage = async () => {
  const data = await api.get(`/${PREFIX}/daily-image`);
  return { data: data.data };
};
