export const FAQ_CONFIG = [
  {
    title: "Pytania ogólne:",
    elements: [
      {
        top: "Czym jest SarAI?",
        bottom: `SarAI to innowacyjna aplikacja mobilna, która uczy angielskiego za pomocą sztucznej inteligencji. Oferuje interaktywne rozmowy z chatbotem AI, pomagając uczącym się poprawić swoje umiejętności języka angielskiego.     `,
      },
      {
        top: "Co sprawia, że nauka z SarAI jest skuteczna?",
        bottom: `SarAI wykorzystuje zaawansowane algorytmy przetwarzania języka naturalnego i uczenia maszynowego, aby angażować użytkowników w realistyczne rozmowy po angielsku. Inteligentny Asystent SarAI odpowiada na wiadomości użytkowników, udzielając opinii i sugestii poprawy.
                  `,
      },
      {
        top: "Czy SarAI jest odpowiedni dla początkujących użytkowników?",
        bottom: `SarAI został zaprojektowany tak, aby odpowiadać uczniom wszystkich poziomów, od początkujących do zaawansowanych użytkowników. Dostosowuje trudność rozmowy w zależności od poziomu biegłości użytkownika, zapewniając optymalne doświadczenie nauki.
                  `,
      },
      {
        top: "O czym mam rozmawiać z SarAI?",
        bottom: `SarAI sam poprowadzi cię przez tematy, które uznasz za interesujące i zajmie się prowadzeniem rozmowy. Inteligentny Asystent oferuje podpowiedzi jak rozpocząć wypowiedź, by wspomóc cię w płynnej rozmowie. 
                  `,
      },
      {
        top: "Czy SarAI mówi po polsku?",
        bottom: `SarAI skupia się na nauczaniu języka angielskiego i prowadzi konwersacje po angielsku, ale rozumie też język polski. Dzięki temu zawsze możesz sprawdzić tłumaczenie wypowiedzi Inteligentnego Asystenta, a nawet odpowiedzieć po polsku.
                  `,
      },
    ],
  },
  {
    title: "Korzystanie z SarAI:",
    elements: [
      {
        top: "Gdzie mogę korzystać z SarAI?",
        bottom: `Z SarAI możesz korzystać na dowolnym urządzeniu (smartfon, tablet, laptop) podłączonym do internetu, w dowolnej przeglądarce internetowej. 
                  `,
      },
      {
        top: "Czy mogę używać SarAI w trybie offline?",
        bottom: `Nie, SarAI działa online, ponieważ opiera się na algorytmach AI i połączeniu z serwerem. 
                  `,
      },
      {
        top: "Czy mogę ćwiczyć mówienie z SarAI w czasie rzeczywistym?",
        bottom: `Tak, SarAI umożliwia praktykę mówienia w czasie rzeczywistym za pośrednictwem chatbota AI. Możesz prowadzić mówione rozmowy i otrzymywać natychmiastową opinię w celu poprawy umiejętności mówienia.
                  `,
      },
      {
        top: "Jakie są dostępne metody płatności?",
        bottom: `Dla Twojej wygody akceptujemy szeroki zakres metod płatności, w tym szybkie przelewy bankowe, BLIK oraz wszystkie główne karty płatnicze. Wybierz metodę, która jest dla Ciebie najwygodniejsza, i zacznij swoją przygodę z językiem angielskim z SarAI! 
                  `,
      },
    ],
  },
];
