import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userManager from "@/api/userManager";
import api from "@/api/api";
import bookBlank from "@/common/icons/book-blank.png";
import bookFull from "@/common/icons/book-full.png";
import userBlank from "@/common/icons/user-blank.svg";
import userull from "@/common/icons/user-full.svg";
import userBordered from "@/common/icons/user-bordered.svg";
import homeBordered from "@/common/icons/home-vector.png";
import homeFiled from "@/common/icons/home-vector-blank.png";
import bookBordered from "@/common/icons/book-bordered.svg";
import Icon from "@/common/images/icon.svg";
import {
  ContentWrapper,
  LinkWrapper,
  LinkWrapperDesktop,
  Logo,
  NavigationDesktopWrapper,
  NavigationItemDesktop,
  Wrapper,
} from "./Layout.styles";
import { useMutation } from "react-query";
import { findCustomer } from "@/api/query/customer";
import PaymentView from "./productLayout/paymentElements/PaymentView";
import FirstLogin from "./productLayout/FirstLogin";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";

const NavElements = [
  {
    name: "learn",
    navigate: "/learn",
    iconChecked: bookFull,
    icon: bookBlank,
    iconBordered: bookBordered,
    title: "Learn",
    tags: "learn, flashcard, image-mode, learning, categories, word-lesson-type, category",
  },
  {
    name: "home",
    navigate: "/home",
    iconChecked: homeBordered,
    icon: homeFiled,
    iconBordered: homeBordered,
    title: "Home",
  },
  {
    name: "profile",
    navigate: "/profile",
    iconChecked: userull,
    icon: userBlank,
    iconBordered: userBordered,
    title: "Settings",
  },
];

const { tokenManager } = api;

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <LinkWrapper>
      {NavElements.map((e, i) => (
        <div style={{ width: "25px", overflow: "hidden" }}>
          <img
            src={
              location?.pathname.split("/")[1].includes(e.name)
                ? e.iconChecked
                : e.icon
            }
            key={e.name}
            onClick={() => navigate(e.navigate)}
            style={{ width: "100%", height: "auto" }}
            alt=""
          />
        </div>
      ))}
    </LinkWrapper>
  );
};

const NavigationDesktop = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <LinkWrapperDesktop>
      {NavElements.map((e, i) => (
        <NavigationItemDesktop
          onClick={() => navigate(e.navigate)}
          isActive={
            location?.pathname.split("/")[1].includes(e.name) ||
            e?.tags?.includes(location?.pathname.split("/")[1])
          }
        >
          <span>{e.title}</span>
          <div style={{ width: "25px", overflow: "hidden" }}>
            <img
              src={
                location?.pathname.split("/")[1].includes(e.name)
                  ? e.iconBordered
                  : e.icon
              }
              key={e.name}
              style={{ width: "100%", height: "auto" }}
              alt=""
            />
          </div>
        </NavigationItemDesktop>
      ))}
    </LinkWrapperDesktop>
  );
};

const ProductLayout = ({ children }) => {
  const [user, setUser] = useState();
  const [payment, setPayment] = useState();
  const navigate = useNavigate();
  const { handleSendIPstats } = useContext(IpStatsContext);

  const handleLoadUserMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      if (data.__t !== "Customer") {
        userManager.removeUser();
        tokenManager.removeToken();
        navigate(getNavigationPath("/login"), {
          replace: true,
        });
      }

      if (payment) {
        return;
      }

      setUser(data);

      if (!data?.active) {
        if (data?.order?.expired_date) {
          window.gtag("event", "account_expired_window_pay");
          handleSendIPstats(IP_STATS_ENUM.ACCOUNT_EXPIRED_WINDOW_PAY);
        } else {
          window.gtag("event", "pay_in_application");
          handleSendIPstats(IP_STATS_ENUM.PAY_IN_APPLICATION);
        }
        navigate("/paywall");
      }

      const now = new Date();
      const accountExpiredDate = new Date(data.order.expired_date);

      if (now.getTime() > accountExpiredDate.getTime()) {
        window.gtag("event", "account_expired_window_pay");
        handleSendIPstats(IP_STATS_ENUM.ACCOUNT_EXPIRED_WINDOW_PAY);
        navigate("/paywall");
      }
    },
    onError: () => {
      userManager.removeUser();
      tokenManager.removeToken();
      navigate(getNavigationPath("/login"), { replace: true });
    },
  });

  useEffect(() => {
    handleLoadUserMutation.mutate();
    const interval = setInterval(() => {
      handleLoadUserMutation.mutate();
    }, 1000 * 60 * 60 * 10);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleSendIPstats(IP_STATS_ENUM.LOGIN_TO_SYSTEM);
  }, []);

  return (
    <>
      {!payment && user?.isFirstLogin && (
        <FirstLogin loader={handleLoadUserMutation} />
      )}
      <Wrapper>
        <Logo src={Icon} />
        <ContentWrapper>
          <NavigationDesktopWrapper>
            <NavigationDesktop />
          </NavigationDesktopWrapper>
          {children}
        </ContentWrapper>
        <Navigation />
      </Wrapper>
    </>
  );
};

export default ProductLayout;
