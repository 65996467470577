import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const DeleteButton = styled.button`
  background-color: #f44336; /* Kolor tła przycisku Delete */
  color: white; /* Kolor tekstu */
  padding: 8px 16px; /* Wielkość przycisku */
  border: none;
  border-radius: 5px; /* Zaokrąglenie krawędzi */
  cursor: pointer;
  font-size: 14px;
`;

const CustomDeleteButton = ({ onClick, text }) => {
  return (
    <DeleteButton type="button" onClick={onClick}>
      {text || <FontAwesomeIcon icon={faRemove} />}
    </DeleteButton>
  );
};

export default CustomDeleteButton;
