import { findCustomerWithStats } from "@/api/query/customer";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "../../mail/AdminMail";
import Loading from "@/common/components/Loading";
import CustomTable from "@/common/components/custom/CustomTable";
import { FROM_SOURCE_TYPES } from "@/common/constants/types";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import ConfirmDecision from "@/common/components/ConfirmDecision";
import { handleRefund } from "@/api/query/order";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { sendBeforeExpired, sendUPsaleMail } from "@/api/query/mail";
import NewCustomTable from "@/common/components/custom/NewCustomTable";

const Title = styled.h3`
  color: #05275d;
  text-align: left;
  font-family: Work Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  margin: 0 0 20px 0;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: fit-content;
  height: fit-content;
`;

export const P = styled.p`
  display: flex;
  gap: 10px;
  text-align: right;
  margin: 0;

  p {
    width: 120px;
    text-align: right;
    margin: 0;
  }

  b {
    font-weight: 600;
    color: #05275d;
  }
`;

const TableWrapper = styled.div`
  height: 400px;
  overflow-y: auto;
`;

const InfoWrapper = styled.div`
  min-width: 300px;
  height: 240px;
`;

const IPSTATS_HEADERS = ["No.", "Type", "Device", "IP", "UTMS", "Created At"];
const MAIL_HEADERS = ["No.", "Send At", "Type", "Dlivered", "Clicked"];
const PAYMENT_HEADERS = [
  "No.",
  "Price",
  "Status",
  "Pending at",
  "Canceled at",
  "Completed at",
];
const ACTIVITY_HEADERS = [
  "No.",
  "Created At",
  "Type",
  "Source",
  "Detail",
  "Message",
];

const CustomerStats = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [showDecisionConfirmation, setShowDecisionConfirmation] = useState();

  const updateCustomerMutation = useMutation({
    mutationFn: (value) => findCustomerWithStats(value),
    onSuccess: ({ data }) => {
      console.log(data);
      setData(data);
    },
  });

  const handleRefundMutation = useMutation({
    mutationFn: (value) => handleRefund(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = id;
      updateCustomerMutation.mutate(payload);
    },
  });

  const handleSendUPsaleMutation = useMutation({
    mutationFn: (value) => sendUPsaleMail(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = id;
      updateCustomerMutation.mutate(payload);
    },
  });

  const handleSendBeforeExpiredMutation = useMutation({
    mutationFn: (value) => sendBeforeExpired(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = id;
      updateCustomerMutation.mutate(payload);
    },
  });

  const handleSendBeforeExpired = () => {
    const payload = {
      email: data.user.email,
    };

    handleSendBeforeExpiredMutation.mutate(payload);
  };

  const handleSendUPsale = () => {
    const payload = {
      email: data.user.email,
    };

    handleSendUPsaleMutation.mutate(payload);
  };

  const handleDecision = (decision) => {
    setShowDecisionConfirmation(null);
    if (!decision) return;

    const payload = {};
    payload.paymentId = data?.payment?._id;

    handleRefundMutation.mutate(payload);
  };

  useEffect(() => {
    if (id) {
      const payload = {};
      payload._id = id;
      updateCustomerMutation.mutate(payload);
    }
  }, [id]);

  return (
    <>
      {(updateCustomerMutation.isLoading ||
        handleSendUPsaleMutation.isLoading) && <Loading />}
      {showDecisionConfirmation && (
        <ConfirmDecision
          decision={showDecisionConfirmation}
          setDecision={handleDecision}
        />
      )}
      {data && (
        <>
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            <CustomEditButton
              onClick={handleSendUPsale}
              text={"SEND MAIL UPSALE"}
            />
            <CustomEditButton
              onClick={handleSendBeforeExpired}
              text={"SEND MAIL BEFORE EXPIRED"}
            />
            {data?.payment?.status === "COMPLETED" && (
              <CustomDeleteButton
                text="Refund"
                onClick={() => setShowDecisionConfirmation("REFUND")}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Card>
              <Title>User Details:</Title>
              <InfoWrapper>
                <P>
                  <p>Customer: </p>
                  <b>
                    {data?.user?.name} {data?.user?.surname}
                  </b>
                </P>
                <P>
                  <p> Email:</p> <b>{data?.user?.email}</b>
                </P>
                <P>
                  <p>Creation:</p> <b>{formatDate(data?.user?.created_at)}</b>
                </P>
                <P>
                  <p>Active:</p> <b>{data?.user?.active ? "Yes" : "No"}</b>
                </P>
                <P>
                  <p>First Login:</p>{" "}
                  <b>{data?.user?.isFirstLogin ? "No" : "Yes"}</b>
                </P>
                <P>
                  <p>Level:</p> <b>{data?.user?.level}</b>
                </P>
                <P>
                  <p>Hobby:</p>{" "}
                  <b>
                    {data?.user?.hobby?.map((h) => h.name)?.join(" ,") ||
                      "-----"}
                  </b>
                </P>
                <P>
                  <p>Speaker:</p> <b>{data?.user?.speaker?.name || "-----"}</b>
                </P>
                <P>
                  <p>Native Lang:</p>{" "}
                  <b>{data?.user?.nativeLanguage?.name || "-----"}</b>
                </P>
                <P>
                  <p>Page Url</p> <b>{data?.user?.pageUrl || "-----"}</b>
                </P>
                <P>
                  <p>Daily Words</p> <b>{data?.user?.dailyWords || "-----"}</b>
                </P>
              </InfoWrapper>
            </Card>
            <Card>
              <Title>Utms:</Title>
              <InfoWrapper>
                <P>
                  <p>Campaign: </p>
                  <b>{data?.user?.utm?.utm_campaign?.toString() || "------"}</b>
                </P>
                <P>
                  <p>Medium: </p>
                  <b>{data?.user?.utm?.utm_medium?.toString() || "------"}</b>
                </P>
                <P>
                  <p>Source: </p>
                  <b>{data?.user?.utm?.utm_source?.toString() || "------"}</b>
                </P>
                <P>
                  <p>Content: </p>
                  <b>{data?.user?.utm?.utm_content?.toString() || "------"}</b>
                </P>
                <P>
                  <p>Term: </p>
                  <b>{data?.user?.utm?.utm_term?.toString() || "------"}</b>
                </P>
                <P>
                  <p>Fbclid: </p>
                  <b>{data?.user?.utm?.utm_fbclid?.toString() || "------"}</b>
                </P>
              </InfoWrapper>
            </Card>
            <Card>
              <Title>User Cost:</Title>
              <InfoWrapper>
                <P>
                  <p>Total Price:</p>{" "}
                  <b>
                    {" "}
                    {Math.round(data?.user?.totalCost * 100000) / 100000 ||
                      "-----"}{" "}
                    $
                  </b>
                </P>
                <P>
                  <p> Token in:</p>{" "}
                  <b>
                    {" "}
                    {data?.user?.chatGptCost?.input || "-----"} (
                    {Math.round(
                      ((data?.user?.chatGptCost?.input * 0.5) / 1_000_000) *
                        100000
                    ) / 100000 || "-----"}{" "}
                    $)
                  </b>
                </P>
                <P>
                  <p> Token out:</p>{" "}
                  <b>
                    {" "}
                    {data?.user?.chatGptCost?.output || "-----"} (
                    {Math.round(
                      ((data?.user?.chatGptCost?.output * 1.5) / 1_000_000) *
                        100000
                    ) / 100000 || "-----"}{" "}
                    $)
                  </b>
                </P>
                <P>
                  <p> Speach to text: </p>{" "}
                  <b>
                    {" "}
                    {data?.user?.chatGptCost?.seconds || "-----"} s (
                    {Math.round(
                      ((data?.user?.chatGptCost?.seconds * 0.006) / 60) * 100000
                    ) / 100000 || "-----"}{" "}
                    $)
                  </b>
                </P>
                <P>
                  <p> Text to speach:</p>{" "}
                  <b>
                    {" "}
                    {data?.user?.chatGptCost?.text_to_speach || "-----"} chars (
                    {Math.round(
                      ((data?.user?.chatGptCost?.text_to_speach * 15) /
                        1_000_000) *
                        100000
                    ) / 100000 || "-----"}{" "}
                    $)
                  </b>
                </P>
              </InfoWrapper>
            </Card>
            <Card>
              <Title>Stats: </Title>
              <InfoWrapper>
                <P>
                  <p>Speak:</p>
                  <b>
                    {data.stats.reduce(
                      (prev, next) =>
                        prev + (next.source == FROM_SOURCE_TYPES.SPEAK ? 1 : 0),
                      0
                    )}
                  </b>
                </P>
                <P>
                  <p>Write:</p>
                  <b>
                    {data.stats.reduce(
                      (prev, next) =>
                        prev + (next.source == FROM_SOURCE_TYPES.WRTIE ? 1 : 0),
                      0
                    )}
                  </b>
                </P>
                <P>
                  <p>Hint:</p>
                  <b>
                    {data.stats.reduce(
                      (prev, next) =>
                        prev + (next.source == FROM_SOURCE_TYPES.HINT ? 1 : 0),
                      0
                    )}
                  </b>
                </P>
              </InfoWrapper>
            </Card>
            <Card>
              <Title>Order:</Title>
              <InfoWrapper>
                <P>
                  <p>Plan: </p>
                  <b>{data?.order?.plan_type || "------"}</b>
                </P>
                <P>
                  <p>Price: </p>
                  <b>{data?.order?.price / 100 || "------"} zł </b>
                </P>
                <P>
                  <p>Created At: </p>
                  <b>{formatDate(data?.order?.created_at) || "------"}</b>
                </P>
                <P>
                  <p>Expired At: </p>
                  <b>{formatDate(data?.order?.expired_date) || "------"}</b>
                </P>
              </InfoWrapper>
            </Card>
            <Card>
              <Title>Payment: </Title>
              <TableWrapper>
                <CustomTable
                  headers={PAYMENT_HEADERS}
                  data={data.payment
                    .sort(
                      (a, b) =>
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime()
                    )
                    .map((m, i) => [
                      i + 1,
                      m.price / 100,
                      m.status,
                      formatDate(m.status_pending_date || m.created_at),
                      formatDate(m.status_canceled_date),
                      formatDate(m.status_complated_date),
                    ])}
                />
              </TableWrapper>
            </Card>
            <Card>
              <Title>Mails: </Title>
              <TableWrapper>
                <CustomTable
                  headers={MAIL_HEADERS}
                  data={data.mails
                    .sort(
                      (a, b) =>
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime()
                    )
                    .map((m, i) => [
                      i + 1,
                      formatDate(m.created_at),
                      m.mailType,
                      formatDate(m.delivered_at),
                      formatDate(m.first_open_at),
                    ])}
                />
              </TableWrapper>
            </Card>
            <Card>
              <Title>IP Stats: </Title>
              <TableWrapper>
                <NewCustomTable
                  headers={IPSTATS_HEADERS}
                  data={data?.ipStats
                    .sort(
                      (a, b) =>
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime()
                    )
                    .map((m, i) => [
                      i + 1,
                      m.IpStatsType,
                      m.device_type,
                      m.ip_name,
                      `${Object.entries(m.utm)
                        .filter((f) => f[1])
                        .map((element) => `${element[0]}: ${element[1]}`)
                        .join(", ")}`,
                      formatDate(m.created_at),
                    ])}
                />
              </TableWrapper>
            </Card>
            <Card>
              <Title>Activity: </Title>
              <NewCustomTable
                headers={ACTIVITY_HEADERS}
                data={data.stats
                  .sort(
                    (a, b) =>
                      new Date(b.created_at).getTime() -
                      new Date(a.created_at).getTime()
                  )
                  .map((m, i) => [
                    i + 1,
                    formatDate(m.created_at),
                    m.type,
                    m.source,
                    m?.lesson?.name ||
                      m?.wordLessonType?.name ||
                      m?.flashcardCategories?.name ||
                      "------",
                    m.message,
                  ])}
                csvData={data.stats
                  .sort(
                    (a, b) =>
                      new Date(b.created_at).getTime() -
                      new Date(a.created_at).getTime()
                  )
                  .map((m, i) => [
                    i + 1,
                    formatDate(m.created_at),
                    m.type,
                    m.source,
                    m.lesson?.name ||
                      m.wordLessonType?.name ||
                      m?.flashcardCategories?.name ||
                      "------",
                    m.message,
                  ])}
              />
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerStats;
