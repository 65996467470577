import { clickBunner } from "@/api/query/lead-history";
import React from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const Wrapper = styled.a`
  position: fixed;
  bottom: 0px;
  color: #eefdff;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: all-small-caps;
  letter-spacing: -0.48px;
  padding: 15px 0 19px 0;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  background: #d47070;
  z-index: 99999999;
  text-decoration: none;
`;

const BottomElement = () => {
  const clickBannerMutation = useMutation({
    mutationFn: (value) => clickBunner(value),
  });

  const handleClick = () => {
    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");
    clickBannerMutation.mutate(payload);
  };

  return (
    <Wrapper href="#plan-offer" onClick={() => handleClick()}>
      Rozpocznij z <b>60%</b> zniżką
    </Wrapper>
  );
};

export default BottomElement;
