import React from "react";

const AnimationRecording = () => {
  return (
    <div>
      <div
        style={{
          float: "right",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          background: "#8BEFD2",
          width: "fit-content",
          padding: "10px",
          borderRadius: "15px",
        }}
      >
        <div
          className="talk_animation"
          style={{ animationDelay: "0.1s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.2s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.3s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.4s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.5s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.1s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.2s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.3s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.4s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.5s" }}
        ></div>
        <div
          className="talk_animation"
          style={{ animationDelay: "0.1s" }}
        ></div>
      </div>
    </div>
  );
};

export default AnimationRecording;
