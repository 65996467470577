import { styled } from "styled-components";

export const Wrapper = styled.section`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  gap: 40px;
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 0;

  @media (max-width: 600px) {
    margin: -50px 0 -30px 0;
    font-size: 28px;
  }
`;

export const CustomImage = styled.img`
  @media (max-width: 600px) {
    transform: scale(0.8);
  }
`;

export const ContentDisplay = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    max-width: 300px;
    gap: 0px;
    margin-top: -20px;
  }
`;
export const Element = styled.div`
  width: 250px;
  display: inline-flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 600px) {
    width: 300px;
  }
`;

export const TitleElement = styled.p`
  color: #0da9ab;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  @media (max-width: 600px) {
    color: #0da9ab;
    text-align: center;
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const TextElemenet = styled.p`
  width: 250px;
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  margin: 0;
`;

export const TextElemenetDown = styled(TextElemenet)`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const StartLearningLink = styled.a`
  text-decoration: none;
  display: inline-flex;
  padding: 17px 47px 14px 46px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--iris, #4b4ded);
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;
