import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Navigation from "./components/Navigation";
import { findCustomer } from "@/api/query/customer";
import SaraiLogo from "./icons/sarai.svg";
import styled from "styled-components";
import PlanView from "./components/PlanView";
import PaymentView from "./components/PaymentView";
import { RegisterContext } from "./context/register.context";

const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 600px) {
    background-color: white;
    width: 90vw;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 60px 0px rgba(79, 167, 180, 0.2);
    max-width: 1200px;
  }
`;

const SaraiLogoImg = styled.img`
  margin-top: 10px;

  @media (max-width: 600px) {
    width: 40px;
    margin-top: 0px;
  }
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px 0 0px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 600px) {
    gap: 20px;
  }
`;

const CONFIG = [
  {
    name: "Plan",
    compontent: () => <div></div>,
  },
  {
    name: "Plan",
    compontent: () => <PlanView />,
  },
  {
    name: "Payment",
    compontent: () => <PaymentView />,
  },
];

const Paywall = () => {
  const [user, setUser] = useState();
  const [view, setView] = useState(1);
  const [plan, setPlan] = useState();

  document.title = `Sarai -  ${CONFIG[view]?.name}`;

  const findUserMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });

  useEffect(() => {
    findUserMutation.mutate();
  }, []);

  return (
    <Wrapper>
      <SaraiLogoImg src={SaraiLogo} />
      <WrapperItems>
        <RegisterContext.Provider
          value={{ setView, user, setUser, plan, setPlan }}
        >
          <Navigation level={view} />
          {CONFIG[view]?.compontent()}
        </RegisterContext.Provider>
      </WrapperItems>
    </Wrapper>
  );
};

export default Paywall;
