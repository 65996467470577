import { addMailLeadHistory } from "@/api/query/lead-history";
import PopUp from "@/common/components/PopUp";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  /* H3 */
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

const Wrapper = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    width: 320px;
  }
`;

const StopInfo = styled.p`
  color: #0b2f45;
  text-align: center;
  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
`;

const Info = styled.div`
  color: #0b2f45;
  width: 700px;
  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */

  @media (max-width: 600px) {
    width: 300px;
    text-align: center;
  }
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 25px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LetMailButton = styled.button`
  display: flex;
  width: 188px;
  height: 60px;
  padding: 21px 43px 24px 44px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #3280fc;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  /* Button */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.1);
  }
`;

const Label = styled.label`
  width: 55px;
  height: 22px;
  flex-shrink: 0;
  color: #0b2f45;
  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
`;

const Input = styled.input`
  width: 466px;
  padding: 10px;
  flex-shrink: 0;
  border-radius: 16px;
  background: rgba(79, 167, 180, 0.2);
  border: none;
  outline: none;

  @media (max-width: 600px) {
    width: 280px;
  }
`;

const BuyPopUp = ({ setClose, price }) => {
  const emailRef = useRef();
  const navigate = useNavigate();

  const addEmailMutation = useMutation({
    mutationFn: (value) => addMailLeadHistory(value),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    window.gtag("event", "complete_registration", { price: price });

    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");
    payload.email = email;

    addEmailMutation.mutate(payload);
    setClose(false);
  };

  return (
    <PopUp setClose={setClose}>
      <Wrapper>
        <Title>Wyjątkowe zainteresowanie SarAI! 🎉</Title>
        <Info>
          Ze względu na ogromne zainteresowanie, w tej chwili mamy{" "}
          <b>wyprzedane wszystkie dostępne subskrypcje.</b> <br></br> <br></br>
          Zostaw swój adres e-mail. Damy Ci znać, kiedy będziemy gotowi przyjąć
          nowych użytkowników!
        </Info>
        <ActionWrapper onSubmit={(e) => handleSubmit(e)}>
          <InputWrapper>
            <Label>Email</Label>
            <Input ref={emailRef} type="email" required />
          </InputWrapper>
          <LetMailButton id="complete_registration" type="submit">
            ZAPISZ SIE
          </LetMailButton>
        </ActionWrapper>
      </Wrapper>
    </PopUp>
  );
};

export default BuyPopUp;
