import userManager from "@/api/userManager";
import PopUp from "@/common/components/PopUp";
import { LEVELS } from "@/pages/landing/components/DemoScript/components/LevelMessage/levelMessage.constants";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { getWordLessonTopType } from "@/api/query/word-lesson-top-type";
import Loading from "@/common/components/Loading";
import { updateUserFirstView } from "@/api/query/customer";
import { InputWrapper } from "@/pages/customer/profile/profile.styles";
import InputRange from "react-input-range";
import SliderWelcome from "./SliderWelcome";
import SelectInput from "../../custom/SelectInput";
import { findNativeLanguages } from "@/api/query/nativeLanguages";

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  margin: 0;

  @media (min-width: 600px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }
`;

const Subtitle = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  max-width: 1000px;
  margin-top: 10px;

  @media (max-width: 800px) {
    max-width: 300px;
    font-size: 12px;
  }
`;

const Question = styled.div`
  margin-top: 20px;
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */

  @media (max-width: 800px) {
    max-width: 300px;
    margin-bottom: 10px;
  }
`;

const Button = styled.button`
  margin-top: 40px;
  display: flex;
  padding: 12px 50px 12px 50px;
  justify-content: center;
  align-items: center;

  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    background-color: grey;
  }

  @media (max-width: 800px) {
    margin-top: 40px;
    padding: 12px 23px 12px 23px;
    font-size: 14px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  min-width: 800px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const WrapperMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-width: 400px;
    min-width: 250px;
  }
`;

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    align-items: center;
    padding-top: 24px;
    gap: 24px;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (min-width: 800px) {
    flex-direction: row;
    gap: 18px;
  }
`;

const HobbyWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 800px;

  @media (max-width: 800px) {
    max-width: 300px;
    justify-content: left;
  }
`;

const HobbyElement = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 10px;
  background: #d9d9d9;

  color: #000;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;

  @media (max-width: 600px) {
    padding: 6px 10px;
    font-size: 12px;
  }
`;

const InputRangeWrapper = styled.div`
  width: 340px;
  margin: 20px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    width: 300px;
    max-width: 400px;
  }
`;

const TitleArticle = styled.h3`
  color: #05275d;

  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
`;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;

  @media (max-width: 600px) {
    gap: 40px;
  }
`;

const WrapperRadio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: #05275d;
    text-align: center;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
  }
`;

const FirstLogin = ({ setClose, loader }) => {
  const [part, setPart] = useState(1);
  const [selectedLevel, setSelectedLevel] = useState(LEVELS.INTERMEDIATE);
  const [hobbies, setHobbies] = useState([]);
  const [selectedHobby, setSelectedHobby] = useState([]);
  const [range, setRange] = useState(50);
  const [nativeLanguage, setNativeLanguage] = useState();
  const [nativeLanguageOptions, setNativeLanguageOptions] = useState([]);

  const handleSaveUserPreferencesMutation = useMutation({
    mutationFn: (values) => updateUserFirstView(values),
    onSuccess: ({ data }) => {
      const user = userManager.getUser();
      user.isFirstLogin = false;
      userManager.setUser(user);
      loader.mutate();
    },
  });

  const handleSend = (payload) => {
    payload.hobbies = selectedHobby;
    payload.level = selectedLevel;
    payload.dailyWords = range;
    payload.nativeLanguage = nativeLanguage.value._id;

    handleSaveUserPreferencesMutation.mutate(payload);
  };

  const user = userManager.getUser();

  const addToSelect = (id) => {
    const current = [...selectedHobby];

    if (current.includes(id)) {
      return setSelectedHobby((prev) => prev.filter((f) => f !== id));
    }

    if (current.length === 3) {
      return;
    }
    current.push(id);
    setSelectedHobby(() => [...new Set(current)]);
  };

  const loadHobbyMutation = useMutation({
    mutationFn: () => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      setHobbies(data);
    },
  });
  const loadNativeLanguagesMutation = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({
        label: (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              height: "20px",
            }}
          >
            <div style={{ height: "20px" }}>
              <img
                src={d?.icon?.base64}
                style={{ width: "auto", height: "100%" }}
              />
            </div>
            {d.name}
          </div>
        ),
        value: d,
      }));

      setNativeLanguageOptions(() => options);
      setNativeLanguage(() => options[0]);
    },
  });

  useEffect(() => {
    loadHobbyMutation.mutate();
    loadNativeLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(loadHobbyMutation.isLoading ||
        handleSaveUserPreferencesMutation.isLoading) && <Loading />}
      <PopUp setClose={() => {}} closeIcon={false}>
        <Wrapper>
          {part === 1 ? (
            <>
              <Title>Hi {user.name}!</Title>
              <Subtitle>
                Before we begin, please help me customize your English path.
              </Subtitle>
              <Question>1. How would you rate your level of English?</Question>
              <LevelWrapper>
                <LevelOptionsWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.BEGINNER}>
                    <input
                      type="radio"
                      id="beginner"
                      checked={selectedLevel === LEVELS.BEGINNER}
                      onChange={() => setSelectedLevel(LEVELS.BEGINNER)}
                    />
                    <label htmlFor="beginner">Beginner (A1)</label>
                  </InputWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.BASIC}>
                    <input
                      type="radio"
                      id="basic"
                      checked={selectedLevel === LEVELS.BASIC}
                      onChange={() => setSelectedLevel(LEVELS.BASIC)}
                    />
                    <label htmlFor="basic">Beginner (A2)</label>
                  </InputWrapper>
                  <InputWrapper
                    isActive={selectedLevel === LEVELS.INTERMEDIATE}
                  >
                    <input
                      type="radio"
                      id="middle"
                      isActive={selectedLevel === LEVELS.INTERMEDIATE}
                      checked={selectedLevel === LEVELS.INTERMEDIATE}
                      onChange={() => setSelectedLevel(LEVELS.INTERMEDIATE)}
                    />
                    <label htmlFor="middle">Intermediate (B1)</label>
                  </InputWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.ADVANCED}>
                    <input
                      type="radio"
                      isActive={selectedLevel === LEVELS.ADVANCED}
                      checked={selectedLevel === LEVELS.ADVANCED}
                      id="advance"
                      onChange={() => setSelectedLevel(LEVELS.ADVANCED)}
                    />
                    <label htmlFor="advance">Advanced (B2)</label>
                  </InputWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.EXPERT}>
                    <input
                      type="radio"
                      isActive={selectedLevel === LEVELS.EXPERT}
                      checked={selectedLevel === LEVELS.EXPERT}
                      id="expert"
                      onChange={() => setSelectedLevel(LEVELS.EXPERT)}
                    />
                    <label htmlFor="expert">Expert (C1+)</label>
                  </InputWrapper>
                </LevelOptionsWrapper>
              </LevelWrapper>
              <Question>2. Choose your native language:</Question>
              <SelectInput
                showLabel={false}
                options={nativeLanguageOptions}
                fontSize="12px"
                transform={0.95}
                selected={nativeLanguage}
                setSelected={setNativeLanguage}
              />
              <Question>3. Set a daily target for spoken words:</Question>
              <InputRangeWrapper>
                <InputRange
                  maxValue={150}
                  minValue={0}
                  step={50}
                  value={range}
                  onChange={setRange}
                />
              </InputRangeWrapper>
              <Question>
                4. Choose 3 topics that interest you the most:
              </Question>
              <HobbyWrapper>
                {hobbies.map((h) => (
                  <HobbyElement
                    onClick={() => addToSelect(h._id)}
                    style={{
                      cursor: "pointer",
                      background: selectedHobby.includes(h._id)
                        ? "#8BEFD1"
                        : "#D9D9D9",
                    }}
                  >
                    <img src={h.file?.base64} width={12} />
                    {h.name}
                  </HobbyElement>
                ))}
              </HobbyWrapper>
              <Button
                disabled={!selectedLevel || selectedHobby.length !== 3}
                onClick={() => setPart(2)}
                style={{ marginTop: "30px" }}
              >
                Dalej
              </Button>
            </>
          ) : (
            <>
              <SliderWelcome handleSend={handleSend} />
            </>
          )}
        </Wrapper>
        <WrapperMobile>
          {part === 1 ? (
            <>
              <Title>Hi {user.name}!</Title>
              <Subtitle>
                Before we begin, please help me customize your English path.
              </Subtitle>
              <Question>How would you rate your level of English?</Question>
              <LevelWrapper>
                <LevelOptionsWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.BEGINNER}>
                    <input
                      type="radio"
                      name="price"
                      id="beginner"
                      checked={selectedLevel === LEVELS.BEGINNER}
                      onChange={() => setSelectedLevel(LEVELS.BEGINNER)}
                    />
                    <label for="beginner">Beginner (A1)</label>
                  </InputWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.BASIC}>
                    <input
                      type="radio"
                      name="price"
                      id="basic"
                      checked={selectedLevel === LEVELS.BASIC}
                      onChange={() => setSelectedLevel(LEVELS.BASIC)}
                    />
                    <label for="basic">Basic (A2)</label>
                  </InputWrapper>
                  <InputWrapper
                    isActive={selectedLevel === LEVELS.INTERMEDIATE}
                  >
                    <input
                      type="radio"
                      name="price"
                      id="middle"
                      isActive={selectedLevel === LEVELS.INTERMEDIATE}
                      checked={selectedLevel === LEVELS.INTERMEDIATE}
                      onChange={() => setSelectedLevel(LEVELS.INTERMEDIATE)}
                    />
                    <label for="middle">Intermediate (B1)</label>
                  </InputWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.ADVANCED}>
                    <input
                      type="radio"
                      name="price"
                      isActive={selectedLevel === LEVELS.ADVANCED}
                      checked={selectedLevel === LEVELS.ADVANCED}
                      id="advance"
                      onChange={() => setSelectedLevel(LEVELS.ADVANCED)}
                    />
                    <label for="advance">Advanced (B2)</label>
                  </InputWrapper>
                  <InputWrapper isActive={selectedLevel === LEVELS.EXPERT}>
                    <input
                      type="radio"
                      name="price"
                      isActive={selectedLevel === LEVELS.EXPERT}
                      checked={selectedLevel === LEVELS.EXPERT}
                      id="expert"
                      onChange={() => setSelectedLevel(LEVELS.EXPERT)}
                    />
                    <label for="expert">Expert (C1+)</label>
                  </InputWrapper>
                </LevelOptionsWrapper>
              </LevelWrapper>
              <Question>Choose your native language:</Question>
              <SelectInput
                showLabel={false}
                options={nativeLanguageOptions}
                fontSize="12px"
                transform={0.85}
                selected={nativeLanguage}
                setSelected={setNativeLanguage}
              />
              <Button onClick={() => setPart(2)}>Get started</Button>
            </>
          ) : part === 2 ? (
            <>
              <Subtitle>
                At SarAI, you learn by talking. Set your daily goal for the
                number of words spoken to track your progress.
              </Subtitle>
              <Question>Set a daily target for spoken words</Question>
              <InputRangeWrapper style={{ marginTop: "40px" }}>
                <InputRange
                  maxValue={150}
                  minValue={0}
                  step={50}
                  value={range}
                  onChange={setRange}
                />
              </InputRangeWrapper>
              <Button onClick={() => setPart(3)}>Next</Button>
            </>
          ) : part === 3 ? (
            <>
              <Question>Choose 3 topics that interest you the most:</Question>
              <HobbyWrapper>
                {hobbies.map((h) => (
                  <HobbyElement
                    onClick={() => addToSelect(h._id)}
                    style={{
                      cursor: "pointer",
                      background: selectedHobby.includes(h._id)
                        ? "#8BEFD1"
                        : "#D9D9D9",
                    }}
                  >
                    <img src={h.file?.base64} width={10} />
                    {h.name}
                  </HobbyElement>
                ))}
              </HobbyWrapper>
              <Button
                disabled={!selectedLevel || selectedHobby.length !== 3}
                onClick={() => setPart(4)}
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <SliderWelcome handleSend={handleSend} />
            </>
          )}
        </WrapperMobile>
      </PopUp>
    </>
  );
};

export default FirstLogin;
