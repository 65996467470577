import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow: hidden;
  text-align: center;
  margin: 0px 20px 0 20px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    margin: 0;
    width: 100%;
    height: fit-content;
    position: relative;
  }
`;
