import CustomTable from "@/common/components/custom/CustomTable";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { getIpStatsByDates } from "@/api/query/ipStats";
import { getUserUsageStats } from "@/api/query/workflow";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Color } from "@/common/colors/colors";
import CSV from "@/common/components/CSV";
import moment from "moment";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const HEADERS = [
  "User",
  "Amount",
  "Days",
  "Last activity Date",
  "Payment Date",
  "Expired Date",
  "Actions",
];
const HEADERS_CSV = [
  "User",
  "Amount",
  "Days",
  "Last activity Date",
  "Payment Date",
  "Expired Date",
];

const HEADERS_TWO = ["Days", "Amount"];

const AdminUsage = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataCsv, setTableDataCsv] = useState([]);

  const [tableDataTwo, setTableDataTwo] = useState([]);

  const calcData = (data) => {
    const calcMap = new Map();

    data.forEach((d) => {
      if (calcMap.has(d.days)) {
        return calcMap.set(d.days, calcMap.get(d.days) + 1);
      }

      calcMap.set(d.days, 1);
    });

    return Array.from(calcMap, ([name, value]) => [name, value]).sort(
      (a, b) => parseInt(b[0]) - parseInt(a[0])
    );
  };

  const findStatsMutation = useMutation({
    mutationFn: () => getUserUsageStats(),
    onSuccess: ({ data }) => {
      console.log(data[0]);

      setTableData(() =>
        data
          .sort((a, b) => {
            return parseInt(b.amount) - parseInt(a.amount);
          })
          .sort((a, b) => {
            return parseInt(b.days) - parseInt(a.days);
          })
          .map((d) => [
            d.user[0]?.email,
            d.amount,
            d.days,
            (d.last_activity && moment(d.last_activity).format("DD/MM/YYYY")) ||
              "-----",
            (d.payment_date && moment(d.payment_date).format("DD/MM/YYYY")) ||
              "-----",
            (d.order?.expired_date &&
              moment(d.order?.expired_date).format("DD/MM/YYYY")) ||
              "-----",
            <>
              <FontAwesomeIcon
                icon={faEye}
                color={Color.Dark}
                onClick={() =>
                  window.open(
                    `/admin/customer/stats/${d.user[0]._id}`,
                    "_blank"
                  )
                }
              />
            </>,
          ])
      );
      setTableDataTwo(calcData(data));
      setTableDataCsv(() =>
        data
          .sort((a, b) => {
            return parseInt(b.amount) - parseInt(a.amount);
          })
          .sort((a, b) => {
            return parseInt(b.days) - parseInt(a.days);
          })
          .map((d) => [
            d.user[0]?.email,
            d.amount,
            d.days,
            d.last_activity,
            d.payment_date,
            d.order?.expired_date,
          ])
      );
      setData(data);
    },
  });

  useEffect(() => {
    findStatsMutation.mutate();
  }, []);

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <Title>Usage Stats</Title>
        <CustomTable
          headers={HEADERS_TWO}
          data={tableDataTwo}
          csvData={tableDataTwo}
        />
        <CustomTable
          headers={HEADERS}
          data={tableData}
          csvHeaders={HEADERS_CSV}
          csvData={tableDataCsv}
        />
      </Wrapper>
    </>
  );
};

export default AdminUsage;
