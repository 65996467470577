import Api from "../api";

const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "order";

export const createCustomerOrder = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);

  return { data: data.data };
};

export const findOrders = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const findOrdersLast = async (payload) => {
  const data = await api.get(`/${PREFIX}/last?days=${payload.last_days}`);

  return { data: data.data };
};

export const handleRefund = async (payload) => {
  const data = await api.post(`/${PREFIX}/refund/payment/${payload.paymentId}`);

  return { data: data.data };
};
