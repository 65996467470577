import React from "react";
import Checked from "@/common/images/group-2.svg";
import CheckedMobile from "@/common/images/group-correct-mobile.svg";
import WrongMobile from "@/common/images/group-wrong-mobile.svg";
import Wrong from "@/common/images/group-3.svg";
import {
  ContentWrapper,
  ContentWrapperMobile,
  CorrectImage,
  LeftInfo,
  LeftTitle,
  RightInfo,
  TextInfo,
  Title,
  Wrapper,
  WrapperComapring,
  WrongImage,
} from "./comparingSection.styles";
import styled from "styled-components";

const ImageWrapper = styled.div`
  width: 20px;

  img {
    width: 100%;
    height: auto;
  }
`;
const ComparingSection = ({ data }) => {
  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <WrapperComapring>
        <LeftInfo>
          <LeftTitle>{data.leftTitle}</LeftTitle>
          <ContentWrapper>
            <ImageWrapper>
              <CorrectImage src={Checked} alt="okay icon" />
            </ImageWrapper>
            <TextInfo>{data.leftDescription}</TextInfo>
          </ContentWrapper>
          <ContentWrapperMobile>
            <CorrectImage src={CheckedMobile} alt="worng icon" />
            <TextInfo>{data.leftDescriptionBottom}</TextInfo>
          </ContentWrapperMobile>
        </LeftInfo>
        <RightInfo>
          <LeftTitle>‍{data.rightTitle}</LeftTitle>
          <ContentWrapper>
            <WrongImage src={Wrong} alt="wrong" />
            <TextInfo>{data.rightDescription}</TextInfo>
          </ContentWrapper>
          <ContentWrapperMobile>
            <WrongImage src={WrongMobile} alt="wrong" />
            <TextInfo>{data.rightDescriptionBottom}</TextInfo>
          </ContentWrapperMobile>
        </RightInfo>
      </WrapperComapring>
    </Wrapper>
  );
};

export default ComparingSection;
