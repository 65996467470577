import React from "react";
import Checked from "@/common/images/group-2.svg";
import CheckedMobile from "@/common/images/group-correct-mobile.svg";
import WrongMobile from "@/common/images/group-wrong-mobile.svg";
import Wrong from "@/common/images/group-3.svg";
import {
  ContentWrapper,
  ContentWrapperMobile,
  CorrectImage,
  LeftInfo,
  LeftTitle,
  RightInfo,
  TextInfo,
  Title,
  Wrapper,
  WrapperComapring,
  WrongImage,
} from "./comparingSection.styles";

const ComparingSection = () => {
  return (
    <Wrapper>
      <Title>10 razy lepszy niż tradycyjny nauczyciel</Title>
      <WrapperComapring>
        <LeftInfo>
          <LeftTitle>🧑‍💻 SarAI</LeftTitle>
          <ContentWrapper>
            <CorrectImage src={Checked} alt="okay icon" />
            <TextInfo>
              Mówi i brzmi jak prawdziwy native speaker <br></br> Nie trzeba
              planować z wyprzedzeniem <br></br> Dostępny przez cały dzień,
              każdego dnia <br></br> Nieskończone możliwości uczenia się nowych
              tematów <br></br> Natychmiastowa informacja zwrotna na temat
              umiejętności konwersacyjnych <br></br> Brak stresu i poczucia
              niepokoju - SarAI nie ocenia.
            </TextInfo>
          </ContentWrapper>
          <ContentWrapperMobile>
            <CorrectImage src={CheckedMobile} alt="worng icon" />
            <TextInfo>
              Mówi i brzmi jak prawdziwy native speaker <br></br>
              Dostępny 24/7 <br></br>
              Nie trzeba planować lekcji z wyprzedzeniem <br></br>
              Nieskończenie wiele tematów do rozmowy <br></br>
              Brak stresu - SarAI nie ocenia
            </TextInfo>
          </ContentWrapperMobile>
        </LeftInfo>
        <RightInfo>
          <LeftTitle>‍👩‍🏫 Tradycyjny nauczyciel</LeftTitle>
          <ContentWrapper>
            <WrongImage src={Wrong} alt="wrong" />
            <TextInfo>
              Mówi ze swoim indywidualnym akcentem <br></br>Ma napięty
              harmonogram, musisz znaleźć czas, który będzie odpowiedni dla was
              obojga <br></br>Pojedyncza lekcja kosztuje 50+ zł. <br></br>
              Prowadzi tradycyjne lekcje trwające 45-60 minut, nie jest
              elastyczny<br></br> Na początku może być niezręcznie
            </TextInfo>
          </ContentWrapper>
          <ContentWrapperMobile>
            <WrongImage src={WrongMobile} alt="wrong" />
            <TextInfo>
              Indywidualny akcent <br></br>
              Napięty harmonogram <br></br>
              50+ zł za lekcję <br></br>
              Na początku może być niezręcznie
            </TextInfo>
          </ContentWrapperMobile>
        </RightInfo>
      </WrapperComapring>
    </Wrapper>
  );
};

export default ComparingSection;
