import { LEVELS } from "../LevelMessage/levelMessage.constants";
import { Howl } from "howler";

export const getGoodAnswer = (name, level, a, b, c) => {
  if (level === LEVELS.ADVANCED) {
    return {
      text: `Fantastic, ${name}! Being advanced in English means we can explore more advanced topics and focus on refining your language skills even further. `,
      audio: new Howl({
        src: [c],
      }),
      translation: `
          Fantastycznie, ${name}! Posiadanie zaawansowanego poziomu 
          języka angielskiego oznacza, że możemy zgłębiać bardziej 
          zaawansowane tematy i skupić się na jeszcze dokładniejszym 
          doskonaleniu Twoich umiejętności językowych. 
        `,
    };
  }

  if (level === LEVELS.INTERMEDIATE) {
    return {
      text: `Great, ${name}! Being at an intermediate level means we can work on expanding your vocabulary, fine-tuning your grammar, and practicing more complex conversations. `,
      audio: new Howl({
        src: [b],
      }),
      translation: `Świetnie, ${name}! Bycie na poziomie średnio zaawansowanym oznacza, 
        że możemy pracować nad poszerzaniem Twojego słownictwa, 
        doskonaleniem gramatyki oraz prowadzeniem bardziej złożonych rozmów. `,
    };
  }

  if (level === LEVELS.BEGINNER) {
    return {
      text: `Thank you for letting me know, ${name}!  As a beginner, I'll make sure to provide you with a solid foundation in English. We'll start with the basics and gradually progress as you become more comfortable with the language. `,
      audio: new Howl({
        src: [a],
      }),
      translation: `Dziękuję za informację, ${name}! Jako początkujący/początkująca, będę dbać o to, aby zapewnić Ci solidne podstawy z języka angielskiego. Zaczniemy od podstaw i stopniowo będziemy się rozwijać, w miarę jak nabierzesz większej pewności w posługiwaniu się językiem.
        `,
    };
  }
};
