import React, { useRef } from "react";
import { styled } from "styled-components";
import { Color } from "@/common/colors/colors";
import moment from "moment";
import Input from "@/common/components/custom/Input";

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({ handleSearch }) => {
  const fromRef = useRef();
  const toRef = useRef();

  const handleSearchCheck = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.from = moment(fromRef.current.value).startOf("day").toISOString();
    payload.to = moment(toRef.current.value).endOf("day").toISOString();
    handleSearch(payload);
  };

  return (
    <>
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <Input
          type="date"
          inputRef={fromRef}
          name="From date"
          inputWidth={120}
          width={100}
          required={true}
        />
        <Input
          type="date"
          inputRef={toRef}
          name="To date"
          inputWidth={120}
          width={100}
          required={true}
        />
        <SearchButton type="submit">Search</SearchButton>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
