import React from "react";
import { SubTitleMobile, Subtitle, Title, Wrapper } from "./headSection.styles";

const HeadSection = ({ data }) => {
  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <Subtitle>{data.subtitle}</Subtitle>
    </Wrapper>
  );
};

export default HeadSection;
