import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useRef, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import { findCategories } from "@/api/query/category";
import SearchBar from "./components/SearchBar";
import Loading from "@/common/components/Loading";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { getIpStatsByDates } from "@/api/query/ipStats";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const HEADERS = ["Event Name", "How much"];
const HEADERS_TWO = ["Event Name", "Amount"];

const AdminIpStats = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataTwo, setTableDataTwo] = useState([]);

  const uniqueIp = useRef();
  const uniqueUid = useRef();

  const calcDataForPassingPercent = (status, data) => {
    const uniqIP = uniqueIp?.current?.checked || false;
    const uniqUID = uniqueUid?.current?.checked || false;

    let filtred = data.filter((d) => d.IpStatsType.includes(status));

    if (!uniqIP && !uniqUID) {
      return filtred.length;
    }

    if (uniqUID) {
      let toCheck = filtred.filter((d) => d.utm?.uid).map((d) => d.utm?.uid);

      if (toCheck.length === 1) {
        filtred = toCheck;
      } else {
        filtred = [...new Set(toCheck)];
      }
    }

    if (uniqIP) {
      let toCheckIP = filtred.map((d) => d.ip_name);

      if (toCheckIP.length === 1) {
        filtred = toCheckIP;
      } else {
        filtred = [...new Set(toCheckIP)];
      }
    }
    return filtred.length;
  };

  const calcDataByStatus = (status, data) => {
    const uniqIP = uniqueIp?.current?.checked || false;
    const uniqUID = uniqueUid?.current?.checked || false;

    let filtred = data.filter((d) => d.IpStatsType === status);

    if (!uniqIP && !uniqUID) {
      return filtred.length;
    }

    if (uniqUID) {
      let toCheck = filtred.filter((d) => d.utm?.uid).map((d) => d.utm?.uid);

      if (toCheck.length === 1) {
        filtred = toCheck;
      } else {
        filtred = [...new Set(toCheck)];
      }
    }

    if (uniqIP) {
      let toCheckIP = filtred.map((d) => d.ip_name);

      if (toCheckIP.length === 1) {
        filtred = toCheckIP;
      } else {
        filtred = [...new Set(toCheckIP)];
      }
    }

    return filtred.length;
  };

  const findStatsMutation = useMutation({
    mutationFn: (values) => getIpStatsByDates(values),
    onSuccess: ({ data }) => {
      setTableData(() =>
        Object.entries(IP_STATS_ENUM).map(([key, value]) => [
          key,
          calcDataByStatus(value, data),
        ])
      );

      setTableDataTwo(() => [
        ["Landing", calcDataForPassingPercent(IP_STATS_ENUM.LANDING, data)],
        [
          "Register",
          calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_BRONZE, data) +
            calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_BUY, data) +
            calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_GOLD, data) +
            calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_PLATINUM, data),
        ],
        [
          "Account Created",
          calcDataForPassingPercent(IP_STATS_ENUM.ACCOUNT_CREATED, data),
        ],
        ["Plan", calcDataForPassingPercent(IP_STATS_ENUM.PLAN, data)],
        ["Pay", calcDataForPassingPercent(IP_STATS_ENUM.PAY, data)],
      ]);

      setData(data);
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <Title>Ip Stats</Title>
        <SearchBar
          handleSearch={handleSearch}
          uniqueIp={uniqueIp}
          uniqueUid={uniqueUid}
        />
        <CustomTable
          headers={HEADERS_TWO}
          data={tableDataTwo}
          csvData={tableDataTwo}
        />
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} csvData={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminIpStats;
