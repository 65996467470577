import { findCategories } from "@/api/query/category";
import { createLesson, updateLesson } from "@/api/query/lesson";
import ImageContainer from "@/common/components/ImageContainer";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import TextArea from "@/common/components/TextArea";
import CustomTable from "@/common/components/custom/CustomTable";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Headers = ["Number", "Name", "Action"];

const PromptEdit = ({ promptEdit, prompts, setPrompts, setPromptEdit }) => {
  const nameRef = useRef();
  const numberRef = useRef();

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.name = nameRef.current.value;
    payload.number = parseInt(numberRef.current.value);
    payload._id = promptEdit.name ? promptEdit._id : prompts.length;

    setPrompts((prev) => [
      ...prev.filter((pre) => pre._id !== payload._id),
      payload,
    ]);
    setPromptEdit(null);
  };

  return (
    <PopUp setClose={setPromptEdit}>
      <Wrapper>
        <Title>Prompt {promptEdit.number ? "Edit" : "Add"}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={numberRef}
            name="Number"
            width={100}
            type="number"
            inputWidth={30}
            placeholder=""
            required
            requiredSign
            value={promptEdit.number}
          />
          <TextArea
            textAreaRef={nameRef}
            label={"Promt"}
            required
            defaultValue={promptEdit.name}
            width="800px"
          />
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </Wrapper>
    </PopUp>
  );
};

const AdminLessonEdit = ({ setEdit, edit }) => {
  const [image, setImage] = useState(edit?.image?.base64);
  const [promptEdit, setPromptEdit] = useState();
  const [categoryOptions, setCategoryOptions] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const starOneRef = useRef();
  const starThreeRef = useRef();
  const starTwoRef = useRef();
  const endLessonRulePromptRef = useRef();

  const handleDeletePromt = (prompt) => {
    setPrompts((prev) => prev.filter((p) => p._id !== prompt._id));
  };

  const [prompts, setPrompts] = useState(edit.prompts || []);

  const tableRows = useMemo(() => {
    if (!prompts) return [];
    return prompts
      .sort((a, b) => a.number - b.number)
      .map((prompt) => [
        prompt.number,
        prompt.name,
        <div style={{ display: "flex", gap: " 10px" }}>
          <CustomDeleteButton onClick={() => handleDeletePromt(prompt)} />
          <CustomEditButton onClick={() => setPromptEdit(prompt)} />
        </div>,
      ]);
  }, [prompts]);

  const nameRef = useRef();
  const descriptionRef = useRef();
  const answerPromptRef = useRef();

  const findCategoriesMutation = useMutation({
    mutationFn: () => findCategories(),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({ label: d.name, value: d._id }));
      setCategoryOptions(options);
      setSelectedCategory(
        options.find((o) => edit.category._id === o.value) || {}
      );
    },
  });

  const createLessonMutation = useMutation({
    mutationFn: (value) => createLesson(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateLessonMutation = useMutation({
    mutationFn: (value) => updateLesson(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.answer_promt = answerPromptRef.current.value;
    payload.description = descriptionRef.current.value;
    payload.prompts = prompts;
    payload.category = selectedCategory.value;
    payload.starOne = starOneRef.current.value;
    payload.starTwo = starThreeRef.current.value;
    payload.starThree = starTwoRef.current.value;
    payload.endLessonRulePrompt = endLessonRulePromptRef.current.value;
    payload.image = image;

    if (edit._id) {
      return updateLessonMutation.mutate(payload);
    }

    createLessonMutation.mutate(payload);
  };

  useEffect(() => {
    findCategoriesMutation.mutate();
  }, []);

  return (
    <>
      {(findCategoriesMutation.isLoading ||
        createLessonMutation.isLoading ||
        updateLessonMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Lesson {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <Input
                inputRef={nameRef}
                name="Name"
                inputWidth={200}
                width={80}
                required
                requiredSign
                value={edit.name}
              />
              <ImageContainer image={image} setImage={setImage} />
            </div>

            <div style={{ display: "flex", marginBottom: "10px" }}>
              <Input
                inputRef={starOneRef}
                name="Star 1"
                type="number"
                inputWidth={40}
                width={80}
                required
                requiredSign
                value={edit.starOne}
              />
              <Input
                inputRef={starThreeRef}
                name="Star 2"
                type="number"
                inputWidth={40}
                width={80}
                required
                requiredSign
                value={edit.starTwo}
              />
              <Input
                inputRef={starTwoRef}
                name="Star 3"
                type="number"
                inputWidth={40}
                width={80}
                required
                requiredSign
                value={edit.starThree}
              />
            </div>
            <SelectInput
              width={80}
              name="category"
              options={categoryOptions}
              setSelected={setSelectedCategory}
              selected={selectedCategory}
            />
            <TextArea
              textAreaRef={descriptionRef}
              label="Description"
              width="800px"
              required
              requiredSign
              defaultValue={edit.description}
            />
            <TextArea
              textAreaRef={endLessonRulePromptRef}
              label="End lesson rule Prompt"
              width="800px"
              required
              requiredSign
              defaultValue={edit.endLessonRulePrompt}
            />
            <TextArea
              textAreaRef={answerPromptRef}
              label="Answer Prompt"
              required
              defaultValue={edit.answer_promt}
              width="800px"
            />
          </div>
          <TableWrapper>
            <CustomTable data={tableRows} headers={Headers} />
          </TableWrapper>
          <SaveWrapper>
            <CustomAddButton
              text="Add prompt"
              onClick={() => setPromptEdit({})}
            />
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
      {promptEdit && (
        <PromptEdit
          prompts={prompts}
          setPromptEdit={setPromptEdit}
          setPrompts={setPrompts}
          promptEdit={promptEdit}
        />
      )}
    </>
  );
};

export default AdminLessonEdit;
