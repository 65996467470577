import React from "react";
import "./paymentview.styles.css";
import OneTimePaymentView from "./paymentViews/OneTimePaymentView";

const PaymentView = () => {
  return (
    <>
      <OneTimePaymentView />
    </>
  );
};

export default PaymentView;
