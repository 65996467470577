import api from "@/api/api";
import userManager from "@/api/userManager";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";
import React from "react";
import { Navigate } from "react-router-dom";
import { styled } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  overflow: hidden;
`;

const Card = styled.div`
  width: 1000px;
  height: calc(var(--vh, 1vh) * 100 - 100px);
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
`;

const { tokenManager } = api;

const AdminLayout = ({ children }) => {
  const user = userManager?.getUser();

  if (!user || "Admin" !== user.role) {
    userManager.removeUser();
    tokenManager.removeToken();

    return <Navigate to={getNavigationPath("/login")} replace />;
  }

  return (
    <Wrapper>
      <Card>{children}</Card>
    </Wrapper>
  );
};

export default AdminLayout;
