import Api from "../api";

const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "workflow";

export const transcriptWithOutUser = async (payload) => {
  const data = await api.post(`/${PREFIX}/transcript-without`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { data: data.data };
};

export const transcript = async (payload) => {
  const data = await api.post(`/${PREFIX}/transcript`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { data: data.data };
};

export const getVoice = async (payload) => {
  const data = await api.post(`/${PREFIX}/voice-from-text`, payload);
  return { data: data.data };
};

export const askChatGptProductSpecial = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/ask-chat-gpt-special/${payload._id}`,
    payload
  );

  return { data: data.data };
};

export const askChatGptFlashCard = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/ask-chat-gpt-flashcard/${payload._id}`,
    payload
  );

  return { data: data.data };
};

export const askChatGptProduct = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/ask-chat-gpt-product/${payload._id}`,
    payload
  );

  return { data: data.data };
};

export const askChatGptProductWord = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/ask-chat-gpt-word-lesson/${payload._id}`,
    payload
  );

  return { data: data.data };
};

export const askChatGpt = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/get-message-from-chat-gpt/${payload._id}`,
    payload
  );

  return { data: data.data };
};

export const askChatGptImageMode = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/ask-chat-gpt-foto-mode/${payload._id}`,
    payload
  );

  return { data: data.data };
};

export const getTranslation = async (payload) => {
  const data = await api.get(`/${PREFIX}/get-translation/${payload.message}`);

  return { data: data.data, name: payload.name };
};
export const getUserUsageStats = async () => {
  const data = await api.get(`/${PREFIX}/get-user-usage-stats`);

  return { data: data.data };
};

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
