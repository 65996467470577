import React, { useState } from "react";
import styled from "styled-components";
import LeftIcon from "@/common/images/group.png";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import Hands, { SOURCES_TYPES } from "@/common/components/customer/Hands";

const Left = styled.p`
  color: #868686;
  min-width: 100px;

  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.h2`
  color: #3280fc;

  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;

  @media (max-width: 600px) {
    margin: 0;
  }
`;

const TopWrapper = styled.div`
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    border: none;
  }
`;

const Subtitle = styled.p`
  color: #05275d;
  max-width: 800px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.28px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const CardTitle = styled.h3`
  @media (max-width: 600px) {
    margin: 0;
  }
`;

const CardSubtitle = styled.p`
  @media (max-width: 600px) {
    margin: 0;
  }
`;

const FlippableCard = styled.div`
  position: relative;
  width: 350px;
  height: 200px;
  perspective: 1000px;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;

    margin-top: -10px;
    width: 350px;
    height: 130px;
  }
`;

const CardSide = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    width: 80%;
    height: 80%;
  }
`;

const CardFront = styled(CardSide)`
  background-color: #3280fc;
  color: white;
`;

const CardBack = styled(CardSide)`
  background-color: rgba(79, 167, 180, 0.2);
  color: #05275d;
`;

const FlashcardView = ({ data }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();

  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 0 : 1,
    transform: `rotateX(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <>
      <TopWrapper>
        <Left onClick={() => navigate("/home")} style={{ cursor: "pointer" }}>
          <img src={LeftIcon} />
          Back
        </Left>
        <Title>Flashcard</Title>
        <Hands type={SOURCES_TYPES.FLASH_CARD} forWhat={data._id} />
      </TopWrapper>
      <Subtitle>
        Click to see the translation and learn a new vocabulary word. You can
        also ask a question in the chat to deepen the discussion and learn more
        examples of usage.
      </Subtitle>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <FlippableCard onClick={handleCardClick}>
          <CardFront style={{ opacity, transform }}>
            <CardTitle>{data.name}</CardTitle>
            <CardSubtitle>{data.sentence}</CardSubtitle>
          </CardFront>
          <CardBack
            style={{
              opacity: opacity.to((o) => 1 - o),
              transform: transform.to((t) => `${t} rotateX(180deg)`),
            }}
          >
            <CardTitle>{data.translation}</CardTitle>
            <CardSubtitle>{data.translatedSentence}</CardSubtitle>
          </CardBack>
        </FlippableCard>
        <ImageWrapper
          style={{
            borderRadius: "10px",
            width: "200px",
            overflow: "hidden",
          }}
        >
          <img
            src={data.base64}
            style={{ width: "100%", height: "auto" }}
            alt={data.name}
          />
        </ImageWrapper>
      </div>
    </>
  );
};

export default FlashcardView;
