import React from "react";
import {
  ContentDisplay,
  CustomImage,
  Element,
  TextElemenetDown,
  Title,
  TitleElement,
  Wrapper,
  ImageWrapper,
} from "./whatYouWillGetSection.styles";

const WhatYouWillGetSection = ({ data }) => {
  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <ContentDisplay>
        {data.items.map(({ image, text, title }) => (
          <Element key={title}>
            <ImageWrapper>
              <CustomImage src={image} alt="Sarai" />
            </ImageWrapper>
            <TitleElement>{title}</TitleElement>
            <TextElemenetDown>{text}</TextElemenetDown>
          </Element>
        ))}
      </ContentDisplay>
    </Wrapper>
  );
};

export default WhatYouWillGetSection;
