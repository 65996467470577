import React, { useEffect, useState } from "react";
import StartDemoIcon from "@/common/images/icon-arrow-circle-right.svg";
import FrameRobot from "@/common/images/framerobot.svg";
import { useNavigate } from "react-router-dom";
import Recording1 from "@/common/recordings/summarize-1.mp3";
import Recording2 from "@/common/recordings/summarize-2.mp3";
import Recording3 from "@/common/recordings/summarize-3.mp3";
import {
  ButtonWrapper,
  ContentWrapper,
  CustomedButton,
  DemoMessageWrapper,
  DemoSummariazeWrapper,
  Image,
  InfoWrapper,
  Logo,
} from "./demoSummarize.styles";
import { useTypeWritterFlow } from "@/common/hooks/useTypeWritterFlow";
import { Howl } from "howler";

const DemoSummarize = () => {
  const [showButton, setShowButton] = useState();
  const navigate = useNavigate();
  const name = localStorage.getItem("lead-history-name");

  const {
    start,
    results: [
      firstMessage,
      secondMessage,
      thirdMessage,
      fourthMessage,
      fifthMessafe,
    ],
  } = useTypeWritterFlow({
    flowItems: [
      {
        text: `${
          name?.charAt(0).toUpperCase() + name?.slice(1)
        }, it was a pleasure talking to you! `,
        typing: 40,
        audio: new Howl({
          src: [Recording1],
        }),
      },
      {
        audio: new Howl({
          src: [Recording2],
        }),
        text: `Based on our short conversation, I have prepared a  `,
        typing: 40,
        timeout: 0,
      },
      {
        text: ` personalized plan `,
        typing: 40,
        timeout: 0,
      },
      {
        text: `that will take your English to the next level! `,
        typing: 40,
      },
      {
        text: `Are you excited to start your customize conversations?  `,
        typing: 40,
        audio: new Howl({
          src: [Recording3],
        }),
      },
    ],
    onEnd: () => {
      setShowButton(true);
    },
  });

  useEffect(() => {
    window.gtag("event", `demo_summary`);
    start()();
  }, []);

  return (
    <DemoSummariazeWrapper>
      <InfoWrapper>
        <Logo src={FrameRobot} alt="robot" />
        <ContentWrapper style={{ gap: "10px" }}>
          <DemoMessageWrapper>{firstMessage}</DemoMessageWrapper>
          {secondMessage && (
            <DemoMessageWrapper>
              {secondMessage} <b>{thirdMessage}</b> {fourthMessage}
            </DemoMessageWrapper>
          )}
          {fifthMessafe && (
            <DemoMessageWrapper>{fifthMessafe}</DemoMessageWrapper>
          )}
        </ContentWrapper>
      </InfoWrapper>
      {showButton && (
        <ButtonWrapper>
          <CustomedButton
            id="buy"
            className="fade__in__animation"
            onClick={() => navigate("/register")}
          >
            SEE PLAN! <Image src={StartDemoIcon} />
          </CustomedButton>
        </ButtonWrapper>
      )}
    </DemoSummariazeWrapper>
  );
};

export default DemoSummarize;
