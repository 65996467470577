import { Color } from "@/common/colors/colors";
import { styled } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px 0 0px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 600px) {
    gap: 20px;
  }
`;

export const AditionalInfo = styled.div`
  color: #868686;
  font-family: "Work Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 14.3px */
  max-width: 290px;
  margin-top: -15px;
  margin-left: 5px;
`;

export const Card = styled.form`
  display: flex;
  justify-content: center;
  gap: 100px;
  margin: 50px;
`;

export const EyeIconWrapper = styled.div`
  color: #aba9a9;
  font-size: 16px;
  position: absolute;
  right: 18px;
  bottom: 5px;
  cursor: pointer;

  &:active {
    color: #05275d;
  }

  @media (min-width: 600px) {
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: left;
  position: relative;
`;

export const RobotWrapper = styled.div`
  width: 18%;
  position: absolute;
  left: -110px;
  top: 50%;
  transform: translateY(-70%);

  @media (max-width: 599px) {
    display: none;
  }
`;

export const Title = styled.h3`
  color: #0b2f45;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

export const Label = styled.label`
  color: #868686;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 5px; */
  /* gap: 8px; */
  position: relative;
`;

export const Input = styled.input`
  margin-top: 4px;
  display: flex;
  padding: 8px 10px 8px 10px;
  width: 260px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #aba9a9;
  background: #fff;

  @media (min-width: 600px) {
    border: 1px solid #aba9a9;
  }

  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  padding: 12px 45px 12px 45px;
  width: 260px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #3280fc;

  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &:hover {
    color: #3280fc;
    background: #fff;
    border: 2px solid #3280fc;
  }
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin: 0;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
`;

export const LoginLink = styled.a`
  color: #3280fc;
  font-family: Work Sans;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
`;
