export const YERS_OPTIONS = [
    {
      name: "18-24",
    },
    {
      name: "25-34",
    },
    {
      name: "35-44",
    },
    {
      name: "45-54",
    },
    {
      name: "55-64",
    },
    {
      name: "65+",
    },
  ];