import React, { useContext, useRef, useState } from "react";
import {
  ActionsWrapper,
  Card,
  ErrorMessage,
  EyeIconWrapper,
  Input,
  InputWrapper,
  InputsContainer,
  Label,
  SubmitButton,
  Title,
  Wrapper,
} from "./login.styles";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { login } from "@/api/query/auth";
import userManager from "@/api/userManager";
import api from "@/api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import PopUp from "@/common/components/PopUp";
import { restartPasswordByMail } from "@/api/query/user";
import Loading from "@/common/components/Loading";
import { MessageContext } from "@/common/contexts/MessageContext";
import LoginImage from "./images/login.png";
import { english } from "@/common/data/english";

const PasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  color: #05275d;
  margin-top: 20px;

  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */

  @media (max-width: 600px) {
    width: 330px;
  }
`;

const ImageWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const { tokenManager } = api;

const Login = () => {
  const [restartPassword, setRestartPassword] = useState();
  const { addMessage } = useContext(MessageContext);

  const [error, setError] = useState();
  const [labelType, setLabelType] = useState("password");
  const navigate = useNavigate();
  const emailRef = useRef();
  const emailRestartRef = useRef();

  const passwordRef = useRef();

  const loginMutation = useMutation({
    mutationFn: (value) => login(value),
    onSuccess: ({ data }) => {
      const { token, refreshToken } = data;

      tokenManager.setToken(token);
      tokenManager.setRefreshToken(refreshToken);

      const payload = tokenManager.parseJwt(token);
      userManager.setUser(payload);

      const user = userManager.getUser();

      if (user.role === "Admin") {
        return navigate("/admin/home");
      }

      return navigate("/home");
    },
    onError: ({ data }) => {
      addMessage(english.login.errorLogin, "error");
    },
  });

  const passwordRestartMutation = useMutation({
    mutationFn: (value) => restartPasswordByMail(value),
    onSuccess: ({ data }) => {
      setRestartPassword(null);
      addMessage(english.login.passwordResterted, "success");
    },
    onError: ({ data }) => {
      addMessage(english.login.passwordError, "error");
    },
  });

  const handleLogin = (e) => {
    e && e.preventDefault();
    const payload = {};
    payload.email = emailRef.current.value;
    payload.password = passwordRef.current.value;

    loginMutation.mutate(payload);
  };

  const handleChangeLabel = () => {
    passwordRef?.current?.focus();

    if (labelType === "password") {
      return setLabelType(() => "text");
    }
    setLabelType(() => "password");
  };

  const handleRestartPassword = (e) => {
    e?.preventDefault();

    const payload = {};
    payload.email = emailRestartRef.current.value;

    passwordRestartMutation.mutate(payload);
  };

  return (
    <>
      {loginMutation.isLoading && <Loading />}
      {restartPassword && (
        <PopUp closeIcon={false} setClose={setRestartPassword}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              alignItems: "center",
            }}
            onSubmit={(e) => handleRestartPassword(e)}
          >
            <Title
              style={{
                fontSize: "16px",
                maxWidth: "300px",
                textAlign: "center",
              }}
            >
              {english.login.restartPasswordText}
            </Title>
            <Input
              ref={emailRestartRef}
              disabled={error}
              type="email"
              placeholder={english.login.placeholder}
              id="pawssword-sarai"
              required
            />
            <ActionsWrapper>
              <SubmitButton
                type="submit"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {english.login.resetPassword}
              </SubmitButton>
            </ActionsWrapper>
          </form>
        </PopUp>
      )}
      <Wrapper style={{ minHeight: "600px", maxHeight: "1000px" }}>
        <Card onSubmit={handleLogin} style={{ height: "fit-content" }}>
          <div>
            <Title>{english.login.title}</Title>
            <InputsContainer>
              <InputWrapper>
                <Label>{english.login.email}</Label>
                <Input
                  ref={emailRef}
                  disabled={error}
                  id="email-sarai"
                  type="email"
                  required
                  placeholder="example@saraiapp.com"
                />
              </InputWrapper>
              <InputWrapper>
                <Label>{english.login.password}</Label>
                <Input
                  ref={passwordRef}
                  disabled={error}
                  id="pawssword-sarai"
                  type={labelType}
                  required
                />
                <EyeIconWrapper onClick={handleChangeLabel}>
                  <FontAwesomeIcon
                    tabIndex="none"
                    icon={faEye}
                    style={{
                      color: labelType === "text" ? " #05275d" : "#ABA9A9",
                    }}
                  />
                </EyeIconWrapper>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </InputWrapper>
            </InputsContainer>
            <ActionsWrapper>
              <SubmitButton
                type="submit"
                style={{ marginTop: `${error ? "5px" : "40px"}` }}
              >
                {english.login.buttonText}
              </SubmitButton>
            </ActionsWrapper>
            <PasswordWrapper
              onClick={() => {
                setRestartPassword(true);
                window.gtag("event", "clicked_restart_password");
              }}
            >
              {english.login.textSub}
              <u style={{ marginLeft: "3px" }}>{english.login.restart}</u>.
            </PasswordWrapper>
          </div>
          <ImageWrapper>
            <img alt="" src={LoginImage} />
          </ImageWrapper>
        </Card>
      </Wrapper>
    </>
  );
};

export default Login;
