import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "user";

export const findUsers = async () => {
  const data = await api.get(`/${PREFIX}`);

  return { data: data.data };
};

export const findUserById = async (_id) => {
  const data = await api.get(`/${PREFIX}/${_id}`);

  return { data: data.data };
};

export const findUsersLast = async (payload) => {
  const data = await api.get(`/${PREFIX}/last?days=${payload.last_days}`);

  return { data: data.data };
};

export const loadUserLearningData = async () => {
  const data = await api.get(`/${PREFIX}/user-profile-learning`);
  return { data: data.data };
};

export const changeUserHobbyLanguage = async (payload) => {
  const data = await api.patch(`/${PREFIX}/user-profile-hobby`, payload);
  return { data: data.data };
};

export const restartPasswordByMail = async (payload) => {
  const data = await api.post(`/${PREFIX}/restart-password`, payload);
  return { data: data.data };
};

export const updatePassoword = async (payload) => {
  const data = await api.patch(`/${PREFIX}/update-password`, payload);
  return { data: data.data };
};

export const updatePassowordInside = async (payload) => {
  const data = await api.patch(`/${PREFIX}/update-password-inside`, payload);
  return { data: data.data };
};

export const handleChangeName = async (payload) => {
  const data = await api.patch(`/${PREFIX}/change-name`, payload);
  return { data: data.data };
};

export const updateUserRange = async (payload) => {
  const data = await api.patch(`/${PREFIX}/profile-change-range`, payload);
  return { data: data.data };
};

export const updateUserLevel = async (payload) => {
  const data = await api.patch(`/${PREFIX}/proifle-change-level`, payload);
  return { data: data.data };
};
