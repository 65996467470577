import React, { useRef, useState } from "react";
import RegenerateTask from "../../icon/regenerate_task.svg";
import TranslationIcon from "../../icon/translationIcon.svg";
import { useMutation } from "react-query";
import { getTranslation, transcript } from "@/api/query/workflow";
import {
  changeTaskInImageMode,
  generateNewTask,
  responseToTask,
} from "@/api/query/image-mode";
import Loading from "@/common/components/Loading";
import styled from "styled-components";
import SendIcon from "../../icon/white-icon-send.svg";
import Microfon from "@/common/components/Microfon";

const ImageWrapper = styled.div``;

const TextWrapper = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const TextWrapperMobile = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const TextAreaFotoMode = styled.textarea`
  margin-top: 20px;

  display: inline-flex;
  width: 100%;
  height: 190px;
  padding: 10px 20px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(79, 167, 180, 0.2);
  border: none;
  resize: none;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
  color: black;

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #eef2f5;
    font-style: italic;
    color: #868686;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;
const ButtonSend = styled.button`
  display: inline-flex;
  margin-top: 5px;
  padding: 9px 46px 8px 44px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;
  background: #3280fc;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &:disabled {
    background-color: gray;
  }

  padding: 10px 20px 10px 20px;
  margin-bottom: 10px;
`;

const TaskTitle = styled.h3`
  margin: 0;
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  text-align: left;

  @media (max-width: 600px) {
    display: none;
  }
`;
const TaskTitleMobile = styled.h3`
  margin: 0;
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  text-align: left;
  display: flex;

  @media (min-width: 600px) {
    display: none;
  }
`;

const TaskDescitpion = styled.p`
  color: #05275d;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  text-align: left;
  max-width: 450px;
  margin: 0;
  min-height: 100px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: -30px;

  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: -10px;
  }
`;

const TaskTextWrapper = styled.div`
  width: 40vw;

  @media (max-width: 600px) {
    width: calc(100vw - 40px);
    overflow-x: hidden;
  }
`;

const ImageModeView = ({ data, setData, setConversation, setAsk }) => {
  const [translation, setTranslation] = useState();
  const [submited, setSubmited] = useState(!!data.answer);
  const responseRef = useRef();

  const changeTaskMutation = useMutation({
    mutationFn: (value) => changeTaskInImageMode(value),
    onSuccess: ({ data }) => {
      setData((prev) => ({ ...prev, task: data }));
      setTranslation(() => null);
    },
  });

  const handleTranslateMutation = useMutation({
    mutationFn: (value) => getTranslation(value),
    onSuccess: ({ data }) => {
      setTranslation(data);
    },
  });

  const handleResponseToTaskMutation = useMutation({
    mutationFn: (value) => responseToTask(value),
    onSuccess: () => {
      setAsk(true);
      setData((data) => ({ ...data, answer: responseRef.current.value }));
      setSubmited(true);
    },
  });

  const handleNewTaskkMutation = useMutation({
    mutationFn: (value) => generateNewTask(value),
    onSuccess: ({ data }) => {
      setData(() => data.imageModeResponse);
      setConversation(() => []);
      responseRef.current.value = "";
      setSubmited(false);
    },
  });

  const handleChangeTask = () => {
    if (submited) {
      return;
    }

    const payload = {};
    payload.imageResponseId = data._id;

    changeTaskMutation.mutate(payload);
  };

  const handleTranslate = () => {
    if (translation) {
      return setTranslation(() => null);
    }
    const payload = {};
    payload.message = data.task;
    payload.name = "name";

    handleTranslateMutation.mutate(payload);
  };

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcript(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;
      responseRef.current.value = responseRef.current.value + " " + data;
    },
  });

  const handleSend = (voice) => {
    transcriptMutation.mutate(voice);
  };

  const handleReplyToTask = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.response = responseRef.current.value;
    payload.imageResponseId = data._id;

    handleResponseToTaskMutation.mutate(payload);
  };

  const handleNewTask = () => {
    const payload = {};
    payload.imageResponseId = data._id;

    handleNewTaskkMutation.mutate(payload);
  };

  return (
    <>
      {(handleTranslateMutation.isLoading ||
        changeTaskMutation.isLoading ||
        handleResponseToTaskMutation.isLoading ||
        handleNewTaskkMutation.isLoading ||
        transcriptMutation.isLoading) && <Loading />}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: "30px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <ImageWrapper
          style={{
            width: "330px",
            height: "330px",
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <img
            src={data.image_mode.image.base64}
            style={{ width: "100%", height: "auto" }}
            alt={data.name}
          />
        </ImageWrapper>
        <TaskTextWrapper>
          <TaskDescitpion>{translation || data.task}</TaskDescitpion>
          <IconWrapper>
            <img
              src={RegenerateTask}
              onClick={handleChangeTask}
              className="animation__grow"
              style={{ cursor: "pointer" }}
            />
            <img
              src={TranslationIcon}
              onClick={handleTranslate}
              className="animation__grow"
              style={{ cursor: "pointer" }}
            />
          </IconWrapper>
          <form onSubmit={handleReplyToTask}>
            <TextAreaFotoMode
              ref={responseRef}
              placeholder="Write your text here..."
              required
              defaultValue={data.answer || ""}
              disabled={submited}
            />
            <ButtonWrapper>
              {!submited && (
                <>
                  <ButtonSend
                    type="submit"
                    disabled={submited}
                    className="animation__grow"
                  >
                    {"Send "}{" "}
                    <img
                      src={SendIcon}
                      style={{ marginLeft: "10px" }}
                      alt="send icon"
                    />
                  </ButtonSend>
                  <Microfon handleSend={handleSend} />
                </>
              )}
              {submited && (
                <ButtonSend
                  type="button"
                  onClick={handleNewTask}
                  className="animation__grow"
                >
                  New task
                </ButtonSend>
              )}
            </ButtonWrapper>
          </form>
        </TaskTextWrapper>
      </div>
    </>
  );
};

export default ImageModeView;
