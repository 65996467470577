import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import { ImageInput, ImageWrapper } from "@/common/components/ImageContainer";
import BasicImage from "@/common/icons/image-icon.svg";
import { getBase64 } from "@/common/functions/getBase64";
import {
  createNativeLanguages,
  updateNativeLanguages,
} from "@/api/query/nativeLanguages";

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const AdminLanguageEdit = ({ setEdit, edit }) => {
  const [icon, setIcon] = useState(edit.icon?.base64);

  const nameRef = useRef();
  const iconRef = useRef();
  const shortRef = useRef();

  const createWordLessonConfigMutation = useMutation({
    mutationFn: (value) => createNativeLanguages(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonConfigMutation = useMutation({
    mutationFn: (value) => updateNativeLanguages(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.icon = icon;
    payload.short = shortRef.current.value;

    if (edit._id) {
      return updateWordLessonConfigMutation.mutate(payload);
    }

    createWordLessonConfigMutation.mutate(payload);
  };

  const handleSelectFileIcon = async () => {
    const file = iconRef.current.files[0];
    const base64 = await getBase64(file);
    setIcon(base64);
  };

  return (
    <>
      {(updateWordLessonConfigMutation.isLoading ||
        createWordLessonConfigMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Language {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={600}
            width={80}
            required
            requiredSign
            value={edit.name}
          />
          <Input
            inputRef={shortRef}
            name="Short"
            inputWidth={600}
            width={80}
            required
            requiredSign
            value={edit.short}
          />
          Icon
          <ImageWrapper>
            <ImageInput
              id="file-input-2"
              type="file"
              name="cv-2"
              ref={iconRef}
              onChange={handleSelectFileIcon}
            />
            <label htmlFor="file-input-2">
              <img
                alt=""
                src={icon || BasicImage}
                style={{ width: "100%", height: "auto" }}
              />
            </label>
          </ImageWrapper>
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminLanguageEdit;
