import React, { useEffect } from "react";
import { Wrapper } from "./learn.styles";
import styled from "styled-components";

import { Link } from "react-router-dom";
import Star from "@/common/icons/stars/star.svg";
import { english } from "@/common/data/english";

const CurrentWrapper = styled(Wrapper)`
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: start;
  padding: 50px;
  gap: 10px;

  @media (min-width: 600px) {
    gap: 40px;
  }

  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100 - 80px);
    overflow-y: scroll;
    justify-content: center;
    padding: 0px;
    padding-bottom: 10px;
  }
`;

const CardElement = styled(Link)`
  @media (max-width: 600px) {
    width: 330px;
  }

  text-decoration: none;
  width: 350px;
  height: 130px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const CardElementInside = styled.div`
  @media (max-width: 600px) {
    width: 330px;
  }

  width: 350px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  text-align: left;
  margin-left: 20px;
`;

const Title = styled.div`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
`;

const Description = styled.div`
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  max-width: 150px;
`;

const Right = styled.div`
  border-radius: 0 15px 15px 0px;
  overflow: hidden;
  height: 130px;
`;

const PopularBar = styled.div`
  display: flex;
  height: 40px;
  padding: 5px 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px 15px 0px 0px;
  background: #8befd1;
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
`;

const TopWrapper = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  width: 300px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const Learn = () => {
  useEffect(() => {
    window.document.title = "Sarai - Ucz się";
  }, []);

  return (
    <>
      <CurrentWrapper>
        <TopWrapper>{}</TopWrapper>
        {english.app.learn.items.map((e) => (
          <CardElement to={e.link} className="animation__grow">
            {e.popular && (
              <PopularBar style={{ height: "24px" }}>
                Trending <img src={Star} style={{ marginLeft: "10px" }} />
              </PopularBar>
            )}
            <CardElementInside
              style={{
                height: e.popular ? "90px" : "130px",
              }}
            >
              <Left>
                <Title>{e.title}</Title>
                <Description>{e.description}</Description>
              </Left>
              <Right
                style={{
                  borderRadius: e.popular
                    ? "0 0px 15px 0px"
                    : "0 15px 15px 0px",
                  marginTop: e.popular ? "5px" : "0px",
                  height: e.popular ? "95px" : "130px",
                }}
              >
                <img
                  src={e.image}
                  alt={e.title}
                  style={{ width: "100%", height: "auto" }}
                />
              </Right>
            </CardElementInside>
          </CardElement>
        ))}
      </CurrentWrapper>
    </>
  );
};

export default Learn;
