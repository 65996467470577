import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.h1`
  color: #05275d;

  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  max-width: 1000px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
`;
const ImageWrapper = styled.div`
  width: 60px;
  padding: 20px;
  background-color: white;
  border-radius: 25px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;
  }
`;

const TextElement = styled.p`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 220px;
  margin: 0;
`;

const ElementsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 50px;
`;

const InnovationDesktop = ({ data }) => {
  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <ElementsWrapper>
        {data.icons.map((c) => (
          <ElementWrapper>
            <ImageWrapper>
              <img src={c.image} />
            </ImageWrapper>
            <TextElement>{c.top}</TextElement>
            <TextElement>{c.bottom}</TextElement>
          </ElementWrapper>
        ))}
      </ElementsWrapper>
    </Wrapper>
  );
};

export default InnovationDesktop;
