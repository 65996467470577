import { updateLeadHistory } from "@/api/query/lead-history";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { ButtonWrapper, CustomButton } from "@/pages/landing/styles/wrappers";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { CONVERSATION_VIEWS_ENUM } from "../../demoScript.enums";
import { useTypeWritterFlow } from "@/common/hooks/useTypeWritterFlow";
import BotMessage from "../Message/BotMessage";
import { Howl } from "howler";
import { LEVEL_OPTIONS } from "./levelMessage.constants";

const LevelMessage = () => {
  const { setConversationParts, data, setData, Recording4, Recording4A } =
    useContext(DemoConversationContext);

  const [picked, setPicked] = useState();
  const [showButtons, setShowButtons] = useState(false);

  const {
    start,
    results: [text1, text2],
  } = useTypeWritterFlow({
    flowItems: [
      {
        text: `Thank you for sharing that, ${data?.name}! That gives me a better understanding of your learning background. `,
        audio: new Howl({
          src: [Recording4],
        }),
      },
      {
        text: `Now, let's move on to assess your comfort level in English. Please choose one of the following options that best describes your current proficiency level? `,
        audio: new Howl({
          src: [Recording4A],
        }),
      },
    ],
    onEnd: () => {
      const element = document.getElementById("section-3");
      element.scrollIntoView({ behavior: "smooth" });
      setShowButtons(true);
    },
  });

  useEffect(() => {
    start()();
  }, []);

  const updateLeadMutation = useMutation({
    mutationFn: (value) => updateLeadHistory(value._id, value),
  });

  const handleSelectButton = (userChoise) => {
    if (picked) {
      return;
    }

    localStorage.setItem("level", userChoise);
    window.gtag("event", "click_question_4");
    const payload = data;
    payload.level = userChoise;
    payload._id = localStorage.getItem("lead-history-id");

    updateLeadMutation.mutate(payload);

    setPicked(userChoise);
    setData((prev) => ({ ...prev, level: userChoise }));
    setConversationParts(CONVERSATION_VIEWS_ENUM.HOBBY);
  };

  return (
    <>
      <BotMessage text={text1} id="section-3" />
      <BotMessage text={text2} />
      {showButtons && (
        <ButtonWrapper
          style={{
            flexDirection: "column",
            justifyContent: "right",
            alignItems: "flex-end",
          }}
        >
          {LEVEL_OPTIONS.map(({ level, name, mobile_name }) => (
            <CustomButton
              className="fade__in__animation"
              onClick={() => handleSelectButton(level)}
              key={level}
              style={{
                textTransform: "unset",
                maxWidth: "450px",
                textAlign: "left",
                background: `${data?.level === level ? "#01B07C" : "#e2fbf4"}`,
                color: `${data?.level === level ? "white" : "black"}`,
              }}
            >
              {window.innerWidth > 600 ? name : mobile_name}
            </CustomButton>
          ))}
        </ButtonWrapper>
      )}
    </>
  );
};

export default LevelMessage;
