import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import TrashIcon from "./icons/trash.svg";
import SendIcon from "./icons/send.png";
import MicrofonIcon from "./icons/microphone.png";
import { KEYS_CODES } from "@/common/constants/keyCodes";
import MicRecorder from "mic-recorder-to-mp3";
import Timer from "./Timer";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const MessageBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    align-self: center;
    width: 95%;
  }
`;

const Input = styled.input`
  height: 35px;
  box-sizing: border-box;
  z-index: 3;
  display: flex;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background: #eef2f5;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    padding: 8px 10px;
    font-size: 12px;
    height: 30px;
  }
`;

const Button = styled.button`
  min-width: 100px;
  height: ${(prev) => prev.dekstop};
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  animation: unset;
  display: flex;
  justify-content: center;

  :hover {
    transform: unset !important;
  }

  @media (max-width: 600px) {
    min-width: 60px;
    height: ${(prev) => prev.mobile};
  }

  img {
    width: auto;
    height: 100%;
  }
`;

const RecordingBar = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
  padding-left: 30px;
  border-radius: 8px;
  background: rgba(139, 239, 209, 0.41);

  @media (max-width: 600px) {
    width: 60%;
    height: 30px;
    font-size: 12px;
  }
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: red;
  width: 10px;
  height: 10px;
  animation: ${blinkAnimation} 1s infinite;
`;

const STATES = {
  BASE: "base",
  RECORDING: "recording",
  TYPING: "typing",
};

const MessageBar = ({ isBlock = false, sendVoice, sendText }) => {
  const [isBlocked, setIsBlocked] = useState();

  const messageRef = useRef();
  const [status, setStatus] = useState(STATES.BASE);

  const start = () => {
    if (isBlocked) {
      alert("Please allow your microfon");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setStatus(() => STATES.RECORDING);
        })
        .catch((e) => alert("Please allow your microphone"));
    }
  };

  const cancel = () => {
    Mp3Recorder.stop();
    setStatus(() => STATES.BASE);
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File([blob], "to_translate.mp3", {
          type: "audio/mpeg",
          lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        formData.append("file", file);

        sendVoice(formData);
      })
      .catch((e) => console.log(e));

    setStatus(() => STATES.BASE);
  };

  const handleSendMessage = () => {
    if (status === STATES.TYPING) {
      sendText(messageRef.current.value);

      messageRef.current.value = "";
    }

    if (status === STATES.RECORDING) {
      stop();
    }

    setStatus(() => STATES.BASE);
  };

  const handleTyping = () => {
    const text = messageRef?.current?.value || "";
    if (text.length === 0) {
      return setStatus(() => STATES.BASE);
    }
    return setStatus(() => STATES.TYPING);
  };

  const onEnterPress = (e) => {
    if (
      e.keyCode === KEYS_CODES.ENTER &&
      e.shiftKey === false &&
      window.innerWidth >= 600
    ) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Permission Granted");
        setIsBlocked(() => false);
      })
      .catch((err) => {
        console.log("Permission Denied");
        console.log(err);
        setIsBlocked(() => true);
      });
  }, []);

  return (
    <MessageBarWrapper>
      {[STATES.RECORDING].includes(status) && (
        <Button onClick={cancel} mobile={"20px"} dekstop={"28px"}>
          <img src={TrashIcon} alt="send" />
        </Button>
      )}
      {[STATES.BASE, STATES.TYPING].includes(status) ? (
        <Input
          onKeyDown={onEnterPress}
          ref={messageRef}
          placeholder="Type you message here..."
          onChange={handleTyping}
          disabled={isBlock}
        />
      ) : (
        <RecordingBar>
          <Circle />
          <Timer />
        </RecordingBar>
      )}
      {[STATES.RECORDING, STATES.TYPING].includes(status) ? (
        <Button onClick={handleSendMessage} mobile={"26px"} dekstop={"35px"}>
          <img src={SendIcon} alt="send" />
        </Button>
      ) : (
        <Button
          onClick={start}
          disabled={isBlock}
          mobile={"23px"}
          dekstop={"28px"}
        >
          <img src={MicrofonIcon} alt="mic" />
        </Button>
      )}
    </MessageBarWrapper>
  );
};

export default MessageBar;
