import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loading from "@/common/components/Loading";
import { getWordLessonTypeForCustomer } from "@/api/query/word-lesson-type";
import { getWordLessonTopType } from "@/api/query/word-lesson-top-type";
import TopTitleHeader from "@/common/components/TopTitleHeader";
import { SOURCES_TYPES } from "@/common/components/customer/Hands";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  margin: 20px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - 160px);
    width: 100%;
    padding: 5px 30px 10px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  margin: 10px 0;
`;

const LessonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 150px;
  height: 150px;
  padding: 10px 0px 0 0px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
  color: #0b2f45;
  gap: 4px;

  color: #0b2f45;

  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  transition: all 0.3s;

  @media (min-width: 600px) {
    width: 150px;
    height: 150px;

    &:hover {
      transform: scale(1.08);
      cursor: pointer;
    }
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;

  @media (min-width: 600px) {
    width: 90%;
  }
`;

const WordsWrapper = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;

  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  max-width: 150px;

  @media (min-width: 310px) {
    max-width: 320px;
    gap: 20px;
  }

  @media (min-width: 600px) {
    max-width: 330px;
    gap: 20px;
  }

  @media (min-width: 800px) {
    max-width: 670px;
  }

  @media (min-width: 1000px) {
    max-width: 830px;
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }
`;

const ImageWrapper = styled.div`
  width: 150px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;

  @media (min-width: 600px) {
    width: 150px;
    border-radius: 0 0 10px 10px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const TitleLesson = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 0;
  margin-bottom: 10px;
`;
const Text = styled.p`
  height: 40px;
  margin: 0;
`;

const NewFlashcardList = () => {
  const navigate = useNavigate();
  const [wordsTypes, setWordsTypes] = useState();

  const handleGetHobbyMutation = useMutation({
    mutationFn: () => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      setWordsTypes(data);
    },
  });

  useEffect(() => {
    handleGetHobbyMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = "Sarai - flashcards";
  }, []);

  return (
    <>
      {handleGetHobbyMutation.isLoading && <Loading />}
      <Wrapper height={window.innerHeight}>
        <TopTitleHeader
          navigate="/learn"
          source={SOURCES_TYPES.FLASH_CARD_NEW}
          title="Flashcards"
        />
        <TitleLesson>Topics</TitleLesson>
        <WordsWrapper>
          {wordsTypes?.map((l) => (
            <LessonWrapper
              onClick={() => navigate(`/learn/new-flashcard-category/${l._id}`)}
            >
              <Text>{l.name}</Text>
              <ImageWrapper>
                <img alt="" src={l?.bigFile?.base64} />
              </ImageWrapper>
            </LessonWrapper>
          ))}
        </WordsWrapper>
      </Wrapper>
    </>
  );
};

export default NewFlashcardList;
