import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import SuccessImage from "../icons/successImage.svg";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
`;
const Title = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
`;

const SubTitle = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  max-width: 300px;
`;

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("user_register");
    localStorage.removeItem("register_order");

    setTimeout(() => {
      navigate(getNavigationPath("/login"));
    }, 3000);
  }, []);

  return (
    <Wrapper>
      <img
        alt=""
        src={SuccessImage}
        style={{ width: "80px", height: "80px", marginTop: "60px" }}
      />
      <Title>Płatność zakończona sukcesem!</Title>
      <SubTitle>
        Zachwilę zostaniesz przekierowany na stronę logowania.
      </SubTitle>
    </Wrapper>
  );
};

export default Success;
