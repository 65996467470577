import { styled } from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 20px;
  flex-grow: 1;

  @media (max-width: 600px) {
    gap: 10px;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(var(--vh, 1vh) * 100 - 220px);
  padding: 0 20px 0;

  @media (min-width: 600px) {
    gap: 18px;
    flex-grow: 1;
    height: calc(100vh - 300px);
  }
`;

export const Logo = styled.img`
  width: 69px;
  height: 75px;

  @media (max-width: 600px) {
    width: 30px;
    height: 32px;
  }
`;

export const Height = styled.div`
  min-height: 1000px;
`;

export const DemoSectionWrapper = styled.div`
  width: calc(100vw - 30px);
  padding: 5px;
  height: calc(var(--vh, 1vh) * 100 - 170px);
  top: 90px;
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(79, 167, 180, 0.2);
  border-radius: 16px;

  @media (min-width: 600px) {
    box-sizing: border-box;
    height: fit-content;
    width: 100%;
    position: static;
    box-shadow: none;
    padding: 30px 8px 6px;
    flex-grow: 1;
    display: flex;
    gap: 20px;
  }
`;
