import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "word-lesson-top-type";

export const getWordLessonTopType = async (payload) => {
  const data = await api.get(`/${PREFIX}/all`, payload);
  return { data: data.data };
};

export const createWordLessonTopType = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateWordLessonTopType = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};
