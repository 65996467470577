import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import moment from "moment";
import { findOrders } from "@/api/query/order";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const HEADERS = [
  "No.",
  "Created date",
  "Email",
  "Plan",
  "Price",
  "Payment status",
  "Expired date",
];

export const formatDate = (d) => {
  if (!d) {
    return "------";
  }
  return moment(d).format("DD/MM/YYYY HH:mm");
};

const AdminOrder = () => {
  const [tableData, setTableData] = useState();

  const findMailMutation = useMutation({
    mutationFn: () => findOrders(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort((a, b) => {
            const aDate = a.payments?.created_at
              ? new Date(a.payments?.created_at).getTime()
              : new Date(a.created_at).getTime();
            const bDate = b.payments?.created_at
              ? new Date(b.payments?.created_at).getTime()
              : new Date(b.created_at).getTime();

            return bDate - aDate;
          })
          .map((d, i) => {
            const aDate = d.payments?.created_at
              ? new Date(d.payments?.created_at).getTime()
              : new Date(d.created_at).getTime();

            return [
              i + 1,
              formatDate(aDate),
              d.user?.email,
              d.plan_type,
              (d.payments?.price || d.price) / 100,
              d.payments?.status || "- - - -",
              formatDate(d.expired_date),
            ];
          })
      );
    },
  });

  useEffect(() => {
    findMailMutation.mutate();
  }, []);

  return (
    <>
      {findMailMutation.isLoading && <Loading />}
      <Wrapper>
        <Title>Orders</Title>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} csvData={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminOrder;
