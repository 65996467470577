import PopUp from "@/common/components/PopUp";
import TextArea from "@/common/components/TextArea";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { Title } from "@/pages/customer/word-lesson-type/WordLessonTypeList";
import React, { useRef } from "react";
import styled from "styled-components";

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const EditGame = ({ setClose, edit, handleAddGame }) => {
  const wordPromptRef = useRef();
  const wordFollowUpPromptRef = useRef();
  const wordQuestionPromptRef = useRef();
  const wordAnswerPrompRef = useRef();
  const nameRef = useRef();

  const handleSave = (e) => {
    e && e.preventDefault();

    const paylaod = edit;

    paylaod.prompt = wordPromptRef.current.value;
    paylaod.answerPrompt = wordAnswerPrompRef.current.value;
    paylaod.questionPrompt = wordQuestionPromptRef.current.value;
    paylaod.followUpPrompt = wordFollowUpPromptRef.current.value;
    paylaod.name = nameRef.current.value;

    if (edit.id) {
      handleAddGame((prev) => [
        ...prev.filter((p) => p.id !== paylaod.id),
        paylaod,
      ]);
      setClose(null);
      return;
    }

    handleAddGame((prev) => [...prev, { ...paylaod, id: prev.length + 1 }]);
    setClose(null);
  };

  return (
    <>
      <PopUp setClose={setClose}>
        <form onSubmit={handleSave}>
          <Title> Game config {edit?.id ? "edit" : "create"}</Title>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={400}
            width={80}
            required
            requiredSign
            value={edit.name}
          />
          <TextArea
            textAreaRef={wordPromptRef}
            label="Opening prompt"
            defaultValue={edit.prompt}
            required={true}
          />
          <TextArea
            textAreaRef={wordFollowUpPromptRef}
            label="Follow up prompt"
            defaultValue={edit.followUpPrompt}
            required={true}
          />
          <TextArea
            textAreaRef={wordQuestionPromptRef}
            label="Question / End rule prompt"
            defaultValue={edit.questionPrompt}
            required={true}
          />
          <TextArea
            textAreaRef={wordAnswerPrompRef}
            defaultValue={edit.answerPrompt}
            label="Answer prompt"
            required={true}
          />
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </form>
      </PopUp>
    </>
  );
};

export default EditGame;
