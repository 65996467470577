import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ArrowLeft from "./images/arrow-left.svg";
import ArrowRight from "../images/arrow-right.svg";
import ArrowDown from "../images/arrow-down.svg";
import ArrowUp from "./images/arrow-up.svg";
import { useMutation } from "react-query";
import { loadUserLearningData, updateUserRange } from "@/api/query/user";
import Loading from "@/common/components/Loading";
import InputRange from "react-input-range";
import { MessageContext } from "@/common/contexts/MessageContext";
import { LEVELS } from "@/pages/landing/components/DemoScript/components/LevelMessage/levelMessage.constants";
import { updateCustomerLevel } from "@/api/query/customer";
import { useNavigate } from "react-router-dom";

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;

  div {
    display: none;
  }

  @media (min-width: 600px) {
    div {
      display: flex;
    }
    gap: 20px;
    cursor: pointer;
  }
`;

const TopBar = styled.div`
  margin-top: 24px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    margin-left: 20px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  margin: 0 auto;
  width: 280px;
  margin-top: 20px;
  border-radius: 16px;
  background: #fff;
  padding: 16px;

  img {
    cursor: pointer;
  }

  @media (min-width: 600px) {
    width: 600px;
  }
`;

const Section = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    width: 600px;
  }
`;

const SectionTop = styled.div`
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    width: 600px;
  }
`;

const SectionTittle = styled.p`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  margin: 0;
`;

const Border = styled.div`
  margin: 16px 0;
  border-bottom: 1px solid rgba(79, 167, 180, 0.2);
`;

const ImageWrapperMenu = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`;

const SelectedElementWrapper = styled.p`
  color: #05275d;
  text-align: right;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  margin: 0;
  margin-right: 20px;
`;

const VIEWS = {
  LEVEL: "level",
  TARGET: "target",
};

const InputRangeWrapper = styled.div`
  width: 340px;
  margin: 30px auto;

  @media (max-width: 600px) {
    width: 250px;
    max-width: 350px;
  }

  .input-range__track--active {
    height: 6px !important;
  }

  .input-range__track--background {
    height: 6px !important;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 20px 0px 10px 30px;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 20px 0px 10px 0px;
    width: 700px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? "600" : "normal")};
    color: #05275d;
    text-align: center;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const ProfileLearning = () => {
  const [range, setRange] = useState(50);
  const [displayView, setDisplayView] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [userData, setUserData] = useState({});

  const navigate = useNavigate();

  const { addMessage } = useContext(MessageContext);

  const loadDataMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
      setRange(data.dailyWords);
      setSelectedLevel(data.level);
    },
  });

  const changeRangeMutation = useMutation({
    mutationFn: (data) => updateUserRange(data),
    onSuccess: ({ data }) => {
      loadDataMutation.mutate();
      addMessage("Target changed", "success");
    },
    onError: () => {
      addMessage("Error", "error");
    },
  });

  const changeLevelMutation = useMutation({
    mutationFn: (data) => updateCustomerLevel(data),
    onSuccess: () => {
      addMessage("Level changed", "success");
      loadDataMutation.mutate();
    },
    onError: () => {
      addMessage("Error", "error");
    },
  });

  const handleChangeRange = (e) => {
    const payload = {
      range: e,
    };

    changeRangeMutation.mutate(payload);
    setRange(e);
  };

  const handleChangeLevel = (level) => {
    const payload = {
      level,
      dailyWords: range,
    };

    changeLevelMutation.mutate(payload);
    setSelectedLevel(level);
  };

  useEffect(() => {
    loadDataMutation.mutate();
  }, []);

  return (
    <>
      {(loadDataMutation.isLoading ||
        changeLevelMutation.isLoading ||
        changeRangeMutation.isLoading) && <Loading />}
      <TopBar>
        <IconTitleWrapper onClick={() => navigate("/profile")}>
          <img src={ArrowLeft} />
          <div>Back</div>
        </IconTitleWrapper>
        <Title>Learning</Title>
        <IconTitleWrapper />
      </TopBar>
      <Card>
        <Section>
          <SectionTop>
            <SectionTittle>Native Language</SectionTittle>
            <ImageWrapperMenu>
              <SelectedElementWrapper>
                {userData?.nativeLanguage?.name || "Polish"}
              </SelectedElementWrapper>
              <img
                src={ArrowRight}
                onClick={() => navigate("/profile/learning/language")}
              />
            </ImageWrapperMenu>
          </SectionTop>
        </Section>
        <Border />
        <Section>
          <SectionTop
            onClick={() =>
              setDisplayView((prev) =>
                prev === VIEWS.LEVEL ? null : VIEWS.LEVEL
              )
            }
          >
            <SectionTittle>English level</SectionTittle>
            <ImageWrapperMenu>
              <SelectedElementWrapper>
                {userData?.level &&
                  `${
                    userData?.level[0]?.toUpperCase() +
                    userData?.level?.slice(1)
                  }`}
              </SelectedElementWrapper>
              <img src={displayView === VIEWS.LEVEL ? ArrowUp : ArrowDown} />
            </ImageWrapperMenu>
          </SectionTop>
          {displayView === VIEWS.LEVEL && (
            <SectionTop>
              <LevelOptionsWrapper>
                <InputWrapper isActive={selectedLevel === LEVELS.BEGINNER}>
                  <input
                    type="radio"
                    name="price"
                    id="beginner"
                    checked={selectedLevel === LEVELS.BEGINNER}
                  />
                  <label
                    for="beginner"
                    onClick={(e) => {
                      handleChangeLevel(LEVELS.BEGINNER);
                    }}
                  >
                    Beginner (A1)
                  </label>
                </InputWrapper>
                <InputWrapper isActive={selectedLevel === LEVELS.BASIC}>
                  <input
                    type="radio"
                    name="price"
                    id="basic"
                    checked={selectedLevel === LEVELS.BASIC}
                  />
                  <label
                    for="basic"
                    onClick={(e) => {
                      handleChangeLevel(LEVELS.BASIC);
                    }}
                  >
                    Basic (A2)
                  </label>
                </InputWrapper>
                <InputWrapper isActive={selectedLevel === LEVELS.INTERMEDIATE}>
                  <input
                    type="radio"
                    name="price"
                    id="middle"
                    isActive={selectedLevel === LEVELS.INTERMEDIATE}
                    checked={selectedLevel === LEVELS.INTERMEDIATE}
                  />
                  <label
                    for="middle"
                    onClick={(e) => {
                      handleChangeLevel(LEVELS.INTERMEDIATE);
                    }}
                  >
                    Intermediate (B1)
                  </label>
                </InputWrapper>
                <InputWrapper isActive={selectedLevel === LEVELS.ADVANCED}>
                  <input
                    type="radio"
                    name="price"
                    isActive={selectedLevel === LEVELS.ADVANCED}
                    checked={selectedLevel === LEVELS.ADVANCED}
                    id="advance"
                  />
                  <label
                    for="advance"
                    onClick={(e) => {
                      handleChangeLevel(LEVELS.ADVANCED);
                    }}
                  >
                    Advanced (B2)
                  </label>
                </InputWrapper>
                <InputWrapper isActive={selectedLevel === LEVELS.EXPERT}>
                  <input
                    type="radio"
                    name="price"
                    isActive={selectedLevel === LEVELS.EXPERT}
                    checked={selectedLevel === LEVELS.EXPERT}
                    id="expert"
                  />
                  <label
                    for="expert"
                    onClick={(e) => {
                      handleChangeLevel(LEVELS.EXPERT);
                    }}
                  >
                    Expert (C1+)
                  </label>
                </InputWrapper>
              </LevelOptionsWrapper>
            </SectionTop>
          )}
        </Section>
        <Border />
        <Section>
          <SectionTop
            onClick={() =>
              setDisplayView((prev) =>
                prev === VIEWS.TARGET ? null : VIEWS.TARGET
              )
            }
          >
            <SectionTittle>Daily World Target</SectionTittle>
            <ImageWrapperMenu>
              <SelectedElementWrapper>
                {userData.dailyWords}
              </SelectedElementWrapper>
              <img src={displayView === VIEWS.TARGET ? ArrowUp : ArrowDown} />
            </ImageWrapperMenu>
          </SectionTop>
          {displayView === VIEWS.TARGET && (
            <SectionTop>
              <InputRangeWrapper>
                <InputRange
                  maxValue={150}
                  minValue={50}
                  step={50}
                  value={range}
                  onChange={handleChangeRange}
                />
              </InputRangeWrapper>
            </SectionTop>
          )}
        </Section>
      </Card>
    </>
  );
};

export default ProfileLearning;
