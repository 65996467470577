import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { findMails, reSendMail } from "@/api/query/mail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailReply } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const HEADERS = [
  "No.",
  "Send At",
  "To",
  "Type",
  "Dlivered",
  "Clicked",
  "Actions",
];

const HEADERS_CSV = ["No.", "Send At", "To", "Type", "Dlivered", "Clicked"];

export const formatDate = (d) => {
  if (!d) {
    return "------";
  }

  return moment(d).format("DD/MM/YYYY HH:mm");
};

const AdminMail = () => {
  const [tableData, setTableData] = useState();
  const [tableDataCSV, setTableDataCSV] = useState();

  const resendMailMutation = useMutation({
    mutationFn: (payload) => reSendMail(payload),
    onSuccess: ({ data }) => {
      findMailMutation.mutate();
    },
  });

  const handleResend = (d) => {
    const payload = {};
    payload._id = d._id;

    resendMailMutation.mutate(payload);
  };

  const findMailMutation = useMutation({
    mutationFn: () => findMails(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((d, i) => [
            i + 1,
            formatDate(d.created_at),
            d.user?.email,
            d.mailType,
            formatDate(d.delivered_at),
            formatDate(d.first_open_at),
            <div>
              <FontAwesomeIcon
                icon={faMailReply}
                onClick={() => handleResend(d)}
              />
            </div>,
          ])
      );
      setTableDataCSV(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((d, i) => [
            i + 1,
            formatDate(d.created_at),
            d.user?.email,
            d.mailType,
            formatDate(d.delivered_at),
            formatDate(d.first_open_at),
          ])
      );
    },
  });

  useEffect(() => {
    findMailMutation.mutate();
  }, []);

  return (
    <>
      {findMailMutation.isLoading && <Loading />}
      <Wrapper>
        <Title>Mail</Title>
        <TableWrapper>
          <CustomTable
            headers={HEADERS}
            data={tableData}
            csvHeaders={HEADERS_CSV}
            csvData={tableDataCSV}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminMail;
