import { styled } from "styled-components";
import { StartLearningLink } from "../whatYouWillGetSection/whatYouWillGetSection.styles";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
`;

export const Title = styled.h1`
  color: #05275d;
  text-align: center;
  /* Title (h1) */
  font-family: Work Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.28px;

  @media (max-width: 600px) {
    color: #05275d;
    text-align: center;
    font-family: Work Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.56px;
    max-width: 320px;
    margin: 0;
  }
`;

export const StartLearningLinkMobile = styled(StartLearningLink)`
  display: none;

  @media (max-width: 600px) {
    margin-top: 20px;
    display: flex;
  }
`;

export const Subtitle = styled.h3`
  color: #0da9ab;
  text-align: center;
  /* Subtitle */
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SubTitleMobile = styled.h3`
  display: none;

  @media (max-width: 600px) {
    max-width: 340px;
    color: #0da9ab;
    text-align: center;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    margin: 20px 0 0 0;
  }
`;
