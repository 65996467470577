import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import AdminLessonEdit from "./components/AdminLessonEdit";
import { deleteLesson, findLessons } from "@/api/query/lesson";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import Loading from "@/common/components/Loading";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const HEADERS = [
  "No.",
  "Name",
  "How many prompts",
  "Image",
  "Creation Date",
  "Actions",
];

const AdminLesson = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();

  const handleDeleteLessonsMutation = useMutation({
    mutationFn: (id) => deleteLesson(id),
    onSuccess: () => findLessonsMutation.mutate(),
  });

  const findLessonsMutation = useMutation({
    mutationFn: () => findLessons(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => [
          i + 1,
          d.name,
          d.prompts.length,
          <div style={{ width: "50px", overflow: "hidden" }}>
            <img
              src={d?.image?.base64}
              style={{ width: "100%", height: "auto" }}
            />
          </div>,
          d.created_at,
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton onClick={(e) => setEdit(d)} />{" "}
            <CustomDeleteButton
              onClick={(e) => handleDeleteLessonsMutation.mutate(d._id)}
            />
          </div>,
        ])
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findLessonsMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {(handleDeleteLessonsMutation.isLoading ||
        findLessonsMutation.isLoading) && <Loading />}
      <Wrapper>
        {edit && <AdminLessonEdit edit={edit} setEdit={setEdit} />}
        <Title>Lesson</Title>
        <TopContent>
          <CustomAddButton onClick={() => setEdit({})} />
        </TopContent>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminLesson;
