import Api from "../api";
const { axios: api } = Api;

const PREFIX = "customer-profile";

export const getCustomerProfiles = async (payload) => {
  const data = await api.get(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const createCustomerProfile = async (payload) => {
  const data = await api.post(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const updateCustomerProfile = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const changeUserIcon = async (payload) => {
  const data = await api.patch(`/${PREFIX}/change-user-image-profile`, payload);
  return { data: data.data };
};
