import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(11, 47, 69, 0.9);
  z-index: 10;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  border: 1px solid grey;
  background: white;
  padding: 50px 70px;
  height: calc(var(--vh, 1vh) * 90);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const TextTitle = styled.h2`
  color: #000;
  font-family: Lato;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
`;

export const TextBottom = styled.p`
  color: #000;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
`;

export const StyledImage = styled.img`
  width: 172px;
  height: 172px;
`;

export const CustomArrow = styled.img`
  position: absolute;
  left: 10px;
  top: 15px;
`;
