import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "daily-conversation";

export const getDailyConversationConfigs = async (payload) => {
  const data = await api.get(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const createDailyConversationConfig = async (payload) => {
  const data = await api.post(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const updateDailyConversaionConfig = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};
