import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Howl } from "howler";
import { Carousel } from "react-responsive-carousel";
import LeftArr from "./icons/left-arr.svg";
import RightArr from "./icons/right-arr.svg";
import VolumeBlank from "./icons/VolumeBlank.svg";
import SpeakAnimation from "./icons/speak_animation.gif";
import { useMutation } from "react-query";
import { getAiPersons } from "@/api/query/ai-person";
import Loading from "../../Loading";

const Title = styled.p`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */

  color: #05275d;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;
  font-weight: bold;
`;

const Subtitle = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  max-width: 600px;

  @media (max-width: 800px) {
    max-width: 300px;
  }
`;

const Name = styled.p`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  text-align: left;
  padding: 0px 10px;
  margin: 0;
`;

const Description = styled.div`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  padding: 0px 10px 10px 10px;
  margin: 0;
  text-align: left;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  padding: 8px 46px 9px 45px;
  justify-content: center;
  align-items: center;
  color: white;

  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TagsWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 5px;
  margin: 10px 10px;
`;

const TageElement = styled.div`
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #05275d;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */

  border-radius: 10px;
  background: rgba(79, 167, 180, 0.2);
`;

const Voice = styled.button`
  cursor: pointer;
  margin: 0px 0 10px 0;
  display: flex;
  width: 70px;
  height: 70px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 39px;
  border-radius: 39px;
  background: linear-gradient(
    243deg,
    rgba(191, 65, 251, 0.92) -3.1%,
    #06cff1 47.2%,
    #8befd1 97.5%,
    rgba(5, 210, 255, 0.98) 147.8%,
    #b35cfa 198.1%
  );

  :hover {
    transform: scale(1);
  }
`;

export const OpinionElement = styled.div`
  width: 240px;
  height: 350px;
  margin: 0px 0 10px 0;
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 25px 10px;
  border-radius: 16px;

  @media (max-width: 600px) {
    max-width: 300px;
    margin: 10px auto;
  }
`;

export const OpinionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;
const BottomInfo = styled.p`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  margin: 5px 0 0 0;
`;

export const OpinionsWrapperMobile = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;

  .control-dots {
    border: 1px solid transparent !important;

    .dot {
      border-radius: 13px;
      background: grey !important;
      box-shadow: unset !important;
    }

    .selected {
      border-radius: 13px;
      background: #3280fc !important;
      box-shadow: unset !important;
    }
  }
`;

const WrapperIcon = styled.div`
  top: 43%;
  position: absolute;
  z-index: 99999;
  border: none;
`;

const OpinionElementBorderLeft = styled(OpinionElement)`
  @media (max-width: 800px) {
    display: none;
  }

  background-image: linear-gradient(to right, white 30%, transparent 80%),
    url(${(prev) => prev.image});
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
`;

const OpinionElementBorderRight = styled(OpinionElement)`
  @media (max-width: 800px) {
    display: none;
  }

  background-image: linear-gradient(to left, white 30%, transparent 90%),
    url(${(prev) => prev.image});
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
`;

const SliderWelcome = ({ handleSend }) => {
  const [curent, setCurrent] = useState(1);
  const [listening, setListening] = useState(false);
  const [tutors, setTutors] = useState([]);

  const handleSubmit = (e) => {
    e?.preventDefault();

    const payload = {};
    payload.speaker = tutors[curent]?._id;

    handleSend(payload);
  };

  const handlePlay = (base64) => {
    const prefix = "data:audio/wav;base64,";
    const audio = new Howl({
      src: [prefix + base64],
      onend: () => {
        setListening(false);
      },
    });
    setListening(true);
    audio?.play();
  };

  const getAllAiTutors = useMutation({
    mutationFn: () => getAiPersons(),
    onSuccess: ({ data }) => {
      setTutors(data);
      console.log(data);
    },
  });

  const handleChange = (e) => {
    setCurrent(() => e);
  };

  useEffect(() => {
    getAllAiTutors.mutate();
  }, []);

  return (
    <>
      {getAllAiTutors.isLoading && <Loading />}
      <Form onSubmit={handleSubmit}>
        <Title>Pick your AI tutor</Title>
        <Subtitle>
          Your decision will affect the style and atmosphere of the lesson.
        </Subtitle>
        {tutors.length > 0 && (
          <OpinionsWrapperMobile>
            <OpinionElementBorderLeft
              style={{
                height: "260px",
                padding: 0,
                opacity: 0.3,
              }}
              image={tutors[curent < 1 ? 3 : curent - 1]?.image.base64}
            />
            <Carousel
              axis="horizontal"
              selectedItem={0}
              autoPlay={false}
              infiniteLoop={true}
              width="300px"
              showThumbs={false}
              showIndicators={true}
              onChange={handleChange}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <WrapperIcon
                  onClick={onClickHandler}
                  style={{
                    left: "0",
                    cursor: "pointer",
                    padding: "20px 20px 20px 0px",
                  }}
                >
                  <img src={LeftArr} />
                </WrapperIcon>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <WrapperIcon
                  onClick={onClickHandler}
                  style={{
                    right: "0px",
                    cursor: "pointer",
                    padding: "20px 0px 20px 20px",
                  }}
                >
                  <img src={RightArr} />
                </WrapperIcon>
              )}
            >
              {tutors?.map((element, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <OpinionElement key={element._id}>
                    <img
                      src={element.image.base64}
                      style={{
                        width: "auto",
                        height: "100%",
                        borderRadius: "12px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "35px",
                        background:
                          "linear-gradient(24deg, rgba(255, 255, 255, 0.90) 36.87%, rgba(217, 217, 217, 0.00) 67.31%)",
                        width: "240px",
                        height: "200px",
                        borderRadius: "0px 0px 10px 10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                      }}
                    >
                      <Name>{element.name}</Name>
                      <TagsWrapper>
                        {element.tags.map((c) => (
                          <TageElement style={{ background: c.color }}>
                            {c.tag}
                          </TageElement>
                        ))}
                      </TagsWrapper>
                      <Description>{element.description}</Description>
                    </div>
                  </OpinionElement>
                </div>
              ))}
            </Carousel>
            <OpinionElementBorderRight
              style={{
                height: "260px",
                padding: 0,
                opacity: 0.3,
              }}
              image={tutors[curent > 2 ? 0 : curent + 1]?.image.base64}
            />
          </OpinionsWrapperMobile>
        )}
        <Voice
          type="button"
          onClick={() => handlePlay(tutors[curent]?.voice?.base64)}
          style={{
            background: "#EEF2F5",
          }}
        >
          <img
            src={listening ? SpeakAnimation : VolumeBlank}
            style={{ width: "60px" }}
          />
        </Voice>
        <SubmitButton>CHOOSE</SubmitButton>
        <BottomInfo>
          You can always change your selection in the settings.
        </BottomInfo>
      </Form>
    </>
  );
};

export default SliderWelcome;
