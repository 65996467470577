import React, { useState } from "react";
import {
  TalkMessageWrapper,
  TranslationMessageWrapper,
} from "@/pages/landing/styles/wrappers";
import Translation from "../Translation/Translation";
import { ContentTranslationWrapper } from "@/pages/landing/components/DemoScript/components/WelcomeMessage/welcomeMessage.styles";

const BotMessage = ({
  text,
  translation,
  showTranslateIcon,
  isTutorial = false,
  id,
}) => {
  const [showTranslation, setShowTrnaslation] = useState();

  if (!text) return null;

  return (
    <>
      <ContentTranslationWrapper id={id}>
        <TalkMessageWrapper
          style={{
            borderRadius: showTranslation
              ? "16px 16px 0px 0px"
              : "16px 16px 16px 0px",
          }}
        >
          {text}
        </TalkMessageWrapper>
        <Translation
          show={showTranslateIcon}
          setShowTrnaslation={setShowTrnaslation}
          demo={isTutorial}
        />
      </ContentTranslationWrapper>
      {showTranslation && (
        <TranslationMessageWrapper className="fade__in__animation">
          {translation}
        </TranslationMessageWrapper>
      )}
    </>
  );
};

export default BotMessage;
