import styled from "styled-components";

export const OrientationProblemWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  line-height: 40px;
  align-items: center;
  color: white;
  font-size: 32px;
  text-align: center;
`;
