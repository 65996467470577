import styled from "styled-components";
import { Color } from "@/common/colors/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: 600px) {
    box-sizing: border-box;
    flex-direction: column;
    gap: 32px;
    padding: 18px;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  min-height: 60px;
  max-height: 60px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(79, 167, 180, 0.2);

  @media (min-width: 600px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    max-width: 1200px;
    border-radius: 16px;
  }
`;

export const NavigationDesktopWrapper = styled.div`
  height: 48px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  background-color: #f3f6f8;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 95px;
  height: 31px;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const LinkWrapperDesktop = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const NavigationItemDesktop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-grow: 1;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border: 1px solid ${Color.Gray};
  background-color: ${(props) => (props.isActive ? "#fff" : Color.LightGray)};
  border: ${(props) => (props.isActive ? "none" : `1px solid ${Color.gray}`)};
  border-bottom: 1px solid ${Color.Gray};
  border-spacing: 2px;
  color: ${(props) => (props.isActive ? Color.Blue : Color.DarkGray)};
  cursor: pointer;
`;
