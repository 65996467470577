import React, { useEffect, useState } from "react";
import RobotTalkIcon from "@/common/images/robot-talk.svg";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import {
  ContentWrapper,
  DemoSectionWrapper,
  Height,
  Logo,
  MessageBox,
} from "./lesson.styles";

import { StartInfo, TalkiMessage } from "./components";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { findLessonById } from "@/api/query/lesson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { handleDeleteLessonMessage } from "@/api/query/lead-history";
import Hands, { SOURCES_TYPES } from "@/common/components/customer/Hands";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 80px);
  overflow-y: auto;
  text-align: center;
  margin: 10px 20px 0 20px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    margin: 0;
    width: 100%;
    height: fit-content;
    position: relative;
  }
`;

const TitleLesson = styled.h3`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  @media (min-width: 600px) {
    padding-top: 12px;
  }
`;

const TitleLessonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 95%;
  border-bottom: 1px solid #d9d9d9;

  @media (max-width: 600px) {
    flex-direction: column;
    border-bottom: none;
  }
`;

const Text = styled.h3`
  color: #3280fc;

  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;
  margin: 0;
`;

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 260px;
  gap: 5px;
  color: #868686;

  text-decoration: none;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const DemoScript = ({ setPageNumber }) => {
  const { id } = useParams();
  const [lesson, setLesson] = useState({});
  const [data, setData] = useState({});
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);
  const [showStarInfo, setShowStarInfo] = useState(false);

  const handleLoadLessonMutation = useMutation({
    mutationFn: (value) => findLessonById(value),
    onSuccess: ({ data }) => {
      setLesson(data);
    },
  });

  const handleDeleteLessonMessageMutation = useMutation({
    mutationFn: (value) => handleDeleteLessonMessage(value),
    onSuccess: ({ data }) => {
      window.location.reload(true);
    },
  });

  const handleClearConversation = () => {
    const payload = {};
    payload.lessonId = lesson._id;

    handleDeleteLessonMessageMutation.mutate(payload);
  };

  useEffect(() => {
    handleLoadLessonMutation.mutate(id);
  }, [id]);

  useEffect(() => {
    window.document.title = "Sarai - scenka";
  }, []);

  return (
    <>
      <PageWrapper>
        <TitleLessonWrapper>
          <BackWrapper to="/learn/categories">
            <FontAwesomeIcon icon={faChevronLeft} /> Back
          </BackWrapper>
          <Text>
            {" "}
            {lesson.name}{" "}
            <FontAwesomeIcon
              icon={faRefresh}
              style={{
                background: "#137A7B",
                color: "white",
                borderRadius: "15px",
                fontSize: "16px",
                padding: "3px",
              }}
              onClick={handleClearConversation}
            />{" "}
          </Text>
          <Hands type={SOURCES_TYPES.SCENE} forWhat={lesson._id} />
        </TitleLessonWrapper>
        <DemoSectionWrapper>
          <ContentWrapper>
            <Logo src={RobotTalkIcon} />
            <DemoConversationContext.Provider
              value={{
                data,
                setData,
                showButtoReccord,
                setShowButtoReccord,
                showLoading,
                setShowLoading,
                setPageNumber,
                showText2,
                setShowText2,
                lesson,
                showStarInfo,
                setShowStarInfo,
              }}
            >
              <MessageBox>
                <TalkiMessage />
                <div id="bottom-info" />
              </MessageBox>
            </DemoConversationContext.Provider>
          </ContentWrapper>
        </DemoSectionWrapper>
      </PageWrapper>
      {showStarInfo && (
        <StartInfo showStarInfo={showStarInfo} setShow={setShowStarInfo} />
      )}
    </>
  );
};

export default DemoScript;
