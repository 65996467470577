import { styled } from "styled-components";

export const Wrapper = styled.section`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const WrapperComapring = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

export const LeftInfo = styled.article`
  border-radius: 16px;
  background: #d4f3ea;
  padding: 40px;
  height: 320px;

  @media (max-width: 600px) {
    max-width: 340px;
    padding: 10px;
    height: fit-content;
  }
`;

export const RightInfo = styled.article`
  border-radius: 16px;
  background: rgba(79, 167, 180, 0.2);
  padding: 40px;
  height: 320px;

  @media (max-width: 600px) {
    max-width: 340px;
    padding: 10px;
    height: fit-content;
  }
`;

export const Title = styled.p`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  margin: 0;

  @media (max-width: 600px) {
    color: #05275d;
    text-align: center;
    font-family: Work Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.56px;
    max-width: 300px;
  }
`;

export const LeftTitle = styled(Title)`
  text-align: left;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 600px) {
    display: none;
    max-width: 340px;
  }
`;

export const TextInfo = styled.p`
  width: 414px;
  color: #05275d;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  white-space: pre;

  @media (max-width: 600px) {
    color: #05275d;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
`;

export const WrongImage = styled.img`
  width: 20px;
  height: 180px;
  margin-top: 25px;

  @media (max-width: 600px) {
    height: 80px;
    margin-top: 18px;
  }
`;

export const CorrectImage = styled.img`
  width: 19.5px;
  height: 132px;
  margin-top: 25px;

  @media (max-width: 600px) {
    height: 100px;
    margin-top: 18px;
  }
`;

export const ContentWrapperMobile = styled(ContentWrapper)`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`;
