import { loadRoadStats } from "@/api/query/report";
import CustomBar from "@/common/charts/Bar";
import { Color } from "@/common/colors/colors";
import Loading from "@/common/components/Loading";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import CustomTable from "@/common/components/custom/CustomTable";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
`;

const BuyRoad = () => {
  const [data, setData] = useState();

  const searchRef = useRef();

  const loadRoadStatsMutation = useMutation({
    mutationFn: (data) => loadRoadStats(data),
    onSuccess: ({ data }) => {
      const utms = [
        "Has utms",
        data.reduce(
          (prev, next) =>
            prev + (["landing"].includes(next.name) ? next.value.utm || 0 : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev + (next.name.includes("register") ? next.value.utm || 0 : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev +
            (["account_created"].includes(next.name) ? next.value.utm || 0 : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev + (["plan"].includes(next.name) ? next.value.utm || 0 : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev + (["pay"].includes(next.name) ? next.value.utm || 0 : 0),
          0
        ),
      ];

      const noUtms = [
        "Has not utms",
        data.reduce(
          (prev, next) =>
            prev +
            (["landing"].includes(next.name) ? next.value.without || 0 : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev +
            (next.name.includes("register") ? next.value.without || 0 : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev +
            (["account_created"].includes(next.name)
              ? next.value.without || 0
              : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev + (["plan"].includes(next.name) ? next.value.without || 0 : 0),
          0
        ),
        data.reduce(
          (prev, next) =>
            prev + (["pay"].includes(next.name) ? next.value.without || 0 : 0),
          0
        ),
      ];

      setData(() => [utms, noUtms]);
    },
  });

  const handleSearch = (e) => {
    e?.preventDefault();

    const payload = {};
    payload.days = searchRef.current.value;

    loadRoadStatsMutation.mutate(payload);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
      {loadRoadStatsMutation.isLoading && <Loading />}
      <div>
        <Form onSubmit={handleSearch}>
          <div>UTM VS NO UTM</div>
          <Input
            inputRef={searchRef}
            name="days"
            type="number"
            value={30}
            width={80}
            inputWidth={40}
            required
            requiredSign
          />
          <CustomSaveButton text="Search" />
        </Form>

        <CustomTable
          headers={["Type", "Landing", "Register", "Account", "Plan", "Buy"]}
          data={data}
        />
      </div>
    </>
  );
};

export default BuyRoad;
