import React from "react";
import Robot from "@/common/images/robot-allow-microfon.png";
import Arrow from "@/common/images/curved-thin-hand-drawn-arrows-down.svg";
import {
  Card,
  CustomArrow,
  StyledImage,
  TextBottom,
  TextTitle,
  Wrapper,
} from "./permisionPopUp.styles";

const PermisionPopUp = () => {
  return (
    <Wrapper>
      <Card className="fade__in__animation">
        <CustomArrow src={Arrow} alt="sarai " />
        <div>
          <TextTitle>Zezwól aplikacji na korzystanie z mikrofonu :)</TextTitle>
          <TextBottom>
            SarAI potrzebuje dostępu do mikrofonu, żeby z Tobą porozmawiać.
          </TextBottom>
        </div>
        <StyledImage src={Robot} alt="Sarai" />
      </Card>
    </Wrapper>
  );
};

export default PermisionPopUp;
