import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { findMails, reSendMail } from "@/api/query/mail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailReply } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { getHands } from "@/api/query/hands";
import { SOURCES_TYPES } from "@/common/components/customer/Hands";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const HEADERS = [
  "No.",
  "Created at",
  "By",
  "Decision",
  "Message",
  "Type",
  "Detail name",
];

export const formatDate = (d) => {
  if (!d) {
    return "------";
  }

  return moment(d).format("DD/MM/YYYY HH:mm");
};

const AdminHands = () => {
  const [tableData, setTableData] = useState();

  const getDetailName = (detail) => {
    if (detail.type === SOURCES_TYPES.SCENE) {
      return detail.lesson.name;
    }

    if (detail.type === SOURCES_TYPES.WORD) {
      return detail.wordLessonType.name;
    }

    return "------";
  };

  const findAllHandsMutation = useMutation({
    mutationFn: () => getHands(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((d, i) => [
            i + 1,
            formatDate(d.created_at),
            d.user.email,
            d.hand_decision,
            d.message || "-----",
            d.type,
            getDetailName(d),
          ])
      );
    },
  });

  useEffect(() => {
    findAllHandsMutation.mutate();
  }, []);

  return (
    <>
      {findAllHandsMutation.isLoading && <Loading />}
      <Wrapper>
        <Title>Hands</Title>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} csvData={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminHands;
