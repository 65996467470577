import React, { useContext } from "react";
import Robot from "@/common/images/robot-new-buy.svg";

import {
  CustomImage,
  ImageWrapper,
  TextInformation,
  WrapperSchema,
  Wrapper,
  StartButton,
} from "./meetSaraiSection.styles";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { clickStartDemoOnBuyPage } from "@/api/query/lead-history";
import styled from "styled-components";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";

const RestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const MeetSaraiSection = ({ data }) => {
  const navigate = useNavigate();

  const { handleSendIPstats } = useContext(IpStatsContext);

  const clickStartDemoOnBuyPageMutation = useMutation({
    mutationFn: (value) => clickStartDemoOnBuyPage(value),
  });

  return (
    <Wrapper>
      <WrapperSchema>
        <ImageWrapper>
          <CustomImage src={Robot} alt="Robot" />
        </ImageWrapper>
      </WrapperSchema>
      <RestWrapper>
        <TextInformation>{data.title}</TextInformation>
        <StartButton
          href={`${getNavigationPath("/")}#plan-offer`}
          onClick={() => {
            handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_MIDDLE);
          }}
        >
          {data.buyButton}
        </StartButton>
      </RestWrapper>
    </Wrapper>
  );
};

export default MeetSaraiSection;
