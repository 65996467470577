import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";

const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "report";

export const loadFeatureStats = async (payload) => {
  const data = await api.get(`/${PREFIX}/feature-usage/?days=${payload.days}`);

  return { data: data.data };
};

export const loadRoadStats = async (payload) => {
  const data = await api.get(`/${PREFIX}/road-stats?days=${payload.days}`);

  return { data: data.data };
};

export const getQualityIndicatorReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/quality-indicator?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: "bracket",
      }
    )}`
  );
  return { data: data.data };
};
