import React, { useState } from "react";
import styled from "styled-components";
import ArrowLeft from "./images/arrow-left.svg";
import ArrowDown from "../images/arrow-down.svg";
import ArrowUp from "./images/arrow-up.svg";
import { useNavigate } from "react-router-dom";

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
`;

const TopBar = styled.div`
  margin-top: 24px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    margin-left: 15px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  width: 80vw;
  border-radius: 16px;
  background: #fff;
  padding: 16px;

  @media (min-width: 600px) {
    width: 600px;
    border: 1px solid #d9d9d9;
    margin: 0 auto;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? "600" : "normal")};
    color: #05275d;
    text-align: center;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const Subtitle = styled.p`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  max-width: 300px;

  @media (min-width: 600px) {
    max-width: 600px;
  }
`;

const Question = styled.p`
  display: flex;
  justify-content: space-between;
  color: #05275d;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;

  margin: 0;
`;

const Answer = styled.p`
  color: #05275d;
  white-space: pre-wrap;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  margin: 15px 0 0 0;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const CONFIG = [
  {
    id: 1,
    question: "My audio isn't working.",
    answer: `In case of audio issues, consider the following tips: 

        Check browser sound permissions.

        Ensure device volume is adequate.

        Confirm proper speaker/headphone 
        connection.

        Review system sound settings for 
        muted apps.

        Try a different device or contact
        tech support.
    `,
  },
  {
    id: 2,
    question: "I can’t record my voice.",
    answer: `In case of recording sound issues, consider the following tips: 
        
        Verify browser mic permissions.

        Ensure mobile browser has mic access.

        Check functional microphone connection.

        Consider an alternate device or contact
        tech support.
    `,
  },
  {
    id: 3,
    question: "Can I use the application on more than one device?",
    answer: `Sure, simply log in on any compatible device for a seamless learning experience.`,
  },
  {
    id: 4,
    question: "Can I use SarAI offline?",
    answer: `No, SarAI requires an internet browser with an internet connection.
    `,
  },
  {
    id: 5,
    question: "How to change the AI tutor?",
    answer: `Navigate to "Tutor" tab in SarAI setting. Explore, and select your preferred tutor.
    `,
  },
  {
    id: 6,
    question: "How to extend access to the application after it expires?",
    answer: `You can renew your plan upon login after expiration.
    `,
  },
  {
    id: 7,
    question: "Can I receive an invoice?",
    answer: `Contact bok@saraiapp.com with NIP and company details.`,
  },
  {
    id: 8,
    question: "How can I reach tech support?",
    answer: `Email bok@saraiapp.com with issue details, device, and browser info.
    `,
  },
];

const ProfileHelp = () => {
  const [showQuestion, setShowQuestion] = useState();
  const navigate = useNavigate();

  return (
    <>
      <TopBar>
        <IconTitleWrapper>
          <img
            src={ArrowLeft}
            onClick={() => navigate("/profile")}
            style={{ cursor: "pointer" }}
          />
        </IconTitleWrapper>
        <Title>Help</Title>
        <IconTitleWrapper />
      </TopBar>
      <TopBar style={{ justifyContent: "center", margin: "-10px 0 20px 0" }}>
        <Subtitle>
          In case of questions/problems contacs us: <u>bok@saraiapp.com</u>
        </Subtitle>
      </TopBar>
      <CardWrapper>
        {CONFIG.map((l) => (
          <Card
            id={`language-${l.id}`}
            onClick={() =>
              setShowQuestion((prev) => (prev?.id === l.id ? null : l))
            }
            style={{}}
          >
            <Question>
              {l.question}
              <img
                src={showQuestion?.id === l.id ? ArrowUp : ArrowDown}
                style={{
                  cursor: "pointer",
                }}
              />
            </Question>
            {showQuestion?.id === l.id && <Answer>{l.answer}</Answer>}
          </Card>
        ))}
      </CardWrapper>
    </>
  );
};

export default ProfileHelp;
