import { ButtonWrapper, CustomButton } from "@/pages/landing/styles/wrappers";
import { styled } from "styled-components";

export const ButtonWrapperAge = styled(ButtonWrapper)`
  margin: -10px 0 10px 0;
  gap: 10px;

  @media (max-width: 600px) {
    gap: 2px;
  }

  @media (min-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const CustomButtonAge = styled(CustomButton)`
  @media (max-width: 600px) {
    padding: 6px;
  }

  @media (min-width: 600px) {
    padding: 10px 20px;
  }
`;
