import Travel from "@/common/images/image-1472.png";
import Sport from "@/common/images/image-1337.png";
import Technology from "@/common/images/image-1188.png";
import Food from "@/common/images/image-1634.png";
import Movies from "@/common/images/image-1174.png";
import Fashion from "@/common/images/image-1238.png";
import Science from "@/common/images/image-874.png";
import Art from "@/common/images/image-1277.png";
import Politics from "@/common/images/image-969.png";
import Personal from "@/common/images/image-418.png";
import SocialMedia from "@/common/images/image-1760.png";

export const HOBBIES = [
  {
    name: "Travel",
    icon: Travel,
    mobile_name: "Travel",
  },
  {
    name: "Technology",
    icon: Technology,
    mobile_name: "Technology",
  },
  {
    name: "Sports",
    icon: Sport,
    mobile_name: "Sports",
  },
  {
    name: "Food and cooking",
    icon: Food,
    mobile_name: "Food & Cooking",
  },
  {
    name: "Movies and TV shows",
    icon: Movies,
    mobile_name: "Movies & TV shows",
  },
  {
    name: "Fashion and style",
    icon: Fashion,
    mobile_name: "Fashion & Style",
  },
  {
    name: "Science and nature",
    icon: Science,
    mobile_name: "Science & Nature",
  },
  {
    name: "Art and culture",
    icon: Art,
    mobile_name: "Art & Culture",
  },
  {
    name: "Politics and current events",
    icon: Politics,
    mobile_name: "Politics & Events",
  },
  {
    name: "Personal development",
    icon: Personal,
    mobile_name: "Personal development",
  },
  {
    name: "Social media",
    icon: SocialMedia,
    mobile_name: "Social media",
  },
];
