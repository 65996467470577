import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EtapWapper = styled.div`
  color: #3280fc;

  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
`;

const LineWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Cicrcle = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 5px;
  justify-content: center;
  align-items: center;

  border-radius: 19px;
  border: 1px solid #d9d9d9;
  background: ${(prev) => prev.color};
  color: ${(prev) => prev.colorText};
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const Text = styled.div`
  color: ${(prev) => prev.color};

  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  line-height: 140%; /* 19.6px */
`;

const Element = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Line = styled.div`
  height: 1px;
  width: 100px;
  background: ${(prev) => prev.color};
  margin-top: 10px;
  margin: 10px -40px 0px -40px;

  @media (min-width: 600px) {
    width: 200px;
  }
`;

const Navigation = ({ level }) => {
  return (
    <Wrapper>
      <EtapWapper>
        <b>Krok {level + 1}</b> / 3
      </EtapWapper>
      <LineWrapper>
        <Element>
          <Cicrcle
            color={level >= 0 ? "#3280FC" : "#868686"}
            colorText={level >= 0 ? "white" : "#868686"}
          >
            1
          </Cicrcle>
          <Text
            color={level >= 0 ? "#3280FC" : "#868686"}
            colorText={level >= 0 ? "white" : "#868686"}
          >
            Rejestracja
          </Text>
        </Element>
        <Line color={level >= 1 ? "#3280FC" : "#D9D9D9"} />
        <Element>
          <Cicrcle
            color={level >= 1 ? "#3280FC" : "white"}
            colorText={level >= 1 ? "white" : "#868686"}
          >
            2
          </Cicrcle>
          <Text
            color={level >= 1 ? "#3280FC" : "#868686"}
            colorText={level >= 1 ? "white" : "#868686"}
          >
            Pakiet
          </Text>
        </Element>
        <Line color={level >= 2 ? "#3280FC" : "#D9D9D9"} />
        <Element>
          <Cicrcle
            color={level >= 2 ? "#3280FC" : "white"}
            colorText={level >= 2 ? "white" : "#868686"}
          >
            3
          </Cicrcle>
          <Text
            color={level >= 2 ? "#3280FC" : "#868686"}
            colorText={level >= 2 ? "white" : "#868686"}
          >
            Podsumowanie
          </Text>
        </Element>
      </LineWrapper>
    </Wrapper>
  );
};

export default Navigation;
