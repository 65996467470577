import PopUp from "@/common/components/PopUp";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import { ButtonBuy } from "@/pages/buy/components/planSection/planSection.styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TopText = styled.h3`
  color: red;
  max-width: 500px;
  margin: 60px 0 20px 0;
`;

const Text = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
`;

const NoButton = styled.button`
  display: inline-flex;
  padding: 15px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #aba9a9;
  min-width: 60px;
  color: white;
  font-size: 18px;

  @media (max-width: 600px) {
    padding: 5px 30px;
  }
`;

const YesButton = styled.button`
  display: inline-flex;
  padding: 15px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #3280fc;
  min-width: 60px;
  color: white;
  font-size: 18px;

  @media (max-width: 600px) {
    padding: 5px 30px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 40px;
`;

const Wrapper = styled.div`
  padding: 30px 20px;
  max-width: 600px;

  @media (max-width: 600px) {
    padding: 30px 10px;
    max-width: 300px;
  }
`;

const LessonEnd = ({ setClose }) => {
  const navigate = useNavigate();

  return (
    <PopUp setClose={setClose} closeIcon={false}>
      <Wrapper>
        <Text>
          <b>Gratulacje!</b> Cel ćwiczenia został osiągnięty. Zakończ lekcję lub
          rozmawiaj dalej.
        </Text>
        <ButtonWrapper>
          <NoButton onClick={() => navigate("/learn/categories")}>
            Zakończ
          </NoButton>
          <YesButton onClick={() => setClose(false)}>Rozmawiaj dalej</YesButton>
        </ButtonWrapper>
      </Wrapper>
    </PopUp>
  );
};

export default LessonEnd;
