import { findUsersLast } from "@/api/query/user";
import CustomBar from "@/common/charts/Bar";
import { Color } from "@/common/colors/colors";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

export const UserStats = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const handleData = (data) => {
    const { payments, costUsage } = data;

    const map = new Map();

    const now = moment().startOf("day");
    const minus30days = moment().subtract("30", "days").startOf("day");

    const diff = now.diff(minus30days, "days");

    const daysArr = [];

    for (let i = diff; i >= 0; i--) {
      daysArr.push(moment().subtract(i, "days").format("DD/MM/YYYY"));
    }

    payments.forEach((d) => {
      const date = moment(d.created_at).format("DD/MM/YYYY");

      if (map.has(date)) {
        let mapElement = map.get(date);
        mapElement.price += d.price;
        mapElement.amount += 1;

        return map.set(date, mapElement);
      }
      return map.set(date, { price: d.price, amount: 1 });
    });

    const map2 = new Map();

    costUsage.forEach((d) => {
      const date = moment(d.created_at).format("DD/MM/YYYY");

      if (map2.has(date)) {
        let mapElement = map2.get(date);
        mapElement.price += d.cost;
        mapElement.amount += 1;

        return map2.set(date, mapElement);
      }
      return map2.set(date, { price: d.cost, amount: 1 });
    });

    return {
      labels: daysArr,
      datasets: [
        {
          label: "Money",
          data: daysArr.map((d) => map.get(d)?.price / 100 || 0),
          backgroundColor: Color.DarkGray,
        },
        {
          label: "Amount",
          data: daysArr.map((d) => map.get(d)?.amount || 0),
          backgroundColor: "red",
        },
        {
          label: "Cost $",
          data: daysArr.map((d) => map2.get(d)?.price || 0),
          backgroundColor: "darkblue",
        },
      ],
    };
  };

  const findDataMutation = useMutation({
    mutationFn: (value) => findUsersLast(value),
    onSuccess: ({ data }) => {
      setChartData(handleData(data));
    },
  });

  useEffect(() => {
    const payload = {};
    payload.last_days = 30;

    findDataMutation.mutate(payload);
  }, []);

  return (
    <div>
      Money / Paid Accounts last 30 days
      <CustomBar data={chartData} />
    </div>
  );
};
