import { styled } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  align-items: fle;
  flex-direction: column;
  gap: 40px;
  padding: 0 10px;
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: left;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 0;
`;

export const SubTitle = styled.p`
  color: #05275d;
  width: 100%;
  margin: 0;
  text-align: left;
  font-family: Work Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.72px;

  @media (max-width: 600px) {
    font-size: 28px;
  }
`;

export const Element = styled.div`
  display: flex;
  max-width: 1030px;
  padding: 10px 30px;
  align-items: center;
  gap: 7px 20px;
  flex-shrink: 0;
  flex-wrap: wrap;
  border-radius: 23px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(19, 122, 123, 0.2);

  @media (max-width: 600px) {
    background: none;
    box-shadow: none;
    padding: 0px;
  }
`;

export const FaqTitle = styled.p`
  color: #137a7b;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.48px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const FaqSubtitle = styled.p`
  width: 990px;
  flex-shrink: 0;
  color: rgba(5, 39, 93, 0.54);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
