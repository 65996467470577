export const LEVELS = {
  BEGINNER: "beginner",
  BASIC: "basic",
  INTERMEDIATE: "intermediate",
  ADVANCED: "advanced",
  EXPERT: "expert",
};

export const LEVEL_OPTIONS = [
  {
    level: LEVELS.BEGINNER,
    name: `I am a beginner: I have little to no experience 
      with English and struggle with basic vocabulary and grammar.`,
    mobile_name: "I am a beginner",
  },
  {
    level: LEVELS.INTERMEDIATE,
    name: `I am intermediate: I have some understanding of English and can have simple conversations, but still struggle with more complex topics.`,
    mobile_name: "I am intermediate",
  },
  {
    level: LEVELS.ADVANCED,
    name: `I am advanced: I am confident in English, can express myself fluently, and have a good grasp of vocabulary and grammar.`,
    mobile_name: "I am advanced",
  },
];
