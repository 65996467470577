import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import Loading from "../Loading";
import styled from "styled-components";
import { createHands } from "@/api/query/hands";
import PopUp from "../PopUp";

import Like from "@/common/icons/like.svg";
import LikeFill from "@/common/icons/like-fill.svg";
import Dislike from "@/common/icons/dislike.svg";
import DislikeFill from "@/common/icons/dislike-fill.svg";

const HAND_DECISION = {
  UP: "HAND_UP",
  DOWN: "HAND_DOWN",
};

const Text2 = styled.p`
  color: #868686;

  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 260px;
`;

export const SOURCES_TYPES = {
  DAILY: "DAILY",
  SCENE: "SCENE",
  WORD: "WORD",
  FLASH_CARD: "FLASH_CARD",
  FLASH_CARD_NEW: "FLASH_CARD_NEW",
  FOTO_MODE: "FOTO_MODE",
};

const Skip = styled.button`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  text-decoration-line: underline;
  background-color: white;

  cursor: pointer;
`;

const Send = styled.button`
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: 17px 74px 14px 75px;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 200px;
    padding: 15px 35px 15px 35px;
  }
`;

const P = styled.p`
  color: #05275d;

  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: left;
  margin: 0;
  margin-top: 50px;
`;

const TitlePopUp = styled.h2`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 0;

  @media (max-width: 600px) {
    max-width: 200px;
  }
`;

const SubTitlePopUp = styled.h3`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;

const TextArea = styled.textarea`
  width: 700px;
  height: 92px;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #eef2f5;
  outline: none;
  border: none;
  resize: none;

  color: #05275d;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;

  @media (max-width: 600px) {
    width: 300px;
    height: 140px;
    padding: 5px;
  }
`;

const LetterCounterWrapper = styled.p`
  text-align: right;
  color: #868686;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: right;
  }
`;

const PopUpWrapper = styled.form`
  padding: 50px;

  @media (max-width: 600px) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Hands = ({ type, forWhat }) => {
  const [decision, setDecision] = useState();
  const [showPopUp, setShowPopUp] = useState();
  const [lengthCounter, setLengthCounter] = useState(0);

  const messegeRef = useRef();

  const handleAddHandMutation = useMutation({
    mutationFn: (payload) => createHands(payload),
  });

  const handleAddHand = (e) => {
    e?.preventDefault();

    const payload = {};
    setDecision(() => showPopUp);

    payload.decision = showPopUp;
    payload.type = type;
    payload.for = forWhat;
    payload.message = messegeRef?.current?.value || null;

    handleAddHandMutation.mutate(payload);
    setShowPopUp(null);
  };

  const handleChange = (e) => {
    const message = messegeRef.current.value;

    if (message.length > 300) {
      return (messegeRef.current.value = message.slice(0, 300));
    }

    setLengthCounter(() => message.length);
  };

  return (
    <>
      {handleAddHandMutation.isLoading && <Loading />}
      <Wrapper>
        <Text2>Do you enjoy this lesson?</Text2>
        <img
          src={decision === HAND_DECISION.UP ? LikeFill : Like}
          onClick={() => setShowPopUp(HAND_DECISION.UP)}
          style={{ width: "20px", cursor: "pointer" }}
        />
        <img
          src={decision === HAND_DECISION.DOWN ? DislikeFill : Dislike}
          onClick={() => setShowPopUp(HAND_DECISION.DOWN)}
          style={{ width: "20px", cursor: "pointer" }}
        />
      </Wrapper>
      {showPopUp && (
        <PopUp closeIcon={false}>
          <PopUpWrapper onSubmit={handleAddHand}>
            <TitlePopUp>Thank you for your evaluation!</TitlePopUp>{" "}
            <SubTitlePopUp>Your vote is important to us!</SubTitlePopUp>
            <P>Tell us what you think about this lesson:</P>
            <TextArea
              ref={messegeRef}
              onChange={(e) => handleChange(e)}
              required={true}
            />
            <LetterCounterWrapper>{lengthCounter} / 300</LetterCounterWrapper>
            <ButtonsWrapper>
              <Skip type="button" onClick={() => handleAddHand()}>
                SKIP
              </Skip>
              <Send type="submit">SEND</Send>
            </ButtonsWrapper>
          </PopUpWrapper>
        </PopUp>
      )}
    </>
  );
};

export default Hands;
