const { default: styled } = require("styled-components");

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  text-align: center;
  margin: 20px 20px 0 20px;
  gap: 20px;
  overflow: auto;

  @media (min-width: 600px) {
    height: calc(var(--vh, 1vh) * 100 - 148px);
    width: 100%;
    margin: 0 auto;
    padding: 36px 20px 0;
    box-sizing: border-box;
    gap: 24px;
  }
`;

export const MainDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 600px) {
    gap: 16px;
    padding-bottom: 28px;
    width: 80%;
  }
`;

export const UserDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media (min-width: 600px) {
    gap: 12px;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  width: 70px;
  border-radius: 50%;
  height: 70px;
  overflow: hidden;
  cursor: pointer !important;
  position: relative;
`;

export const UserNameWrapper = styled.h4`
  display: flex;
  align-items: center;
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  margin: 0;
`;

export const Card = styled.div`
  padding: 15px 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(79, 167, 180, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 380px);

  @media (min-width: 600px) {
    gap: 20px;
    box-shadow: none;
    padding: 0;
    overflow-y: visible;
  }

  img {
    cursor: pointer;
  }
`;

export const Subtitle = styled.p`
  color: #000;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
  margin: 10px 0 0 0;

  @media (min-width: 600px) {
    font-size: 20px;
    margin: 0px 0 -20px 0;
  }
`;

export const Logout = styled.div`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

export const Label = styled.label`
  color: #000;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
`;

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    align-items: center;
    padding-top: 24px;
    gap: 24px;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 18px;
  }
`;

export const SubscriptionDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? "600" : "normal")};
    color: #05275d;
    text-align: center;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    line-height: 140%; /* 28px */
  }

  input {
    margin: 0;
  }
`;

export const Border = styled.div`
  width: 100%;
  min-height: 1px;
  max-height: 1px;
  background: #d9d9d9;
`;

export const SaveButton = styled.button`
  margin: 10px 0 5px 0;
  align-self: center;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #3280fc;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  transition: all 0.3s;
  border: 2px solid #3280fc;

  @media (min-width: 600px) {
    padding: 10px 28px;
    font-size: 18px;
  }

  &:hover {
    color: #3280fc;
    background-color: #fff;
  }
`;

export const LogoutButton = styled.button`
  display: none;
  margin: 10px 0 5px 0;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  outline: none;
  background: #3280fc;
  color: #fff;
  border: 2px solid #3280fc;
  cursor: pointer;

  font-family: Work Sans;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;

  transition: all 0.3s;

  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  &:hover {
    color: #3280fc;
    background: #fff;

    svg {
      fill: #3280fc;
    }
  }

  :hover {
    transform: none;
  }
`;

export const MobileLogoutContainter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (min-width: 600px) {
    display: none;
  }
`;

export const LogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  color: #000;
  text-align: left;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
`;

export const ActionsSectionWrapper = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

export const ImageInput = styled.input`
  display: none;
`;
