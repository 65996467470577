import React, { useContext, useState } from "react";
import {
  ButtonBuy,
  MarginPlan,
  SubTop,
  Title,
  TopInfo,
  Wrapper,
  MoneyInfo,
  MoneyBottomInfo,
  ElementWrapper,
  IconWrapper,
} from "./planSection.styles";
import BuyPopUp from "./BuyPopUp";
import Check from "@/common/images/check-icon-one.svg";
import Bot from "@/common/images/icon.svg";
import { startCheckout } from "@/api/query/lead-history";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import Next from "@/common/images/next.svg";
import Previous from "@/common/images/previous.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";

export const PlanWrapper = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    max-width: 300px;
    margin-top: -20px;
    gap: 10px;
  }
`;

export const MainPlan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 300px;
  height: 320px;
  border-radius: 16px;
  background: #0da9ab;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    transform: scale(0.95);
    cursor: pointer;
  }
`;

export const Price = styled.h2`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0;
`;

const LeftRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 300px;
  height: 320px;
  border-radius: 16px;
  background: #eefdff;

  transition: all 0.3s;

  &:hover {
    transform: scale(0.95);
    cursor: pointer;
  }
`;

const WrapperIcon = styled.div`
  top: 48%;
  position: absolute;
  z-index: 99999;
  border: none;
`;

const PlansWrapper = styled.div`
  display: flex;
  gap: 25px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const PlanName = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TextAdionalInfo = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Flex1 = styled.div`
  flex: 1;
`;

const Border = styled.div`
  width: 230px;
  height: 1px;
  background: rgba(79, 167, 180, 0.2);
`;

const SaveWrapepr = styled.div`
  display: inline-flex;
  padding: 3px 0;
  justify-content: center;
  align-items: center;
  background: #8befd2;
  width: 350px;
  margin-top: 10px;

  @media (max-width: 1000px) {
    width: 300px;
  }

  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Discount = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through red;
  -webkit-text-decoration: line-through red;
`;

const WrapperElement = styled.div`
  width: 360px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const MobileWrapper = styled.div`
  display: flex;

  @media (min-width: 1200px) {
    display: none;
  }
`;

const PlanSection = ({ data }) => {
  const navigate = useNavigate();
  const { handleSendIPstats } = useContext(IpStatsContext);
  return (
    <>
      <Wrapper id="plan-offer">
        <Title>{data.title} </Title>
        <MarginPlan>
          <TopInfo style={{ marginTop: "10px" }}>{data.subTitle}</TopInfo>
          <PlansWrapper>
            <LeftRightWrapper
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_BRONZE);
                window?.fbq("track", "AddToCart");
                window?.gtag("event", "add_to_cart");
                window?.uetq?.push("event", "add_to_cart", {
                  revenue_value: 30,
                  currency: "PLN",
                });

                localStorage.setItem("picked_plan", "1");
                navigate(getNavigationPath("/register"));
              }}
            >
              <PlanName>Bronze</PlanName>
              <Border />
              <Flex1 />
              <TextAdionalInfo>{data.bronzeText}</TextAdionalInfo>
              <Flex1 />
              <Price>{data.bronzePriceText}</Price>
              <SubTop style={{ color: "white" }}></SubTop>
            </LeftRightWrapper>
            <MainPlan
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_GOLD);
                window?.fbq("track", "AddToCart");
                window.gtag("event", "add_to_cart");
                window.gtag("event", "conversion", {
                  send_to: "AW-11121636531/sbTqCNnOmZQZELPpmrcp",
                });
                window?.uetq?.push("event", "add_to_cart", {
                  revenue_value: 75,
                  currency: "PLN",
                });

                localStorage.setItem("picked_plan", "2");
                navigate(getNavigationPath("/register"));
              }}
            >
              <PlanName style={{ color: "white" }}>Gold</PlanName>
              <Border style={{ background: "white" }} />
              <SubTop style={{ color: "white" }}>{data.goldTopText}</SubTop>
              <Flex1 />
              <TextAdionalInfo style={{ color: "white" }}>
                {data.goldMidText}
              </TextAdionalInfo>
              <Flex1 />
              <Discount style={{ color: "white" }}>
                {data.bronzePriceText}
              </Discount>
              <Price style={{ color: "white" }}>{data.goldPriceText}</Price>
              <SubTop style={{ fontStyle: "normal", color: "white" }}>
                {data.goldFinalPriceText}
              </SubTop>
            </MainPlan>
            <LeftRightWrapper
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PLATINUM);
                window?.fbq("track", "AddToCart");
                window.gtag("event", "add_to_cart");
                window.gtag("event", "conversion", {
                  send_to: "AW-11121636531/sbTqCNnOmZQZELPpmrcp",
                });
                window?.uetq?.push("event", "add_to_cart", {
                  revenue_value: 120,
                  currency: "PLN",
                });
                localStorage.setItem("picked_plan", "3");
                navigate(getNavigationPath("/register"));
              }}
            >
              <PlanName>Platinium</PlanName>
              <Border />
              <SubTop>{data.platinumTopText}</SubTop>
              <SaveWrapepr>{data.platinumSaveText}</SaveWrapepr>
              <Flex1 />
              <TextAdionalInfo>{data.platinumMiddleText}</TextAdionalInfo>
              <Flex1 />
              <Discount>{data.bronzePriceText}</Discount>
              <Price>{data.platinumPriceText}</Price>
              <SubTop style={{ fontStyle: "normal" }}>
                {data.platinumFullPriceText}
              </SubTop>
            </LeftRightWrapper>
          </PlansWrapper>
          <MobileWrapper>
            <Carousel
              axis="horizontal"
              autoPlay={true}
              infiniteLoop={true}
              width="360px"
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <WrapperIcon onClick={onClickHandler} style={{ left: "0" }}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{ fontSize: "30px" }}
                  />
                </WrapperIcon>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <WrapperIcon onClick={onClickHandler} style={{ right: "0" }}>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ fontSize: "30px" }}
                  />
                </WrapperIcon>
              )}
            >
              <WrapperElement
                onClick={() => {
                  handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_BRONZE);
                  localStorage.setItem("picked_plan", "1");
                  window?.fbq("track", "AddToCart");
                  window.gtag("event", "add_to_cart");
                  window.gtag("event", "conversion", {
                    send_to: "AW-11121636531/sbTqCNnOmZQZELPpmrcp",
                  });
                  window?.uetq?.push("event", "add_to_cart", {
                    revenue_value: 30,
                    currency: "PLN",
                  });

                  navigate(getNavigationPath("/register"));
                }}
              >
                <LeftRightWrapper>
                  <PlanName>Bronze</PlanName>
                  <Border />
                  <Flex1 />
                  <TextAdionalInfo>{data.bronzeText}</TextAdionalInfo>
                  <Flex1 />
                  <Price>{data.bronzePriceText}</Price>
                  <SubTop style={{ color: "white" }}></SubTop>
                </LeftRightWrapper>
              </WrapperElement>
              <WrapperElement
                onClick={() => {
                  handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_GOLD);
                  window?.fbq("track", "AddToCart");
                  window.gtag("event", "add_to_cart");
                  window.gtag("event", "conversion", {
                    send_to: "AW-11121636531/sbTqCNnOmZQZELPpmrcp",
                  });
                  window?.uetq?.push("event", "add_to_cart", {
                    revenue_value: 75,
                    currency: "PLN",
                  });

                  localStorage.setItem("picked_plan", "2");
                  navigate(getNavigationPath("/register"));
                }}
              >
                <MainPlan>
                  <PlanName style={{ color: "white" }}>Gold</PlanName>
                  <Border style={{ background: "#8BEFD2" }} />
                  <SubTop style={{ color: "white" }}>{data.goldTopText}</SubTop>
                  <Flex1 />
                  <TextAdionalInfo style={{ color: "white" }}>
                    {data.goldMidText}
                  </TextAdionalInfo>
                  <Flex1 />
                  <Discount style={{ color: "white" }}>
                    {data.bronzePriceText}
                  </Discount>
                  <Price style={{ color: "white" }}>{data.goldPriceText}</Price>
                  <SubTop style={{ fontStyle: "normal", color: "white" }}>
                    {data.goldFinalPriceText}
                  </SubTop>
                </MainPlan>
              </WrapperElement>
              <WrapperElement
                onClick={() => {
                  handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PLATINUM);
                  localStorage.setItem("picked_plan", "3");
                  window?.fbq("track", "AddToCart");
                  window.gtag("event", "add_to_cart");
                  window.gtag("event", "conversion", {
                    send_to: "AW-11121636531/sbTqCNnOmZQZELPpmrcp",
                  });
                  window?.uetq?.push("event", "add_to_cart", {
                    revenue_value: 120,
                    currency: "PLN",
                  });

                  navigate(getNavigationPath("/register"));
                }}
              >
                <LeftRightWrapper>
                  <PlanName>Platinium</PlanName>
                  <Border />
                  <SubTop>{data.platinumTopText}</SubTop>
                  <SaveWrapepr> {data.platinumSaveText}</SaveWrapepr>
                  <Flex1 />
                  <TextAdionalInfo>{data.platinumMiddleText}</TextAdionalInfo>
                  <Flex1 />
                  <Discount> {data.bronzePriceText}</Discount>
                  <Price>{data.platinumPriceText}</Price>
                  <SubTop style={{ fontStyle: "normal" }}>
                    {data.platinumFullPriceText}
                  </SubTop>
                </LeftRightWrapper>
              </WrapperElement>
            </Carousel>
          </MobileWrapper>
          <ButtonBuy
            onClick={() => {
              handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_BUY);

              window?.fbq("track", "AddToCart");
              window.gtag("event", "add_to_cart");
              window.gtag("event", "conversion", {
                send_to: "AW-11121636531/sbTqCNnOmZQZELPpmrcp",
              });
              window?.uetq?.push("event", "add_to_cart", {
                revenue_value: 75,
                currency: "PLN",
              });

              navigate(getNavigationPath("/register"));
            }}
            style={{ cursor: "pointer" }}
          >
            {data.buttonText}
          </ButtonBuy>
        </MarginPlan>
      </Wrapper>
    </>
  );
};

export default PlanSection;
