import { styled } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const Title = styled.h1`
  color: #05275d;
  text-align: left;
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  max-width: 345px;
  margin: 0;

  @media (min-width: 600px) {
    max-width: 1200px;
    text-align: center;
  }
`;

export const Subtitle = styled.h3`
  color: #0da9ab;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 200px;
  text-align: left;
  margin: 0;
  margin-left: -140px;

  @media (min-width: 600px) {
    max-width: 1200px;
    text-align: center;
    margin: 0;
  }
`;
