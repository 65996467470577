import { getWordLessonTopType } from "@/api/query/word-lesson-top-type";
import {
  createNewWordLessonType,
  updateNewWordLessonType,
} from "@/api/query/word-lesson-type";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import UserProile from "@/common/icons/user-profile.svg";
import { getBase64 } from "@/common/functions/getBase64";

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ImageWrapper = styled.div`
  width: 70px;
  border-radius: 50%;
  height: 70px;
  overflow: hidden;
`;

export const ImageInput = styled.input`
  display: none;
`;

const AdminWordLessonTypeEdit = ({ setEdit, edit }) => {
  const [categoryOptions, setCategoryOptions] = useState();
  const [selectedTopCategory, setSelectedTopCategory] = useState();
  const fileRef = useRef();
  const translatoinRef = useRef();

  const [currentBase64, setCurrentBase64] = useState(
    edit.file?.base64 || UserProile
  );

  const nameRef = useRef();

  const findWordLessonTopMutation = useMutation({
    mutationFn: () => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({ label: d.name, value: d._id }));
      setCategoryOptions(options);
      setSelectedTopCategory(
        options.find((o) => edit?.wordLessonTopType?._id === o.value) || {}
      );
    },
  });

  const createWordLessonTypeMutation = useMutation({
    mutationFn: (value) => createNewWordLessonType(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonTypeMutation = useMutation({
    mutationFn: (value) => updateNewWordLessonType(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    if (!selectedTopCategory) {
      return;
    }

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.wordTopType = selectedTopCategory.value;
    payload.base64 = currentBase64;
    payload.translation = translatoinRef.current.value;

    if (edit._id) {
      return updateWordLessonTypeMutation.mutate(payload);
    }

    createWordLessonTypeMutation.mutate(payload);
  };

  const handleSelectFile = async () => {
    const file = fileRef.current.files[0];
    const base64 = await getBase64(file);

    setCurrentBase64(base64);
  };

  useEffect(() => {
    findWordLessonTopMutation.mutate();
  }, []);

  return (
    <>
      {(findWordLessonTopMutation.isLoading ||
        updateWordLessonTypeMutation.isLoading ||
        createWordLessonTypeMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Wrod lesson type {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)} noValidate>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={200}
            width={130}
            required
            requiredSign
            value={edit.name}
          />
          <Input
            inputRef={translatoinRef}
            name="Translation"
            inputWidth={200}
            width={130}
            required
            requiredSign
            value={edit.translation}
          />
          <SelectInput
            width={80}
            name="category"
            options={categoryOptions}
            setSelected={setSelectedTopCategory}
            selected={selectedTopCategory}
          />
          <ImageWrapper>
            <ImageInput
              id="file-input"
              type="file"
              ref={fileRef}
              onChange={handleSelectFile}
            />
            <label htmlFor="file-input">
              <img
                alt=""
                src={currentBase64}
                style={{ width: "100%", height: "auto" }}
              />
            </label>
          </ImageWrapper>
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminWordLessonTypeEdit;
