import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "mail";

export const findMails = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const reSendMail = async (payload) => {
  const data = await api.post(`/${PREFIX}/resend/${payload._id}`);
  return { data: data.data };
};

export const sendUPsaleMail = async (payload) => {
  const data = await api.post(`/${PREFIX}/upsale/${payload.email}`);
  return { data: data.data };
};

export const sendBeforeExpired = async (payload) => {
  const data = await api.post(`/${PREFIX}/before-expired/${payload.email}`);
  return { data: data.data };
};
