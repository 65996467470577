import React from "react";
import Select from "react-select";

const customStyles = (width, fontSize) => {
  return {
    container: (provided) => ({
      ...provided,
      width: `${width}px`,
      fontSize,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      width: `${width}px`,
      fontSize,
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
    menu: (provided) => ({
      ...provided,
      width: `${width}px`,
      fontSize,
    }),
    menuList: (provided) => ({
      ...provided,
      width: `${width}px`,
      fontSize,
    }),
    option: (provided) => ({
      ...provided,
    }),
  };
};

const SelectInput = ({
  name,
  options,
  setSelected,
  selected,
  multiple = false,
  width = 300,
  selectWidth = 260,
  setAccesses,
  labelUp = false,
  showLabel = true,
  onChange = () => {},
  color = "black",
  required = false,
  placeholder = "Select...",
  id,
  disabled = false,
  fontSize = 18,
  margin = "10px",
  transform = 1,
}) => {
  return (
    <div
      style={{
        display: labelUp ? "grid" : "flex",
        alignItems: "center",
        gap: labelUp ? "10px" : "30px",
        margin: margin,
        borderRadius: "20px !important",
      }}
    >
      {showLabel && (
        <label
          style={{
            width: `${width}px`,
            textAlign: "right",
            fontSize: `${fontSize}px`,
          }}
        >
          <strong style={{ color: color }}>{name}:</strong>
        </label>
      )}
      <div style={{ transform: `scale(${transform})` }}>
        <Select
          id={id}
          value={selected}
          onChange={setSelected}
          options={options}
          isMulti={multiple}
          styles={customStyles(selectWidth, fontSize)}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default SelectInput;
