import Frame1 from "@/common/images/objects.svg";
import Frame2 from "@/common/images/imagesframe.svg";
import Frame3 from "@/common/images/frame.svg";

export const CONFIG = [
  {
    image: Frame1,
    title: "Indywidualny plan konwersacji",
    text: "Rozmawiaj codziennie na dowolny temat i śledź swoje postępy! Wyznaczaj cele i osiągaj je.",
  },
  {
    image: Frame2,
    title: "Wsparcie dwujęzycznego Inteligentnego Asystenta",
    text: "SarAI rozmawia z Tobą po angielsku, ale możesz odpowiedzieć mu po polsku lub poprosić o tłumaczenie.",
  },
  {
    image: Frame3,
    title: "Natychmiastowa informacja zwrotna",
    text: "Rozmawiaj i wdrażaj wskazówki SarAI, jak na tradycyjnej lekcji z nauczycielem.",
  },
];
