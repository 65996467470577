export const IP_STATS_ENUM = {
  LANDING: "landing",
  REGISTER_BY_TOP: "register_by_top",
  REGISTER_BY_MIDDLE: "register_by_middle",
  REGISTER_BY_LOGIN: "register_by_login",
  REGISTER_BY_BRONZE: "register_by_bronze",
  REGISTER_BY_GOLD: "register_by_gold",
  REGISTER_BY_PLATINUM: "register_by_platinum",
  REGISTER_BY_BUY: "register_by_buy",
  REGISTER_BY_EMAIL: "register_by_email",
  PLAN: "plan",
  PAY: "pay",
  LOGIN: "login",
  CHECK: "check",
  PASS_DEMO: "pass_demo",
  CLICK_DEMO: "click_demo",
  CLICK_DEMO_MOBILE: "click_demo_mobile",
  ACCOUNT_CREATED: "account_created",
  TALKING_IN_FOTO_MODE: "talking_in_foto_mode",
  MAIL_UP_SALE_BACK: "mail_up_sale_back",
  ACCOUNT_EXPIRED_WINDOW_PAY: "account_expired_window_pay",
  PAY_IN_APPLICATION: "pay_in_application",
  LOGIN_TO_SYSTEM: "login_to_system",
  MAIL_BEFORE_EXPIRED: "mail_before_expired",
};
