import React, { useRef } from "react";
import Image from "@/common/icons/image-icon.svg";
import styled from "styled-components";
import { getBase64 } from "../functions/getBase64";
const ImageContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 70px;
  border-radius: 50%;
  height: 70px;
  overflow: hidden;
`;

const ImageContainer = ({ image, setImage }) => {
  const imageRef = useRef();

  const handleSelectFile = async () => {
    const file = imageRef.current.files[0];
    const base64 = await getBase64(file);
    setImage(base64);
  };

  return (
    <ImageContainerWrapper>
      <ImageWrapper>
        <ImageInput
          id="file-input"
          type="file"
          name="cv"
          ref={imageRef}
          onChange={handleSelectFile}
        />
        <label htmlFor="file-input">
          <img
            alt=""
            src={image || Image}
            style={{ width: "100%", height: "auto" }}
          />
        </label>
      </ImageWrapper>
    </ImageContainerWrapper>
  );
};

export default ImageContainer;
