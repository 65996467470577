import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ArrowLeft from "./images/arrow-left.svg";
import { useMutation } from "react-query";
import {
  changeUserHobbyLanguage,
  loadUserLearningData,
} from "@/api/query/user";
import Loading from "@/common/components/Loading";
import { MessageContext } from "@/common/contexts/MessageContext";
import { useNavigate } from "react-router-dom";
import { getWordLessonTopType } from "@/api/query/word-lesson-top-type";

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
`;

const TopBar = styled.div`
  margin-top: 24px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    margin-left: 15px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  width: 80vw;
  margin-top: 20px;
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (min-width: 600px) {
    margin: 0 auto;
    justify-content: center;
    max-width: 800px;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  border-radius: 10px;
  background: #dcedf0;
  padding: 5px;
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? "600" : "normal")};
    color: #05275d;
    text-align: center;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const SearchInput = styled.input`
  display: inline-flex;
  padding: 10px 0px 10px 20px;
  justify-content: center;
  align-items: flex-start;
  width: 250px;

  border-radius: 50px;
  background: #fff;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }
`;

const LanguageName = styled.p`
  color: #000;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 171%; /* 23.94px */
  letter-spacing: 0.28px;
  margin: 0;
`;

const Subtitle = styled.p`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  max-width: 300px;
`;

const ProfileHobbies = () => {
  const [languages, setLanguages] = useState([]);
  const [userData, setUserData] = useState();

  const navigate = useNavigate();

  const searchRef = useRef();
  const { addMessage } = useContext(MessageContext);

  const loadDataMutation = useMutation({
    mutationFn: () => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      setLanguages(data);
    },
  });

  const loadUserMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
    },
  });

  const changeUserHobbyMutation = useMutation({
    mutationFn: (data) => changeUserHobbyLanguage(data),
    onSuccess: ({ data }) => {
      loadUserMutation.mutate();
      addMessage("Hobby changed", "success");
    },
    onError: () => {
      addMessage("You can select max 3 hobbies", "error");
    },
  });

  const handleChangeLanguage = (hobby) => {
    const payload = {
      hobby,
    };

    changeUserHobbyMutation.mutate(payload);
  };

  useEffect(() => {
    loadDataMutation.mutate();
    loadUserMutation.mutate();
  }, []);

  return (
    <>
      {(loadDataMutation.isLoading ||
        changeUserHobbyMutation.isLoading ||
        loadUserMutation.isLoading) && <Loading />}
      <TopBar>
        <IconTitleWrapper>
          <img src={ArrowLeft} onClick={() => navigate("/profile")} />
        </IconTitleWrapper>
        <Title>Change your interests</Title>
        <IconTitleWrapper />
      </TopBar>
      <TopBar style={{ justifyContent: "center", margin: "-10px 0 20px 0" }}>
        <Subtitle>You can pick maximum 3 hobbies.</Subtitle>
      </TopBar>
      <Card>
        {languages.map((l) => (
          <Section
            id={`language-${l._id}`}
            onClick={() => handleChangeLanguage(l._id)}
            style={{
              background: userData?.hobby?.includes(l._id)
                ? "#8BEFD1"
                : "#DCEDF0",
              color: userData?.hobby?.includes(l._id) ? "#DCEDF0" : "#8BEFD1",
            }}
          >
            <div style={{ width: "14px" }}>
              <img src={l.file.base64} style={{ width: "100%" }} />
            </div>
            <LanguageName>{l.name}</LanguageName>
          </Section>
        ))}
      </Card>
    </>
  );
};

export default ProfileHobbies;
