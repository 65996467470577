import React, { useContext } from "react";
import styled from "styled-components";
import Visa from "../icons/Visa blue.svg";
import Master from "../icons/mastercard.svg";
import Blik from "../icons/blik.svg";
import Transfer from "../icons/przelewy.svg";
import Payu from "../icons/payou.logo.svg";
import { useMutation } from "react-query";
import { createCustomerOneTimeOrder } from "@/api/query/payu";
import { CONFIG_DATA_ARRAY } from "@/pages/register/components/PlanView";
import { RegisterContext } from "@/pages/register/context/register.context";

const PaymentIcon = styled.img`
  width: 60px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  max-width: 300px;
  padding: 20px;

  @media (min-width: 600px) {
    min-width: 500px;
    padding: 30px;
  }
`;
const Title = styled.h3`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0;

  @media (max-width: 600px) {
    max-width: 200px;
  }
`;

const SubTitle = styled.p`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Price = styled.p`
  width: 211px;
  height: 57px;
  flex-shrink: 0;
  color: #05d698;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin: 0;

  text-align: center;
`;

const Pacakge = styled.p`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  text-align: left;
`;

const SubPacakge = styled.p`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;

  text-align: left;
`;

const ImgeWrapper = styled.img`
  max-width: 40px;
`;

const DataText = styled.p`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-align: left;
  margin: 0;
`;

const Border = styled.div`
  height: 1px;

  margin-top: 10px;
  background: rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const LetMailButton = styled.button`
  display: flex;
  width: 200px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #3280fc;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  /* Button */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.1);
  }
`;

const PayuLink = styled.a`
  color: #a6c307;

  &:hover {
    color: darkgreen;
  }
`;

const PaymentSubmitView = () => {
  const { user, plan } = useContext(RegisterContext);

  const handlePickPlanMutation = useMutation({
    mutationFn: (value) => createCustomerOneTimeOrder(value),
    onSuccess: (response) => {
      window.location.replace(response.data);
    },
  });

  const handleCreateOrder = (e) => {
    e.preventDefault();

    const payload = {};
    payload.userId = user._id;
    handlePickPlanMutation.mutate(payload);
  };

  return (
    <>
      <Wrapper>
        <Title>Podsumowanie</Title>
        <SubTitle>Produkt:</SubTitle>
        <Pacakge>
          SarAI Pakiet{" "}
          {CONFIG_DATA_ARRAY.find((cd) => cd.type === plan.plan_type).label}
        </Pacakge>
        <SubPacakge>
          Dostęp na{" "}
          {CONFIG_DATA_ARRAY.find((cd) => cd.type === plan.plan_type).months}
        </SubPacakge>
        <Border />
        <SubTitle>Dane:</SubTitle>
        <DataText>
          Imie i nazwisko: {user.name} {user.surname}
        </DataText>
        <DataText>{user.email}</DataText>
        <Border />
        <SubTitle>Forma płatności:</SubTitle>
        <ImageWrapper>
          <PaymentIcon
            src={Payu}
            style={{
              width: "90px",
              marginBottom: "10px",
              marginTop: "-15px",
            }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <PaymentIcon src={Blik} style={{ width: "40px" }} />
          <PaymentIcon
            src={Visa}
            style={{
              width: "50px",
            }}
          />
          <PaymentIcon
            src={Master}
            style={{
              width: "30px",
            }}
          />
          <PaymentIcon
            src={Transfer}
            style={{
              width: "65px",
            }}
          />
        </ImageWrapper>
        <Border />
        <SubTitle>Do zapłaty dzisiaj:</SubTitle>
        <Price>
          {
            CONFIG_DATA_ARRAY.find((cd) => cd.type === plan.plan_type)
              .full_price
          }{" "}
          zł
        </Price>
      </Wrapper>
      <ActionWrapper onSubmit={(e) => handleCreateOrder(e)}>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <input type="checkbox" id="rules_2" required defaultChecked />
          <label
            htmlFor="rules_2"
            style={{
              cursor: "pointer",
              display: "flex",
              gap: "3px",
              padding: "0 0 0 5px ",
            }}
          >
            {" "}
            Akceptuję
            <div
              onClick={() => window.open("/Regulamin.pdf")}
              style={{ textDecoration: "underline" }}
            >
              regulamin
            </div>
            Sarai
          </label>
        </div>
        <div htmlFor="rules_1" style={{ minWidth: "180px" }}>
          Płacąc akceptujesz{" "}
          <PayuLink
            href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf"
            target="_blank"
          >
            "Zasady płatności PayU"
          </PayuLink>
          .
        </div>
        <LetMailButton
          id="initiate_checkout"
          type="submit"
          style={{ fontSize: "18px" }}
        >
          KUPUJĘ I PŁACĘ
        </LetMailButton>
      </ActionWrapper>
    </>
  );
};

export default PaymentSubmitView;
