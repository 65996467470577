import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import { getTranslation } from "@/api/query/workflow";
import { useTypeWritterFlow } from "@/common/hooks/useTypeWritterFlow";
import BotMessage from "../Message/BotMessage";
import TalkingMicrofon from "./TalkingMicrofon";
import UserMessage from "./UserMessage";
import BotMessageTalking from "./BotMessageTalking";
import { Howl } from "howler";

export const TALKING_ROLES = {
  USER: "user",
  BOT: "bot",
};

export const TextInformationWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0;
`;

const TalkiMessage = () => {
  const { setShowButtoReccord, setShowText2, Recording7B } = useContext(
    DemoConversationContext
  );

  const [conversationWithChatGPT, setConversationWithChatGPT] = useState([]);
  const [current, setCurrent] = useState();
  const [showMessagesTranslations, setShowMessagesTranslations] = useState([]);
  const [translationMessages, setTrnaslationMessages] = useState([]);

  const TEXT = () => {
    return {
      eng: `Now, let's move on to conversation practice. You can use the microphone on your phone to respond to my questions, or simply write your answer in the box below. Are you ready? `,
      voice: [Recording7B],
    };
  };

  const {
    start,
    results: [text],
  } = useTypeWritterFlow({
    flowItems: [
      {
        text: TEXT().eng,
        audio: new Howl({
          src: TEXT().voice,
        }),
      },
    ],
    onEnd: () => {
      const element = document.getElementById("section-5");
      element.scrollIntoView({ behavior: "smooth" });
      setShowButtoReccord(true);
      setTimeout(() => {
        setShowText2(false);
      }, 5000);
    },
  });

  useEffect(() => {
    start()();
  }, []);

  const translationMutation = useMutation({
    mutationFn: (value) => getTranslation(value),
    onSuccess: (value) => {
      setShowMessagesTranslations((prev) => [...prev, value.name]);
      setTrnaslationMessages((prev) => [
        ...prev,
        { name: value.name, data: value.data },
      ]);
    },
  });

  const handleGetTranlationForBot = useCallback(
    (name, message) => {
      const translation = translationMessages.find((tm) => tm.name === name);

      if (translation) {
        if (showMessagesTranslations.includes(name)) {
          setShowMessagesTranslations((prev) => [
            ...prev.filter((name) => name !== name),
          ]);
        } else {
          setShowMessagesTranslations((prev) => [...prev, name]);
        }

        return;
      }

      const payload = {};
      payload.message = message;
      payload.name = name;

      translationMutation.mutate(payload);
    },
    [translationMessages, showMessagesTranslations]
  );

  const previousMessages = useMemo(
    () =>
      conversationWithChatGPT.map((element, i) =>
        element.role === TALKING_ROLES.BOT ? (
          <BotMessageTalking
            key={i}
            id={i}
            message={element.message}
            translationMessages={translationMessages}
            showMessagesTranslations={showMessagesTranslations}
            handleGetTranlationForBot={handleGetTranlationForBot}
          />
        ) : (
          <UserMessage message={element.message} key={i} />
        )
      ),
    [
      conversationWithChatGPT,
      translationMessages,
      handleGetTranlationForBot,
      showMessagesTranslations,
    ]
  );

  return (
    <>
      <BotMessage text={text} id="section-5" />
      {previousMessages}
      {current && <BotMessage text={current} showTranslateIcon={false} />}
      <TalkingMicrofon
        setCurrent={setCurrent}
        setConversationWithChatGPT={setConversationWithChatGPT}
        conversationWithChatGPT={conversationWithChatGPT}
      />
    </>
  );
};

export default TalkiMessage;
