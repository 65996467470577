import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomerEdit from "./components/CustomerEdit";
import AdminEdit from "./components/AdminEdit";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import { findUsers } from "@/api/query/user";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import Loading from "@/common/components/Loading";
import CustomInfoButton from "@/common/components/custom/buttons/CustomInfoButton";
import moment from "moment";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const HEADERS = [
  "No.",
  "Created At",
  "Email",
  "Role",
  "Aktywny",
  "Expired date",
  "Actions",
];

const HEADERS_CSV = [
  "No.",
  "Created At",
  "Email",
  "Role",
  "Aktywny",
  "Expired date",
];

const AdminUser = () => {
  const [editAdmin, setEditAdmin] = useState();
  const [editCustomer, setEditCustomer] = useState();
  const [tableData, setTableData] = useState();
  const [tableDataCSV, setTableDataCSV] = useState();

  const findUsersMutation = useMutation({
    mutationFn: () => findUsers(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data?.map((d, i) => [
          i + 1,
          <div>{moment(d?.created_at).format("DD/MM/YYYY")}</div>,
          d.email,
          d.__t,
          <div
            style={{
              color: d.active ? "green" : "red",
            }}
          >
            {d.active ? "tak" : "nie"}
          </div>,
          <div
            style={{
              color: d.active ? "green" : "red",
            }}
          >
            {d?.expired_date && moment(d?.expired_date).format("DD/MM/YYYY")}
          </div>,
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton
              onClick={(e) =>
                d.__t === "Admin" ? setEditAdmin(d) : setEditCustomer(d)
              }
            />
            {d.__t !== "Admin" && (
              <CustomInfoButton
                onClick={(e) => window.open(`/admin/customer/stats/${d._id}`)}
              />
            )}
          </div>,
        ])
      );

      setTableDataCSV(() =>
        data?.map((d, i) => [
          i + 1,
          moment(d?.created_at).format("DD/MM/YYYY"),
          d.email,
          d.__t,
          d.active ? "tak" : "nie",
          (d?.order?.expired_date &&
            moment(d?.order.expired_date).format("DD/MM/YYYY")) ||
            "-----",
        ])
      );
    },
  });

  useEffect(() => {
    if (!editAdmin && !editCustomer) {
      findUsersMutation.mutate();
    }
  }, [editAdmin, editCustomer]);

  return (
    <>
      {findUsersMutation.isLoading && <Loading />}
      <Wrapper>
        {editCustomer && (
          <CustomerEdit edit={editCustomer} setEdit={setEditCustomer} />
        )}
        {editAdmin && <AdminEdit edit={editAdmin} setEdit={setEditAdmin} />}
        <Title>User</Title>
        <TopContent>
          <CustomAddButton
            onClick={() => setEditCustomer({})}
            text="Add Customer"
          />
          <CustomAddButton onClick={() => setEditAdmin({})} text="Add Admin" />
        </TopContent>
        <CustomTable
          headers={HEADERS}
          data={tableData}
          csvHeaders={HEADERS_CSV}
          csvData={tableDataCSV}
        />
      </Wrapper>
    </>
  );
};

export default AdminUser;
