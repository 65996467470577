import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { BuyWrapper } from "./landing2.styles";
import HeadSection from "./headSection/HeadSection";
import MeetSaraiSection from "./meetSaraISection/MeetSaraiSection";
import ComparingSection from "./comparingSection/ComparingSection";
import PlanSection from "./planSection/PlanSection";
import Mockup from "./mockup/Mockup";
import WhatYouWillGetSection from "./whatYouWillGetSection/WhatYouWillGetSection";
import OpinionsSection from "./opinionsSection/OpinionsSection";
import { useScrollDirection } from "@/common/hooks/useScrollDirection";
import BottomElement from "./components/BottomElement";
import FAQSection from "./faq/FAQSection";
import { useMutation } from "react-query";
import { createLeadHistory } from "@/api/query/lead-history";
import useQueryMemo from "@/common/hooks/urlMemo";
import { useLocation } from "react-router-dom";
import Fotter from "./fotter/Fotter";
import HeroSectionDesktop from "./heroSectionDesktop/HeroSectionDesktop";
import InnovationDesktop from "./innovationDesktop/InnovationDesktop";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { EventService } from "@/common/services/eventService";
import { EventNamesEnum } from "@/common/enums/eventNamesEnum";
import { english } from "@/common/data/english";

const SCROLLS = {
  UP: "up",
  DOWN: "down",
};

const Landning2 = () => {
  const queryMemo = useQueryMemo();

  const createLeadMutation = useMutation({
    mutationFn: (value) => createLeadHistory(value),
    onSuccess: (data) => {
      localStorage.setItem("lead-history-id", data._id);
    },
  });

  useLayoutEffect(() => {
    const utm_campaign =
      queryMemo.get("utm_campaign") || localStorage.getItem("utm_campaign");
    const utm_medium =
      queryMemo.get("utm_medium") || localStorage.getItem("utm_medium");
    const utm_source =
      queryMemo.get("utm_source") || localStorage.getItem("utm_source");
    const utm_content =
      queryMemo.get("utm_content") || localStorage.getItem("utm_content");
    const utm_fbclid =
      queryMemo.get("utm_fbclid") || localStorage.getItem("utm_fbclid");
    const utm_term =
      queryMemo.get("utm_term") || localStorage.getItem("utm_term");

    const payload = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
    };

    if (queryMemo.get("utm_campaign")) {
      localStorage.setItem("utm_campaign", queryMemo.get("utm_campaign"));
    }
    if (queryMemo.get("utm_medium")) {
      localStorage.setItem("utm_medium", queryMemo.get("utm_medium"));
    }
    if (queryMemo.get("utm_source")) {
      localStorage.setItem("utm_source", queryMemo.get("utm_source"));
    }
    if (queryMemo.get("utm_content")) {
      localStorage.setItem("utm_content", queryMemo.get("utm_content"));
    }

    if (queryMemo.get("utm_fbclid")) {
      localStorage.setItem("utm_fbclid", queryMemo.get("utm_fbclid"));
    }

    window.gtag("set", { campaignName: utm_campaign });
    window.gtag("set", { campaignSource: utm_medium });
    window.gtag("set", { campaignMedium: utm_source });
    window.gtag("set", { campaignContent: utm_content });
    window.gtag("set", { campaignFbclid: utm_fbclid });

    createLeadMutation.mutate(payload);
    window.gtag("event", "page_landing");
  }, []);

  const { handleSendIPstats } = useContext(IpStatsContext);

  useEffect(() => {
    return EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
      handleSendIPstats(IP_STATS_ENUM.LANDING)
    );
  }, []);

  return (
    <BuyWrapper>
      <HeroSectionDesktop data={english.main.hero} />
      <InnovationDesktop data={english.main.innovation} />
      <HeadSection data={english.main.head} />
      <MeetSaraiSection data={english.main.meet} />
      <ComparingSection data={english.main.compare} />
      <PlanSection data={english.main.planSection} />
      <Mockup data={english.main.mockup} />
      <WhatYouWillGetSection data={english.main.what} />
      <OpinionsSection data={english.main.opinion} />
      <FAQSection data={english.main.faq} />
      <Fotter data={english.footer} />
    </BuyWrapper>
  );
};

export default Landning2;
