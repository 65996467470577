import React from "react";
import Robot from "@/common/images/robot.svg";
import Stars from "@/common/images/group-8.svg";
import StarsMobile from "@/common/images/gwiazdki-mobile.svg";

import {
  ContentWrapper,
  CustomImage,
  ListText,
  ListWrapper,
  SectionTitle,
  SectionTitleMobile,
  StarsImage,
  Wrapper,
} from "./meetSaraiSection.styles";

const TEXT = {
  MOBILE: `Innowacyjne podejście Sztucznej Inteligencji (AI)
  Mów płynnie po angielsku 
  Przyjemne podejście do nauki 
  `,
  DESKTOP: `Zacznij mówić płynnie z SarAI Odkryj przyjemne podejście
  do nauki języka angielskiego  Wykorzystaj możliwości
  Sztucznej Inteligencji (AI) na swoją korzyść`,
};

const MeetSaraiSection = () => {
  return (
    <Wrapper>
      <SectionTitle>
        Poznaj SarAI - Inteligentnego Asystenta w nauce angielskiego
      </SectionTitle>
      <ContentWrapper>
        <CustomImage src={Robot} alt="Robot" />
        <div>
          <SectionTitleMobile>Poznaj SarAI </SectionTitleMobile>
          <ListWrapper>
            <StarsImage
              src={window.innerWidth > 600 ? Stars : StarsMobile}
              alt="Stars"
            />
            <ListText>
              {window.innerWidth > 600 ? TEXT.DESKTOP : TEXT.MOBILE}
            </ListText>
          </ListWrapper>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default MeetSaraiSection;
