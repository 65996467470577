import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loading from "@/common/components/Loading";
import { getWordLessonTypeForCustomer } from "@/api/query/word-lesson-type";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 80px);
  overflow-y: auto;
  text-align: center;
  margin: 20px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - 160px);
    width: 100%;
    padding: 30px 30px 10px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  margin: 10px 0;
`;

const LessonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 150px;
  height: 150px;
  padding: 10px 5px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
  color: #0b2f45;
  gap: 4px;

  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  @media (min-width: 600px) {
    width: 200px;
    height: 200px;

    &:hover {
      transform: scale(0.8);
      cursor: pointer;
    }
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;

  @media (min-width: 600px) {
    width: 90%;
  }
`;

const WordsWrapper = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;

  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  max-width: 150px;

  @media (min-width: 350px) {
    max-width: 310px;
  }

  @media (min-width: 600px) {
    max-width: 430px;
    gap: 24px;
  }

  @media (min-width: 772px) {
    max-width: 650px;
  }

  @media (min-width: 1000px) {
    max-width: 900px;
  }
`;

const ImageWrapper = styled.div`
  width: 80px;
  overflow: hidden;

  @media (min-width: 600px) {
    width: 110px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const TitleLesson = styled.h3`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const WordLessonTypeList = () => {
  const navigate = useNavigate();
  const [wordsTypes, setWordsTypes] = useState();

  const handleLoadWordsListMutation = useMutation({
    mutationFn: () => getWordLessonTypeForCustomer(),
    onSuccess: ({ data }) => {
      setWordsTypes(data);
    },
  });

  useEffect(() => {
    handleLoadWordsListMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = "Sarai - słówka";
  }, []);

  return (
    <>
      {handleLoadWordsListMutation.isLoading && <Loading />}
      <Wrapper height={window.innerHeight}>
        <TitleLesson>Words</TitleLesson>
        <WordsWrapper>
          {wordsTypes?.map((l) => (
            <LessonWrapper
              onClick={() => navigate(`/word-lesson-type/${l._id}`)}
            >
              {l.name}{" "}
              <ImageWrapper>
                <img alt="" src={l?.file?.base64} />
              </ImageWrapper>
            </LessonWrapper>
          ))}
        </WordsWrapper>
      </Wrapper>
    </>
  );
};

export default WordLessonTypeList;
