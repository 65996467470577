import React, { useEffect, useState } from "react";
import { BuyWrapper } from "./buy.styles";
import {
  ComparingSection,
  FAQSection,
  HeadSection,
  MeetSaraiSection,
  OpinionsSection,
  PlanSection,
  WhatYouWillGetSection,
} from "./components";
import { wasOnBuyPage } from "@/api/query/lead-history";
import { useMutation } from "react-query";

const Buy = () => {
  const createLeadMutation = useMutation({
    mutationFn: (value) => wasOnBuyPage(value),
    onSuccess: () => {},
  });

  useEffect(() => {
    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");

    createLeadMutation.mutate(payload);
    window.gtag("event", "view_content");
  }, []);

  return (
    <BuyWrapper>
      <HeadSection />
      <MeetSaraiSection />
      <ComparingSection />
      <PlanSection />
      <WhatYouWillGetSection />
      <OpinionsSection />
      <FAQSection />
    </BuyWrapper>
  );
};

export default Buy;
