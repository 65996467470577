import { styled } from "styled-components";

export const CustomImage = styled.img`
  width: 25px;
  height: 25px;

  &:hover {
    cursor: pointer;
    /* transform: scale(1.05); */
  }
`;
