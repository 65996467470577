import Api from "../api";
const { axios: api } = Api;

const PREFIX = "ai-person";

export const getAiPersons = async (payload) => {
  const data = await api.get(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const createAiPerson = async (payload) => {
  const data = await api.post(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const updateAiPerson = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const changeUserTutor = async (payload) => {
  const data = await api.patch(`/${PREFIX}/profile-tutor`, payload);
  return { data: data.data };
};
