import {
  askChatGpt,
  transcript,
  transcriptWithOutUser,
} from "@/api/query/workflow";
import React, { useContext, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { TALKING_ROLES } from "./TalkiMessage";
import Animation1 from "@/common/images/animation-1.gif";
import { typeWriter } from "@/common/functions/typing";
import { Howl } from "howler";
import MessageBar from "@/common/components/chat/MessageBar";

const IconWrapper = styled.div`
  display: flex;
  align-self: center;
  position: absolute;
  width: 900px;
  margin-top: 440px;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 800px;
  }

  @media (max-width: 700px) {
    width: 600px;
  }

  @media (max-width: 600px) {
    width: 500px;
  }

  @media (max-width: 400px) {
    width: 300px;
  }
`;

const TalkingMicrofon = ({
  setCurrent,
  setConversationWithChatGPT,
  conversationWithChatGPT,
}) => {
  const { setShowLoading, setPageNumber } = useContext(DemoConversationContext);
  const presenceChecker = useRef();

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcriptWithOutUser(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;

      setConversationWithChatGPT((prev) => [
        ...prev,
        { role: TALKING_ROLES.USER, message: data },
      ]);

      const payload = {};
      payload.message = data;
      payload._id = localStorage.getItem("lead-history-id");

      getResponseForChatGptMutation.mutate(payload);
    },
  });

  const getResponseForChatGptMutation = useMutation({
    mutationFn: (value) => askChatGpt(value),
    onSuccess: async ({ data }) => {
      const blob = new Blob([new Uint8Array(data.speaking.data)], {
        type: "audio/mp3",
      });
      const url = await (function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      })(blob);

      const numberOfUserQuestion =
        (conversationWithChatGPT.filter((c) => c.role === TALKING_ROLES.BOT)
          ?.length || 0) + 1;

      window.gtag("event", `speak_question_${numberOfUserQuestion}`);

      const audio = new Howl({
        src: [url],
      });

      if (presenceChecker?.current && !document.hidden) {
        audio.play();
      }
      typeWriter({
        text: data.text,
        setText: setCurrent,
        typing: 45,
        onEnd: () => {
          if (conversationWithChatGPT.length > 4) {
            setShowLoading(true);
            setTimeout(() => {
              setPageNumber((prev) => 2);
            }, 3000);
          }
          setCurrent(null);
          setConversationWithChatGPT((prev, i) => [
            ...prev,
            { index: i, role: TALKING_ROLES.BOT, message: data.text },
          ]);
        },
      });
    },
  });

  const handleSendMessage = (message) => {
    const toSend = {};
    toSend._id = localStorage.getItem("lead-history-id");
    setConversationWithChatGPT((prev) => [
      ...prev,
      { role: TALKING_ROLES.USER, message: message },
    ]);

    const payload = {};
    payload.message = message;
    payload._id = localStorage.getItem("lead-history-id");

    getResponseForChatGptMutation.mutate(payload);
  };

  useEffect(() => {
    if (conversationWithChatGPT.length > 1) {
      const element = document.getElementById(
        `bot-message-${conversationWithChatGPT.length - 1}`
      );
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversationWithChatGPT]);

  return (
    <>
      {transcriptMutation.isLoading && (
        <div style={{ textAlign: "right" }}>
          <img src={Animation1} style={{ width: "40px" }} />
        </div>
      )}
      {getResponseForChatGptMutation.isLoading && (
        <div style={{ textAlign: "left" }}>
          <img src={Animation1} style={{ width: "40px" }} />
        </div>
      )}
      <div ref={presenceChecker} />
      <IconWrapper>
        <MessageBar
          isBlock={
            transcriptMutation.isLoading ||
            getResponseForChatGptMutation.isLoading
          }
          sendText={handleSendMessage}
          sendVoice={(data) => transcriptMutation.mutate(data)}
        />
      </IconWrapper>
    </>
  );
};

export default TalkingMicrofon;
