import {
  askChatGptFlashCard,
  askChatGptProductSpecial,
  transcript,
} from "@/api/query/workflow";
import MicRecorder from "mic-recorder-to-mp3";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { TALKING_ROLES } from "./TalkiMessage";
import Animation1 from "@/common/images/animation-1.gif";
import { typeWriter } from "@/common/functions/typing";
import { useLongPress } from "use-long-press";
import { Howl } from "howler";
import MicrofonIcon from "@/common/images/green-mirophone.png";
import TalkIcon from "@/common/images/TalkIcon.svg";
import AnimationRecording from "./AnimationRecording";
import IconCloseHints from "@/common/icons/icon-circle-chevron-down.svg";
import {
  HintsWrapper,
  IconWrapper,
  Input,
  FlexWrapper,
  MicrophoneIconWrapper,
  SendIconWrapper,
  WrapperHint,
  HintIcon,
  HintIconWrapper,
} from "./TalkingMicrofon.styles";
import { KEYS_CODES } from "@/common/constants/keyCodes";
import { FROM_SOURCE_TYPES } from "@/common/constants/types";

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const TalkingMicrofon = ({
  setCurrent,
  setConversationWithChatGPT,
  conversationWithChatGPT,
}) => {
  const [showHints, setShowHints] = useState(true);
  const [hints, setHints] = useState([]);
  const [speechLasts, setSpeechLasts] = useState();

  const { showLoading, dailyConversation } = useContext(
    DemoConversationContext
  );

  const inputMessage = useRef();
  const presenceChecker = useRef();

  const callback = React.useCallback(() => {
    !transcriptMutation.isLoading &&
      !getResponseForChatGptMutation.isLoading &&
      start();
  }, []);

  const bind = useLongPress(callback, {
    onStart: (event, meta) => {},
    onFinish: (event, meta) => {
      stop();
    },
    onTouchStart: () => {
      start();
    },
    onTouchEnd: () => {
      !transcriptMutation.isLoading &&
        !getResponseForChatGptMutation.isLoading &&
        stop();
    },
    threshold: 100,
  });

  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcript(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;

      setConversationWithChatGPT((prev) => [
        ...prev,
        { role: TALKING_ROLES.USER, message: data },
      ]);

      const payload = {};
      payload.message = data;
      payload._id = dailyConversation._id;
      payload.source = FROM_SOURCE_TYPES.SPEAK;

      getResponseForChatGptMutation.mutate(payload);
    },
  });

  const getResponseForChatGptMutation = useMutation({
    mutationFn: (value) => askChatGptFlashCard(value),
    onSuccess: async ({ data }) => {
      setHints(() => data.hints);

      const blob = new Blob([new Uint8Array(data.speaking.data)], {
        type: "audio/mp3",
      });
      const url = await (function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      })(blob);

      const numberOfUserQuestion =
        (conversationWithChatGPT.filter((c) => c.role === TALKING_ROLES.BOT)
          ?.length || 0) + 1;

      window.gtag("event", `speak_question_${numberOfUserQuestion}`);

      const audio = new Howl({
        src: [url],
        onend: () => {
          setSpeechLasts(() => false);
        },
        onunload: () => {
          setSpeechLasts(() => false);
        },
      });

      setSpeechLasts(() => true);

      if (presenceChecker?.current && !document.hidden) {
        audio.play();
      }

      typeWriter({
        text: data.text,
        setText: setCurrent,
        typing: 45,
        onEnd: () => {
          setCurrent(null);
          setSpeechLasts(() => false);
          setConversationWithChatGPT((prev, i) => [
            ...prev,
            { index: i, role: TALKING_ROLES.BOT, message: data.text },
          ]);
          inputMessage?.current?.focus();
        },
      });
    },
  });

  const handleSendMessage = (messageInput, hint = false) => {
    if (!inputMessage.current.value && !messageInput) return;

    setHints([]);

    const message = messageInput || inputMessage.current.value;

    setConversationWithChatGPT((prev) => [
      ...prev,
      { role: TALKING_ROLES.USER, message: message },
    ]);

    const payload = {};
    payload.message = message;
    payload._id = dailyConversation._id;
    payload.source = hint ? FROM_SOURCE_TYPES.HINT : FROM_SOURCE_TYPES.WRTIE;

    getResponseForChatGptMutation.mutate(payload);

    inputMessage.current.value = "";
  };

  const isResponding = useMemo(
    () =>
      speechLasts ||
      getResponseForChatGptMutation.isLoading ||
      transcriptMutation.isLoading,
    [
      getResponseForChatGptMutation.isLoading,
      speechLasts,
      transcriptMutation.isLoading,
    ]
  );

  useEffect(() => {
    if (conversationWithChatGPT.length > 3) {
      const element = document.getElementById(
        `bot-message-${conversationWithChatGPT.length - 1}`
      );
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversationWithChatGPT]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Permission Granted");
        setIsBlocked(() => false);
      })
      .catch((err) => {
        console.log("Permission Denied");
        console.log(err);
        setIsBlocked(() => true);
      });
  }, []);

  const start = () => {
    if (isBlocked) {
      alert("Please allow your microfon");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(() => true);
        })
        .catch((e) => alert("Please allow your microphone"));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File([blob], "to_translate.mp3", {
          type: "audio/mpeg",
          lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        formData.append("file", file);
        transcriptMutation.mutate(formData);

        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const handleHints = () => {
    if (showHints) {
      return setShowHints(false);
    }

    setShowHints(true);
  };

  const onEnterPress = (e) => {
    if (
      e.keyCode === KEYS_CODES.ENTER &&
      e.shiftKey === false &&
      window.innerWidth >= 600
    ) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (inputMessage.current) {
      inputMessage?.current?.focus();
    }
  }, [inputMessage.current]);

  return (
    <>
      {transcriptMutation.isLoading && (
        <div style={{ textAlign: "right" }}>
          <img alt="" src={Animation1} style={{ width: "40px" }} />
        </div>
      )}
      {getResponseForChatGptMutation.isLoading && (
        <div style={{ textAlign: "left" }}>
          <img alt="" src={Animation1} style={{ width: "40px" }} />
        </div>
      )}
      {isRecording && <AnimationRecording />}
      {showLoading && (
        <div
          style={{
            textAlign: "center",
            marginTop: "-40px",
            marginLeft: "-80px",
          }}
        >
          <img alt="" src={Animation1} style={{ width: "120px" }} />
        </div>
      )}
      <div ref={presenceChecker} />
      <IconWrapper>
        <FlexWrapper>
          {showHints && !isResponding && (
            <HintsWrapper>
              {hints?.map((h) => (
                <WrapperHint onClick={() => handleSendMessage(h, true)}>
                  {h}
                </WrapperHint>
              ))}
            </HintsWrapper>
          )}
          {!!hints?.length && !isResponding && (
            <HintIconWrapper isRotated={showHints}>
              <HintIcon
                alt=""
                src={IconCloseHints}
                onClick={() => handleHints()}
              />
            </HintIconWrapper>
          )}
        </FlexWrapper>
        <FlexWrapper>
          <Input
            onKeyDown={(e) => onEnterPress(e)}
            ref={inputMessage}
            type="text"
            placeholder="Write your message here!"
            disabled={isResponding}
          />
          <MicrophoneIconWrapper {...(!isResponding && bind())}>
            <img
              draggable={false}
              alt=""
              src={TalkIcon}
              style={{ width: "100%", height: "auto", userSelect: "none" }}
            />
          </MicrophoneIconWrapper>
          <SendIconWrapper>
            <img
              draggable={false}
              alt=""
              src={MicrofonIcon}
              style={{
                width: "100%",
                height: "auto",
                userSelect: "none",
              }}
              onClick={() => handleSendMessage()}
              disabled={transcriptMutation.isLoading}
              readonly="readonly"
            />
          </SendIconWrapper>
        </FlexWrapper>
      </IconWrapper>
    </>
  );
};

export default TalkingMicrofon;
