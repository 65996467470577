import {
  AgeMessage,
  HobbysMessage,
  LevelMessage,
  NameMessage,
  TalkiMessage,
  WelcomeMessage,
} from "../components";
import { CONVERSATION_VIEWS_ENUM } from "../demoScript.enums";

export const CONVERSATION_CONFIG = [
  {
    id: CONVERSATION_VIEWS_ENUM.WELCOME,
    component: <WelcomeMessage />,
  },
  {
    id: CONVERSATION_VIEWS_ENUM.NAME,
    component: <NameMessage />,
  },
  {
    id: CONVERSATION_VIEWS_ENUM.AGE,
    component: <AgeMessage />,
  },
  {
    id: CONVERSATION_VIEWS_ENUM.LEVEL,
    component: <LevelMessage />,
  },
  {
    id: CONVERSATION_VIEWS_ENUM.HOBBY,
    component: <HobbysMessage />,
  },
  {
    id: CONVERSATION_VIEWS_ENUM.TALKING,
    component: <TalkiMessage />,
  },
];
