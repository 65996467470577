import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import CustomTable from "@/common/components/custom/CustomTable";
import Input from "@/common/components/custom/Input";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import EditPrompt from "./EditTag";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import TextArea from "@/common/components/TextArea";
import { ImageInput, ImageWrapper } from "@/common/components/ImageContainer";
import BasicImage from "@/common/icons/image-icon.svg";
import { getBase64 } from "@/common/functions/getBase64";
import { createAiPerson, updateAiPerson } from "@/api/query/ai-person";
import SelectInput from "@/common/components/custom/SelectInput";
import { Howl } from "howler";

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const VOICE_OPTIONS = [
  {
    label: "ALLOY",
    value: "alloy",
  },
  {
    label: "ECHO",
    value: "echo",
  },
  {
    label: "FABLE",
    value: "fable",
  },
  {
    label: "ONYX",
    value: "onyx",
  },
  {
    label: "NOVA",
    value: "nova",
  },
  {
    label: "SHIMMER",
    value: "shimmer",
  },
];

const HEADERS = ["no.", "tag", "color", "Actions"];

const AdminDailyConversationEdit = ({ setEdit, edit }) => {
  const [showPrompt, setShowPrompt] = useState();
  const [tags, settags] = useState(edit.tags || []);
  const [image, setImage] = useState(edit.image?.base64);
  const [icon, setIcon] = useState(edit.icon?.base64);
  const [voiceOption, setVoiceOption] = useState(
    VOICE_OPTIONS.find((vo) => vo.value === edit.voiceType)
  );

  const deletePrompt = (promptElement) => {
    const filtred = tags
      .filter((p) => p.id !== promptElement.id)
      .map((p, i) => ({ ...p, id: i + 1 }));

    settags(() => filtred);
  };

  const tableData = useMemo(() => {
    if (!tags) {
      return [];
    } else {
      return tags.map((g) => [
        g.id,
        g.tag,
        g.color,
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomEditButton onClick={() => setShowPrompt(g)} />
          <CustomDeleteButton onClick={() => deletePrompt(g)} />
        </div>,
      ]);
    }
  }, [tags]);

  const nameRef = useRef();
  const promptRef = useRef();
  const voiceTextRef = useRef();
  const descriptionRef = useRef();
  const imageRef = useRef();
  const iconRef = useRef();

  const createWordLessonConfigMutation = useMutation({
    mutationFn: (value) => createAiPerson(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonConfigMutation = useMutation({
    mutationFn: (value) => updateAiPerson(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.prompt = promptRef.current.value;
    payload.description = descriptionRef.current.value;
    payload.voiceText = voiceTextRef.current.value;
    payload.voiceType = voiceOption.value;
    payload.icon = icon;
    payload.image = image;
    payload.tags = tags;

    if (edit._id) {
      return updateWordLessonConfigMutation.mutate(payload);
    }

    createWordLessonConfigMutation.mutate(payload);
  };

  const handleSelectFile = async () => {
    const file = imageRef.current.files[0];
    const base64 = await getBase64(file);
    setImage(base64);
  };

  const handleSelectFileIcon = async () => {
    const file = iconRef.current.files[0];
    const base64 = await getBase64(file);
    setIcon(base64);
  };

  const handlePlay = () => {
    const prefix = "data:audio/wav;base64,";
    const audio = new Audio(prefix + edit.voice.base64);
    audio.play();
  };

  return (
    <>
      {(updateWordLessonConfigMutation.isLoading ||
        createWordLessonConfigMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Person Ai {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={600}
            width={80}
            required
            requiredSign
            value={edit.name}
          />
          <TextArea
            textAreaRef={descriptionRef}
            label="Description"
            defaultValue={edit.description}
            required={true}
          />
          Image
          <ImageWrapper>
            <ImageInput
              id="file-input"
              type="file"
              name="cv"
              ref={imageRef}
              onChange={handleSelectFile}
            />
            <label htmlFor="file-input">
              <img
                alt=""
                src={image || BasicImage}
                style={{ width: "100%", height: "auto" }}
              />
            </label>
          </ImageWrapper>
          Icon
          <ImageWrapper>
            <ImageInput
              id="file-input-2"
              type="file"
              name="cv-2"
              ref={iconRef}
              onChange={handleSelectFileIcon}
            />
            <label htmlFor="file-input-2">
              <img
                alt=""
                src={icon || BasicImage}
                style={{ width: "100%", height: "auto" }}
              />
            </label>
          </ImageWrapper>
          <TextArea
            textAreaRef={promptRef}
            label="Prompt"
            defaultValue={edit.prompt}
            required={true}
          />
          <TextArea
            textAreaRef={voiceTextRef}
            label="Voice text"
            defaultValue={edit.voiceText}
            required={true}
          />
          <SelectInput
            options={VOICE_OPTIONS}
            name={"Speaker"}
            width={60}
            setSelected={setVoiceOption}
            selected={voiceOption}
          />
          {edit?.voice?.base64 && (
            <div>
              <CustomAddButton text="Play voice" onClick={() => handlePlay()} />
            </div>
          )}
          <TableWrapper>
            <CustomTable headers={HEADERS} data={tableData} />
          </TableWrapper>
          <SaveWrapper>
            <CustomAddButton onClick={() => setShowPrompt({})} />
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
      {showPrompt && (
        <EditPrompt
          setClose={setShowPrompt}
          edit={showPrompt}
          handleAddPrompt={settags}
        />
      )}
    </>
  );
};

export default AdminDailyConversationEdit;
