import { styled } from "styled-components";

export const OpinionImage = styled.img`
  margin-left: -10px;
`;

export const Wrapper = styled.section`
  margin-top: 70px;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 0;
`;

export const OpinionElement = styled.div`
  display: flex;
  gap: 30px;
  padding: 25px 35px;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  border-radius: 16px;
  background: #fff;

  @media (max-width: 600px) {
    max-width: 300px;
    width: 300px;
    margin: 10px auto;
  }
`;

export const OpinionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const OpinionsWrapperMobile = styled(OpinionsWrapper)`
  margin-top: 30px;
  display: none;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const Text = styled.p`
  width: 258px;
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  margin: 0;
`;

export const PersonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Name = styled.p`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
`;
