import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useRef, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import { findCategories } from "@/api/query/category";
import SearchBar from "./components/SearchBar";
import Loading from "@/common/components/Loading";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { getIpStatsByDates } from "@/api/query/ipStats";
import { getQualityIndicatorReport } from "@/api/query/report";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const HEADERS = [
  "Customers",
  "40 inter",
  "20 inter",
  "Renew",
  "Package end",
  "Avg inter",
  "q (50%) inter",
  "q (80%) inter",
  "q (90%) inter",
  "q (95%) inter",
  "Avg days",
  "q (50%) days",
  "q (80%) days",
  "q (90%) days",
  "q (95%) days",
];

const AdminQualityIndicator = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const findStatsMutation = useMutation({
    mutationFn: (values) => getQualityIndicatorReport(values),
    onSuccess: ({ data }) => {
      setTableData(() => [
        [
          data.customers,
          data.secondThreshold,
          data.firstThreshold,
          data.renew,
          data.end,
          data.avgInteractions,
          ...data.medianInteractions.map((d) => Math.round(d * 100) / 100),
          data.avgDays,
          ...data.medianDays.map((d) => Math.round(d * 100) / 100),
        ],
      ]);
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <Title>Quality Indicator</Title>
        <SearchBar handleSearch={handleSearch} />
        <CustomTable headers={HEADERS} data={tableData} csvData={tableData} />
      </Wrapper>
    </>
  );
};

export default AdminQualityIndicator;
