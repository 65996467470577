import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Hands from "./customer/Hands";
import { Link } from "react-router-dom";

const TitleLessonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 98%;
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const TitleLessonWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  padding: 5px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 260px;
  gap: 5px;
  color: #868686;
  text-decoration: none;

  @media (max-width: 600px) {
    min-width: 10px;
    margin-left: 20px;
    margin-right: -20px;
    color: rgba(5, 39, 93, 1);
  }
`;

const TextBack = styled.p`
  margin: 0;
  text-decoration: none;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;

  @media (max-width: 600px) {
    display: none;
  }
`;
const Text = styled.h3`
  color: #3280fc;

  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;
  margin: 0;

  @media (max-width: 600px) {
    color: #05275d;
  }
`;

const EmptyContainer = styled.div`
  width: 260px;

  @media (max-width: 600px) {
    width: 10px;
  }
`;

const TopTitleHeader = ({
  title = "",
  source = "",
  forWhat = "",
  navigate = "",
  showHands = false,
}) => {
  return (
    <>
      <TitleLessonWrapper>
        <BackWrapper to={navigate}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "12px" }} />
          <TextBack>Back</TextBack>
        </BackWrapper>
        <Text>{title}</Text>
        {showHands ? (
          <Hands type={source} forWhat={forWhat} />
        ) : (
          <EmptyContainer />
        )}
      </TitleLessonWrapper>
      <TitleLessonWrapperMobile>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "-10px",
          }}
        >
          <BackWrapper to={navigate}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackWrapper>
          <Text>{title}</Text>
          <div style={{ width: "10px" }}></div>
        </div>
        {showHands ? (
          <Hands type={source} forWhat={forWhat} />
        ) : (
          <EmptyContainer />
        )}
      </TitleLessonWrapperMobile>
    </>
  );
};

export default TopTitleHeader;
