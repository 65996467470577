import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import user from "@/common/icons/user-admin.svg";
import lessons from "@/common/icons/lessons-admin.svg";
import category from "@/common/icons/category-admin.svg";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  gap: 20px;
`;

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
  color: #05275d;
  font-family: Work Sans;
  gap: 8px;
  padding: 32px;
  border-radius: 16px;
  background: rgba(79, 167, 180, 0.2);
  transition: all 0.3s;

  &:hover {
    transform: scale(0.8);
  }
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdminElements = [
  {
    name: "Kategorie scenek",
    link: "/admin/category",
    icon: category,
  },
  {
    name: "Lekcje scenki",
    link: "/admin/lesson",
    icon: lessons,
  },
  {
    name: "User",
    link: "/admin/user",
    icon: user,
  },
  {
    name: "Lekcje słówka",
    link: "/admin/word-lesson-type",
    icon: category,
  },
  {
    name: "Hobby",
    link: "/admin/word-lesson-top-type",
    icon: category,
  },
  {
    name: "Config scenek słówka",
    link: "/admin/word-lesson-type-config",
    icon: category,
  },
  {
    name: "Wysłane maile",
    link: "/admin/mail",
    icon: category,
  },
  {
    name: "Ordery",
    link: "/admin/order",
    icon: category,
  },
  {
    name: "Panel",
    link: "/admin/panel",
    icon: category,
  },
  {
    name: "Hands",
    link: "/admin/hands",
    icon: category,
  },
  {
    name: "Flashcard",
    link: "/admin/flashcard",
    icon: category,
  },
  {
    name: "Daily history",
    link: "/admin/daily-history",
    icon: category,
  },
  {
    name: "Image Mode",
    link: "/admin/image-mode",
    icon: category,
  },
  {
    name: "Ip Stats",
    link: "/admin/ip-stats",
    icon: category,
  },
  {
    name: "Usage Stats",
    link: "/admin/usage-stats",
    icon: category,
  },
  {
    name: "Image mode Stats",
    link: "/admin/image-mode-stats",
    icon: category,
  },
  {
    name: "Flashcard categories",
    link: "/admin/flashcard-categories",
    icon: category,
  },
  {
    name: "AI Persons",
    link: "/admin/ai-persons",
    icon: category,
  },
  {
    name: "Daily config",
    link: "/admin/daily-config",
    icon: category,
  },
  {
    name: "Languages",
    link: "/admin/language",
    icon: category,
  },
  {
    name: "Customer profiles",
    link: "/admin/customer-profile",
    icon: category,
  },
  {
    name: "Quality indicator",
    link: "/admin/quality-indicator",
    icon: category,
  },
];

const AdminHome = () => {
  return (
    <Wrapper>
      {AdminElements.map((element) => (
        <CustomLink to={element.link}>
          <IconWrapper>
            <img
              style={{ width: "100%", height: "auto" }}
              alt=""
              src={element.icon}
            />
          </IconWrapper>
          {element.name}
        </CustomLink>
      ))}
    </Wrapper>
  );
};

export default AdminHome;
