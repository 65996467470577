import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "word-lesson-type";

export const findWordLesson = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload}`);
  return { data: data.data };
};

export const getWordLessonTypeForCustomer = async (payload) => {
  const data = await api.get(`/${PREFIX}/all`, payload);
  return { data: data.data };
};

export const createNewWordLessonType = async (payload) => {
  const data = await api.post(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const updateNewWordLessonType = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};
