import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import FlashcardEdit from "./components/FlashCardEdit";
import moment from "moment";
import { getImageModes } from "@/api/query/image-mode";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const HEADERS = ["No.", "Created At", "Description", "Image"];

const AdminImageMode = () => {
  const [editFlashcard, setEditFlashcard] = useState();
  const [tableData, setTableData] = useState();

  const findFlashCardsMutation = useMutation({
    mutationFn: () => getImageModes(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((d, i) => [
            `${i + 1}.`,
            moment(d.created_at).format("DD/MM/YYYY HH:mm"),
            <div>{d.description}</div>,
            <div
              style={{
                width: "150px",
              }}
            >
              <img
                src={d.image.base64}
                style={{ width: "100%", height: "auto" }}
              />
            </div>,
          ])
      );
    },
  });

  useEffect(() => {
    if (!editFlashcard) {
      findFlashCardsMutation.mutate();
    }
  }, [editFlashcard]);

  return (
    <>
      {findFlashCardsMutation.isLoading && <Loading />}
      <Wrapper>
        {editFlashcard && (
          <FlashcardEdit edit={editFlashcard} setEdit={setEditFlashcard} />
        )}
        <Title>Image Mode</Title>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminImageMode;
