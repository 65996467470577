import { findCategoryById } from "@/api/query/category";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import FilledStar from "@/common/icons/stars/filled-star.svg";
import BlankStar from "@/common/icons/stars/blank-star.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PopUp from "@/common/components/PopUp";
import { Color } from "@/common/colors/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
  text-align: center;
  margin: 20px 20px 0 20px;
  gap: 10px;

  @media (min-width: 600px) {
    margin: 0;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    height: calc(var(--vh, 1vh) * 100 - 160px);
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  margin: 10px 0;
`;

const LessonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  width: 85vw;
  padding: 10px 20px 10px 20px;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);

  color: #0b2f45;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  @media (min-width: 600px) {
    width: 85%;
  }
`;

const StarWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;

  @media (min-width: 600px) {
    width: 90%;
  }
`;

const StartPopUpTitle = styled.h4`
  color: #05275d;

  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
`;

const PopUpStartWrapper = styled.div`
  max-width: 300px;
  text-align: center;

  p {
    color: #05275d;

    text-align: center;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
`;

const ConfirmationButton = styled.button`
  display: inline-flex;
  padding: 17px 56px 14px 56px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #3280fc;
  border: none;
  color: white;
`;

const CategoryOptions = () => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState();
  const { id } = useParams();
  const [category, setCategory] = useState({});

  const handleLoadCategoryLessons = useMutation({
    mutationFn: (value) => findCategoryById(value),
    onSuccess: ({ data }) => {
      setCategory(data);
      console.log(data);
    },
  });

  useEffect(() => {
    if (id) {
      handleLoadCategoryLessons.mutate(id);
    }
  }, [id]);

  useEffect(() => {
    window.document.title = "Sarai - categorie scenki";
  }, []);

  return (
    <>
      <Wrapper>
        <TopWrapper>
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ fontSize: "28px", color: Color.Dark }}
            onClick={() => navigate("/learn/categories")}
          />
          <Title>{category?.name}</Title>
          <div></div>
        </TopWrapper>
        {category?.lessons?.map((l) => (
          <LessonWrapper onClick={() => setShowConfirmation(l)}>
            {l.name}{" "}
            <StarWrapper>
              <img src={l?.progres?.starOne ? FilledStar : BlankStar} />
              <img src={l?.progres?.starTwo ? FilledStar : BlankStar} />
              <img src={l?.progres?.starThree ? FilledStar : BlankStar} />
            </StarWrapper>
          </LessonWrapper>
        ))}
      </Wrapper>
      {showConfirmation && (
        <PopUp closeIcon={false} setClose={setShowConfirmation}>
          <PopUpStartWrapper>
            <StartPopUpTitle>Collect stars!</StartPopUpTitle>
            <p>{showConfirmation.description}</p>
            <ConfirmationButton
              onClick={() => navigate(`/lesson/${showConfirmation._id}`)}
            >
              Start
            </ConfirmationButton>
          </PopUpStartWrapper>
        </PopUp>
      )}
    </>
  );
};

export default CategoryOptions;
