import api from "@/api/api";
import userManager from "@/api/userManager";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";
import React from "react";
import { Navigate } from "react-router-dom";
import { styled } from "styled-components";

const Wrapper = styled.div`
  padding: 30px;
  overflow: hidden;
`;

const { tokenManager } = api;

const NewAdminLayout = ({ children }) => {
  const user = userManager?.getUser();

  if (!user || "Admin" !== user.role) {
    userManager.removeUser();
    tokenManager.removeToken();

    return <Navigate to={getNavigationPath("/login")} replace />;
  }

  return <Wrapper>{children}</Wrapper>;
};

export default NewAdminLayout;
