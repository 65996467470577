import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "flashcard-categories";

export const createFlashcardCategory = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const createFlashcardCategoryByCustomer = async (payload) => {
  const data = await api.post(`/${PREFIX}/customer`, payload);
  return { data: data.data };
};

export const generateWordsFlashCardTopic = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-words/${payload._id}`);
  return { data: data.data };
};

export const saveWordsFashcard = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/generate-words-algo/saveWords`,
    payload
  );
  return { data: data.data };
};

export const updateFlashcardCategory = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const findFlashcardCategories = async () => {
  const data = await api.get(`/${PREFIX}/`);
  return { data: data.data };
};

export const getWordLessonTopTypeByHobby = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload._id}`);
  return { data: data.data };
};

export const deleteFlashcardCategory = async (payload) => {
  const data = await api.delete(`/${PREFIX}/${payload._id}`);
  return { data: data.data };
};
