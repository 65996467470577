import React, { useContext, useRef, useState } from "react";
import {
  AditionalInfo,
  Card,
  EyeIconWrapper,
  Input,
  InputWrapper,
  InputsWrapper,
  Label,
  RobotWrapper,
  SubmitButton,
  Title,
} from "../register.styles";
import { useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { RegisterContext } from "../context/register.context";
import Loading from "@/common/components/Loading";
import { handleRegisterCustomer } from "@/api/query/customer";
import styled from "styled-components";
import ErrorImage from "../icons/exclamation-button.svg";
import useQueryMemo from "@/common/hooks/urlMemo";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import RegisterImage from "../images/rejestracja.png";
import { english } from "@/common/data/english";

const ErrorWrapper = styled.div`
  color: #fc0341;

  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  margin-top: -10px;
  min-width: 320px;
  text-align: left;

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const UserDataView = () => {
  const queryMemo = useQueryMemo();
  const [labelType, setLabelType] = useState("password");
  const [labelTypeCheck, setLabelTypeCheck] = useState("password");

  const { setView, setUser } = useContext(RegisterContext);
  const [error, setError] = useState();
  const [errorPassword, setErrorPasswordr] = useState();

  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const { handleSendIPstats } = useContext(IpStatsContext);

  const handleRegisterCustomerMutation = useMutation({
    mutationFn: (value) => handleRegisterCustomer(value),
    onSuccess: ({ data }) => {
      localStorage.setItem("user_register", JSON.stringify(data));
      setUser(data);
      handleSendIPstats(IP_STATS_ENUM.ACCOUNT_CREATED, data?._id);
      setView(1);
    },
    onError: () => {
      setError(english.register.errorRegister);
    },
  });

  const checkPassword = (pass) => {
    if (pass.length <= 8)
      return {
        valid: false,
        message: english.register.passwordError1,
      };

    if (!/[A-Z].*/.test(pass))
      return {
        valid: false,
        message: english.register.passwordError2,
      };

    if (!/[a-z].*/.test(pass))
      return {
        valid: false,
        message: english.register.passwordError3,
      };

    if (!/\d/.test(pass))
      return {
        valid: false,
        message: english.register.passwordError4,
      };

    return { valid: true, message: "Valid Password" };
  };

  const handleLogin = (e) => {
    e && e.preventDefault();

    const password = passwordRef.current.value;
    const checkedPassword = checkPassword(password);

    if (!checkedPassword.valid) {
      setErrorPasswordr(checkedPassword.message);

      setTimeout(() => {
        setErrorPasswordr(null);
      }, 3000);
      return;
    }

    const utm_campaign =
      queryMemo.get("utm_campaign") || localStorage.getItem("utm_campaign");
    const utm_medium =
      queryMemo.get("utm_medium") || localStorage.getItem("utm_medium");
    const utm_source =
      queryMemo.get("utm_source") || localStorage.getItem("utm_source");
    const utm_content =
      queryMemo.get("utm_content") || localStorage.getItem("utm_content");
    const utm_fbclid =
      queryMemo.get("utm_fbclid") || localStorage.getItem("utm_fbclid");
    const utm_term =
      queryMemo.get("utm_term") || localStorage.getItem("utm_term");

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
    };

    const name = nameRef.current.value;

    const payload = {};
    payload.email = emailRef.current.value;
    payload.password = passwordRef.current.value;
    payload.name = name?.split(" ")[0];
    payload.surname = name?.split(" ")[1];
    payload.utm = utm;

    window?.fbq("track", "CompleteRegistration");
    window?.uetq?.push("event", "subscribe", {});

    handleRegisterCustomerMutation.mutate(payload);
  };

  const handleChangeLabel = () => {
    if (labelType === "password") {
      return setLabelType(() => "text");
    }
    setLabelType(() => "password");
  };

  const handleChangeLabelCheck = () => {
    if (labelTypeCheck === "password") {
      return setLabelTypeCheck(() => "text");
    }
    setLabelTypeCheck(() => "password");
  };

  return (
    <>
      {handleRegisterCustomerMutation.isLoading && <Loading />}
      <Card onSubmit={handleLogin}>
        <ImageWrapper>
          <img src={RegisterImage} alt="Sarai register" />
        </ImageWrapper>
        <InputsWrapper>
          <Title>{english.register.register}</Title>
          <InputWrapper>
            <Label>{english.register.nameAndSurname}</Label>
            <Input
              ref={nameRef}
              id="name-sarai"
              required
              placeholder="ex. Adam Smith"
            ></Input>
          </InputWrapper>
          <InputWrapper>
            <Label>{english.register.email}</Label>
            <Input
              ref={emailRef}
              id="email-sarai"
              type="email"
              required
              placeholder="ex. example@saraiapp.com"
            ></Input>
          </InputWrapper>
          <InputWrapper>
            <Label>Hasło</Label>
            <Input
              ref={passwordRef}
              id="pawssword-sarai"
              type={labelType}
              required
            ></Input>
            <EyeIconWrapper onClick={handleChangeLabel}>
              <FontAwesomeIcon icon={faEye} tabIndex="none" />
            </EyeIconWrapper>
          </InputWrapper>
          <AditionalInfo style={{ color: errorPassword ? "red" : "#868686" }}>
            {!errorPassword ? (
              <>{english.register.passwordInfo}</>
            ) : (
              errorPassword
            )}
          </AditionalInfo>
          {error && (
            <ErrorWrapper>
              <img src={ErrorImage} /> {error}
            </ErrorWrapper>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <SubmitButton type="submit">
              {english.register.createAccount}
            </SubmitButton>
          </div>
        </InputsWrapper>
        <RobotWrapper />
      </Card>
    </>
  );
};

export default UserDataView;
