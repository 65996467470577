import Api from "../api";

const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "payu";

export const createOrderWithPayu = async (payload) => {
  const data = await api.post(`/${PREFIX}/create-order`, payload);

  return { data: data.data };
};

export const createCustomerOneTimeOrder = async (payload) => {
  const data = await api.post(`/${PREFIX}/one-time-payment`, payload);
  return { data: data.data };
};
