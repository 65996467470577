export const {
  default: ComparingSection,
} = require("./comparingSection/ComparingSection");
export const { default: FAQSection } = require("./faq/FAQSection");
export const { default: HeadSection } = require("./headSection/HeadSection");
export const {
  default: MeetSaraiSection,
} = require("./meetSaraISection/MeetSaraiSection");
export const {
  default: OpinionsSection,
} = require("./opinionsSection/OpinionsSection");
export const { default: PlanSection } = require("./planSection/PlanSection");
export const {
  default: WhatYouWillGetSection,
} = require("./whatYouWillGetSection/WhatYouWillGetSection");
