export const Color = {
  PrimaryBrand: "#ED4B9E",
  SecondaryBrand: "#4B4DED",
  TertiaryBrand: "#F3D9DA",
  Dark: "#0E0E2C",
  Success: "#ED4B9E",
  Text: "#4A4A68",
  SubtleText: "#8C8CA1",
  Accent: "#ECF1F4",
  Light: "#FAFCFE",
  Gray: "#d9d9d9",
  DarkGray: "#868686",
  LightGray: "#EBECED",
  Blue: "#3280FC",
};
