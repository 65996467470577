import React, { useContext, useEffect, useRef } from "react";
import Computer from "./images/computer.png";
import styled from "styled-components";
import Video from "./video/sarai-demo.mp4";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  overflow: hidden;

  @media (max-width: 600px) {
    display: none;
  }

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  max-width: 1000px;
  overflow: hidden;
  z-index: 0;

  @media (max-width: 1000px) {
    display: none;
  }

  video {
    clip-path: inset(1px 1px) !important;
    width: 100%;
    height: auto;
  }

  video:focus {
    outline: none;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export const Title = styled.h1`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin: 0;
  text-align: center;
  width: 550px;
`;

export const Subtitle = styled.h3`
  color: #0b2f45;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 100px;
`;

export const StartButton = styled.a`
  display: inline-flex;
  padding: 12px 80px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(266deg, #05d698 -13.87%, #3db2dc 91.82%);
  box-shadow: 4px 4px 13px 0px #76d4e1;
  border: none;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  text-decoration: none;
  width: 250px;

  &:hover {
    transform: scale(1.05);
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin-bottom: 100px;
  margin-left: -200px;
  z-index: 1;

  @media (max-width: 1000px) {
    margin: 0;
  }
`;

const HeroSectionDesktop = ({ data }) => {
  const { handleSendIPstats } = useContext(IpStatsContext);

  return (
    <Wrapper>
      <ImageWrapper>
        <video src={Video} autoPlay loop muted></video>
      </ImageWrapper>
      <RightWrapper>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
        <StartButton
          href="#plan-offer"
          onClick={() => {
            handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_MIDDLE);
            window.gtag("event", "clicked_buy_now_hero");
          }}
        >
          {data.buyNow}
        </StartButton>
      </RightWrapper>
    </Wrapper>
  );
};

export default HeroSectionDesktop;
