import React, { useEffect, useLayoutEffect, useState } from "react";
import { WorkflowWrapper } from "./styles/lannding.styles";
import { WORKFLOW_CONFIG } from "./landing.constants";
import useQueryMemo from "@/common/hooks/urlMemo";
import { createLeadHistory } from "@/api/query/lead-history";
import { useMutation } from "react-query";

const LandingPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const queryMemo = useQueryMemo();

  const createLeadMutation = useMutation({
    mutationFn: (value) => createLeadHistory(value),
    onSuccess: (data) => {
      localStorage.setItem("lead-history-id", data._id);
    },
  });

  useLayoutEffect(() => {
    const payload = {
      utm_campaign: queryMemo.get("utm_campaign"),
      utm_medium: queryMemo.get("utm_medium"),
      utm_source: queryMemo.get("utm_source"),
      utm_content: queryMemo.get("utm_content"),
      utm_fbclid: queryMemo.get("fbclid"),
      utm_term: queryMemo.get("utm_term"),
    };

    window.gtag("set", { campaignName: queryMemo.get("utm_campaign") });
    window.gtag("set", { campaignSource: queryMemo.get("utm_medium") });
    window.gtag("set", { campaignMedium: queryMemo.get("utm_source") });
    window.gtag("set", { campaignContent: queryMemo.get("utm_content") });
    createLeadMutation.mutate(payload);
    window.gtag("event", "page_landing");
  }, []);

  return (
    <>
      <WorkflowWrapper>
        {WORKFLOW_CONFIG.find((info) => info.id === pageNumber)?.component(
          setPageNumber
        )}
      </WorkflowWrapper>
    </>
  );
};

export default LandingPage;
