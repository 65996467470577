import {
  createWordLessonTopType,
  updateWordLessonTopType,
} from "@/api/query/word-lesson-top-type";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import UserProile from "@/common/icons/user-profile.svg";
import { getBase64 } from "@/common/functions/getBase64";

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
`;

export const ImageInput = styled.input`
  display: none;
`;

const AdminWordLessonTopTypeEdit = ({ setEdit, edit }) => {
  const nameRef = useRef();
  const fileRef = useRef();
  const bigFileRef = useRef();

  const [currentBase64, setCurrentBase64] = useState(
    edit.file?.base64 || UserProile
  );
  const [currentBigBase64, setCurrentBigBase64] = useState(
    edit.bigFile?.base64 || UserProile
  );

  const createWordLessonTopTypeMutation = useMutation({
    mutationFn: (value) => createWordLessonTopType(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonTopTypeMutation = useMutation({
    mutationFn: (value) => updateWordLessonTopType(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = async (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.base64 = currentBase64;
    payload.bigBase64 = currentBigBase64;

    if (edit._id) {
      return updateWordLessonTopTypeMutation.mutate(payload);
    }

    createWordLessonTopTypeMutation.mutate(payload);
  };

  const handleSelectFile = async () => {
    const file = fileRef.current.files[0];
    const base64 = await getBase64(file);

    setCurrentBase64(base64);
  };

  const handleSelectBigFile = async () => {
    const file = bigFileRef.current.files[0];
    const base64 = await getBase64(file);
    setCurrentBigBase64(base64);
  };

  return (
    <>
      {(createWordLessonTopTypeMutation.isLoading ||
        updateWordLessonTopTypeMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Wrod lesson top type {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)} novalidate>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={200}
            width={80}
            required
            requiredSign
            value={edit.name}
          />
          <div>Small Image</div>
          <ImageWrapper>
            <ImageInput
              id="file-input"
              type="file"
              ref={fileRef}
              onChange={handleSelectFile}
            />
            <label htmlFor="file-input">
              <img
                alt=""
                src={currentBase64}
                style={{ width: "100%", height: "auto" }}
              />
            </label>
          </ImageWrapper>
          <div>Big Image</div>
          <ImageWrapper>
            <ImageInput
              id="file-input-2"
              type="file"
              ref={bigFileRef}
              onChange={handleSelectBigFile}
            />
            <label htmlFor="file-input-2">
              <img
                alt=""
                src={currentBigBase64}
                style={{ width: "100%", height: "auto" }}
              />
            </label>
          </ImageWrapper>
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminWordLessonTopTypeEdit;
