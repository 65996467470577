import { createAdmin, updateAdmin } from "@/api/query/admin";
import { updateFlashcard } from "@/api/query/flashcard";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { SubmitButton } from "@/pages/login/login.styles";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const FlashcardEdit = ({ edit, setEdit }) => {
  const nameRef = useRef();
  const translationRef = useRef();
  const sentenceRef = useRef();
  const translatedSentenceRef = useRef();

  const updateAdminMutation = useMutation({
    mutationFn: (value) => updateFlashcard(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.word = nameRef.current.value;
    payload.translation = translationRef.current.value;
    payload.sentence = sentenceRef.current.value;
    payload.translatedSentence = translatedSentenceRef.current.value;

    updateAdminMutation.mutate(payload);
  };

  return (
    <>
      {updateAdminMutation.isLoading && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>{edit._id ? "Edit" : "Create"} FlashCard</Title>
        <Wrapper onSubmit={(e) => handleSave(e)} autoComplete="new-password">
          <Input
            inputRef={nameRef}
            name="Word"
            width={200}
            required
            value={edit.name}
            inputWidth={380}
          />
          <Input
            inputRef={translationRef}
            name="Translation"
            width={200}
            required
            value={edit.translation}
            inputWidth={380}
          />
          <Input
            inputRef={sentenceRef}
            name="Sentence"
            width={200}
            required
            value={edit.sentence}
            inputWidth={380}
          />
          <Input
            inputRef={translatedSentenceRef}
            name="Sentence translated"
            width={200}
            value={edit.translatedSentence}
            inputWidth={380}
            required
          />
          <ButtonWrapper>
            <CustomSaveButton />
          </ButtonWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default FlashcardEdit;
