import Sylwia from "@/common/images/sylwia.svg";
import Pawel from "@/common/images/pawel.svg";
import Malgorzata from "@/common/images/malgorzata.svg";

export const OPINION_CONFIG = [
  {
    text: "SarAI to świetna aplikacja do poszerzania słownictwa. Rozmowy są naprawdę naturalne i przyjemne, można w wolnej chwili wejść do aplikacji i pogadać po angielsku.",
    image: Sylwia,
    name: "Sylwia",
  },
  {
    text: "Dla introwertyka, który stresuje się nawet telefonem do nauczyciela, żeby umówić się na lekcję… SarAI to strzał w 10 :) Zaczynałem od dukania po angielsku, a dzięki systematycznym powtórkom szybko zyskuję pewność siebie w mówieniu po angielsku.",
    image: Pawel,
    name: "Paweł",
  },
  {
    text: "Świetna alternatywa dla konwersacji z native speakerami. Za cenę jednej lekcji mogę rozmawiać do woli przez miesiąc.",
    image: Malgorzata,
    name: "Małgorzata",
  },
];
