import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import Loading from "@/common/components/Loading";
import { getAiPersons } from "@/api/query/ai-person";
import AdminCustomerProfileEdit from "./components/AdminCustomerProfileEdit";
import { getCustomerProfiles } from "@/api/query/customer-profile";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 200px);
`;

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const HEADERS = ["No.", "Name", "Icon", "Actions"];

const AdminCustomerProfile = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();

  const findWordLessonsConfigMutation = useMutation({
    mutationFn: () => getCustomerProfiles(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => [
          i + 1,
          d.name,
          <img src={d.icon?.base64} style={{ width: "60px" }} />,
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton onClick={(e) => setEdit(d)} />
          </div>,
        ])
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findWordLessonsConfigMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {findWordLessonsConfigMutation.isLoading && <Loading />}
      <Wrapper>
        {edit && <AdminCustomerProfileEdit edit={edit} setEdit={setEdit} />}
        <Title>Customer Profile</Title>
        <TopContent>
          <CustomAddButton onClick={() => setEdit({})} />
        </TopContent>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminCustomerProfile;
