import React from "react";
import { CSVLink } from "react-csv";
import { Color } from "../colors/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

const CSV = ({
  header,
  data = [[]],
  fontSize = 25,
  filename,
  color = Color.Text,
}) => {
  return (
    <CSVLink filename={filename} data={[header, ...data]}>
      <FontAwesomeIcon
        icon={faFileCsv}
        style={{
          fontSize: `${fontSize}px`,
          cursor: "pointer",
          color: color,
        }}
      />
    </CSVLink>
  );
};

export default CSV;
