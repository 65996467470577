import { styled } from "styled-components";
import { SectionWrapper } from "../../styles/wrappers";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 20px;

  @media (max-width: 600px) {
    gap: 10px;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: 470px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 76);
  }
`;

export const Logo = styled.img`
  width: 69px;
  height: 75px;

  @media (max-width: 600px) {
    width: 30px;
    height: 32px;
  }
`;

export const Height = styled.div`
  min-height: 1000px;
`;

export const DemoSectionWrapper = styled(SectionWrapper)`
  padding: 50px 5px 5px 40px;
  min-width: 1000px;
  height: 500px;

  @media (max-width: 600px) {
    margin-top: 0px;
    min-width: 300px;
    height: calc(var(--vh, 1vh) * 76);
    max-width: 350px;
    padding: 10px 5px 5px 10px;
  }
`;
