import React from "react";
import { styled } from "styled-components";
import Close from "@/common/images/group.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(11, 47, 69, 0.6);
  z-index: 90;
`;

const Box = styled.div`
  min-width: 320px;
  position: fixed;
`;

const Card = styled.div`
  border-radius: 10px;
  border: 1px solid grey;
  background: white;
  padding: 10px;
  max-height: calc(var(--vh, 1vh) * 90);
  overflow: hidden;
  overflow-y: auto;
`;

const PopUp = ({ setClose = () => {}, children, closeIcon = true }) => {
  return (
    <Wrapper>
      <div
        onClick={() => setClose((prev) => !prev)}
        style={{ width: "100%", height: "100%" }}
      ></div>
      <Box>
        <Card>
          {closeIcon && (
            <div
              style={{
                position: "absolute",
                right: "20px",
              }}
            >
              <img
                src={Close}
                alt="close"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "14px",
                  right: "8px",
                }}
                onClick={() => setClose((prev) => !prev)}
              />
            </div>
          )}
          {children}
        </Card>
      </Box>
    </Wrapper>
  );
};

export default PopUp;
