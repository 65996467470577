import React from "react";
import { styled } from "styled-components";

const SaveButton = styled.button`
  background-color: #f44336; /* Kolor tła przycisku Save */
  color: white; /* Kolor tekstu */
  padding: 10px 20px; /* Wielkość przycisku */
  border: none;
  border-radius: 5px; /* Zaokrąglenie krawędzi */
  font-size: 14px;
  cursor: pointer;
`;

const CustomSaveButton = ({ text = "Save" }) => {
  return <SaveButton type="submit">{text}</SaveButton>;
};

export default CustomSaveButton;
