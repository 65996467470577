import React, { useEffect, useRef, useState } from "react";
import Verify from "@/common/images/verify.svg";
import {
  Name,
  OpinionElement,
  OpinionImage,
  OpinionsWrapper,
  OpinionsWrapperMobile,
  PersonWrapper,
  Text,
  Title,
  Wrapper,
} from "./opinionSection.styles";
import { OPINION_CONFIG } from "./opinionSection.constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const OpinionsSection = () => {
  return (
    <Wrapper>
      <Title>Recenzje naszych użytkowników 💬</Title>
      <OpinionsWrapper>
        {OPINION_CONFIG.map(({ text, image, name }) => (
          <OpinionElement>
            <Text>{text}</Text>
            <PersonWrapper>
              <img src={image} alt={name} />
              <Name>{name}</Name>
              <OpinionImage src={Verify} alt="verify" />
            </PersonWrapper>
          </OpinionElement>
        ))}
      </OpinionsWrapper>
      <OpinionsWrapperMobile>
        <Carousel
          axis="horizontal"
          autoPlay={true}
          infiniteLoop={true}
          width="340px"
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
        >
          {OPINION_CONFIG.map(({ text, image, name }) => (
            <OpinionElement key={text}>
              <Text>{text}</Text>
              <PersonWrapper>
                <img src={image} alt={name} />
                <Name>{name}</Name>
                <OpinionImage src={Verify} alt="verify" />
              </PersonWrapper>
            </OpinionElement>
          ))}
        </Carousel>
      </OpinionsWrapperMobile>
    </Wrapper>
  );
};

export default OpinionsSection;
