import React, { useEffect, useState } from "react";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import {
  ContentWrapper,
  DemoSectionWrapper,
  Height,
  Logo,
  MessageBox,
} from "./talking.styles";

import { TalkiMessage } from "./components";
import { useMutation } from "react-query";
import { createDailyConversation } from "@/api/query/lead-history";
import { Wrapper } from "../home/Home.styles";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Hands, { SOURCES_TYPES } from "@/common/components/customer/Hands";
import { Link } from "react-router-dom";
import { findCustomer } from "@/api/query/customer";
import Henry from "./images/Henry_avatar.png";
import Leo from "./images/Leo_avatar.png";
import Ruby from "./images/Ruby_avatar.png";
import Sarah from "./images/Sarah_avatar.png";

const TitleLesson = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 95%;
  border-bottom: 1px solid #d9d9d9;

  @media (max-width: 600px) {
    border-bottom: none;
    flex-direction: column;
  }
`;

const Text = styled.h3`
  color: #3280fc;

  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;
  margin: 0;
`;

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 260px;
  gap: 5px;
  color: #868686;

  text-decoration: none;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SPEAKER_OPTIONS = {
  LEO: "Leo",
  HENRY: "Henry",
  SARAH: "Sarah",
  RUBY: "Ruby",
};

const Talking = ({ setPageNumber }) => {
  const [dailyConversation, setDailyConversation] = useState();
  const [data, setData] = useState({});
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);
  const [user, setUser] = useState({});

  const handleDailyConversationMutation = useMutation({
    mutationFn: () => createDailyConversation(),
    onSuccess: (data) => {
      setDailyConversation(data);
    },
  });

  const getCustomerMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });

  useEffect(() => {
    handleDailyConversationMutation.mutate();
    getCustomerMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = "Sarai - chat";
  }, []);

  return (
    <Wrapper>
      <TitleLesson>
        <BackWrapper to="/learn">
          <FontAwesomeIcon icon={faChevronLeft} /> Back
        </BackWrapper>
        <Text>Chat</Text>
        <Hands type={SOURCES_TYPES.DAILY} />
      </TitleLesson>
      <DemoSectionWrapper>
        <ContentWrapper>
          <Logo>
            <img src={user?.speaker?.icon?.base64} />
          </Logo>
          <DemoConversationContext.Provider
            value={{
              data,
              setData,
              showButtoReccord,
              setShowButtoReccord,
              showLoading,
              setShowLoading,
              setPageNumber,
              showText2,
              setShowText2,
              dailyConversation,
            }}
          >
            <MessageBox id="chat-box">
              <TalkiMessage />
              <div id="bottom-info" />
            </MessageBox>
          </DemoConversationContext.Provider>
        </ContentWrapper>
      </DemoSectionWrapper>
    </Wrapper>
  );
};

export default Talking;
