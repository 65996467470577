import React, { useEffect } from "react";
import styled from "styled-components";

import ErrorImage from "../icons/delete.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
`;
const Title = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
`;

const SubTitle = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  max-width: 300px;
`;

const ErrorImageWrapper = styled.div`
  border-radius: 103px;
  background: #f96565;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const Warning = ({ setShow, setView, setUser, setPlan }) => {
  useEffect(() => {
    setTimeout(() => {
      const user = localStorage.getItem("user_register");
      const plan = localStorage.getItem("register_order");

      if (user && plan) {
        setPlan(JSON.parse(plan));
        setUser(JSON.parse(user));
        setShow(null);
        setView(2);
      }
    }, 3000);
  }, []);

  return (
    <Wrapper>
      <ErrorImageWrapper>
        <img src={ErrorImage} style={{ width: "60%", height: "auto" }} />
      </ErrorImageWrapper>

      <Title>Płatność zakończona niepowodzeniem.</Title>
      <SubTitle>
        Zachwilę zostaniesz przekierowany na stronę płatności.
      </SubTitle>
    </Wrapper>
  );
};

export default Warning;
