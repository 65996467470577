import {
  addMailLeadHistory,
  clickBuyOnBuyPage,
} from "@/api/query/lead-history";
import PopUp from "@/common/components/PopUp";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  /* H3 */
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

const Wrapper = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    width: 320px;
  }
`;

const StopInfo = styled.p`
  color: #0b2f45;
  text-align: center;
  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
`;

const Info = styled.div`
  color: #0b2f45;
  width: 700px;
  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */

  @media (max-width: 600px) {
    width: 300px;
    text-align: center;
    color: #05275d;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 10px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LetMailButton = styled.button`
  display: flex;
  width: 188px;
  height: 60px;
  padding: 21px 43px 24px 44px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #3280fc;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  /* Button */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.1);
  }
`;

const Label = styled.label`
  width: 55px;
  height: 22px;
  flex-shrink: 0;
  color: #0b2f45;
  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
`;

const Input = styled.input`
  width: 466px;
  padding: 10px;
  flex-shrink: 0;
  border-radius: 16px;
  background: rgba(79, 167, 180, 0.2);
  border: none;
  outline: none;

  @media (max-width: 600px) {
    width: 280px;
  }
`;

const PricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  margin: 10px 0;
`;

const FlexElement = styled.div`
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }

  display: flex;
  gap: 5px;
  margin: 0 30px;
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

const TextTop = styled.div`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const CONFIG_DATA = {
  _1: {
    price: "99,99",
    months: "1 miesiąc",
    full_price: "99,99",
  },
  _2: {
    price: "49,99",
    months: "3 miesiące",
    full_price: "149,97",
  },
  _3: {
    price: "39,99",
    months: "6 miesięcy",
    full_price: "239,94",
  },
};

const TextPrice = styled.p`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;

  h3 {
    color: #05d698;
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.96px;
    margin: 0;
  }
`;

const TextBottom = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
`;

const BuyPopUp = ({ setClose, price }) => {
  const [view, setView] = useState(1);
  const [selectedOption, setSelectedOption] = useState(CONFIG_DATA["_3"]);

  const emailRef = useRef();

  const addEmailMutation = useMutation({
    mutationFn: (value) => addMailLeadHistory(value),
  });

  const clickBuyOnBuyPageMutation = useMutation({
    mutationFn: (value) => clickBuyOnBuyPage(value),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    window.gtag("event", "complete_registration", { price: price });
    window?.fbq("track", "CompleteRegistration");
    window?.uetq.push("event", "subscribe", {});

    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");
    payload.email = email;

    addEmailMutation.mutate(payload);
    setClose(false);
  };

  const handleNextPage = (e) => {
    e && e.preventDefault();

    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");
    payload.price = price;

    window.gtag("event", "initiate_checkout");
    window?.fbq("track", "InitiateCheckout");

    clickBuyOnBuyPageMutation.mutate(payload);
    setView(2);
  };

  return (
    <PopUp setClose={setClose}>
      {view === 2 && (
        <Wrapper>
          <Title>Wyjątkowe zainteresowanie SarAI! 🎉</Title>
          <Info>
            Ze względu na ogromne zainteresowanie, w tej chwili mamy{" "}
            <b>wyprzedane wszystkie dostępne subskrypcje.</b> <br></br>{" "}
            <br></br>
            Zostaw swój adres e-mail. Damy Ci znać, kiedy będziemy gotowi
            przyjąć nowych użytkowników!
          </Info>
          <ActionWrapper onSubmit={(e) => handleSubmit(e)}>
            <InputWrapper>
              <Label>Email</Label>
              <Input ref={emailRef} type="email" required />
            </InputWrapper>
            <LetMailButton type="submit">ZAPISZ SIE</LetMailButton>
          </ActionWrapper>
        </Wrapper>
      )}
      {view === 1 && (
        <Wrapper>
          <Title>Wybierz plan dla siebie</Title>
          <Info style={{ textAlign: "left", marginLeft: "50px" }}>
            Dostępne plany:
          </Info>
          <PricesWrapper>
            <FlexElement>
              <input
                type="radio"
                name="price"
                id="1-month"
                onClick={() => setSelectedOption(CONFIG_DATA["_1"])}
              />
              <label
                for="1-month"
                style={{
                  fontWeight: selectedOption.price === "99,99" ? "bold" : "",
                }}
              >
                1 miesiąc
              </label>
            </FlexElement>
            <FlexElement>
              <input
                id="3-month"
                type="radio"
                name="price"
                onClick={() => setSelectedOption(CONFIG_DATA["_2"])}
              />
              <label
                for="3-month"
                style={{
                  fontWeight: selectedOption.price === "44,99" ? "bold" : "",
                }}
              >
                3 miesiące{" "}
                <span style={{ color: "#05d698", fontWeight: "bold" }}>
                  (-50%)
                </span>
              </label>
            </FlexElement>
            <FlexElement>
              <input
                type="radio"
                id="6-month"
                name="price"
                onClick={() => setSelectedOption(CONFIG_DATA["_3"])}
                defaultChecked
              />
              <label
                for="6-month"
                style={{
                  fontWeight: selectedOption.price === "33,99" ? "bold" : "",
                }}
              >
                6 miesięcy{" "}
                <span style={{ color: "#05d698", fontWeight: "bold" }}>
                  (-60%)
                </span>
              </label>
            </FlexElement>
          </PricesWrapper>
          <div>
            {selectedOption.price != "99,99" && (
              <TextTop
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                Zamiast{" "}
                <p
                  style={{
                    textDecoration: "1px line-through red",
                    margin: "0",
                  }}
                >
                  99,99
                </p>{" "}
                zł/mies tylko
              </TextTop>
            )}
            <TextPrice>
              <h3>{selectedOption.price}</h3> zł/mies
            </TextPrice>
          </div>
          <ActionWrapper onSubmit={(e) => handleNextPage(e)}>
            <LetMailButton type="submit" style={{ fontSize: "22px" }}>
              KUP
            </LetMailButton>
          </ActionWrapper>
          <div>
            <TextBottom>
              {selectedOption.full_price} zł pobrane co {selectedOption.months}
            </TextBottom>
          </div>
        </Wrapper>
      )}
    </PopUp>
  );
};

export default BuyPopUp;
