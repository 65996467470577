import React, { useCallback, useContext, useEffect, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { useLongPress } from "use-long-press";
import styled from "styled-components";
import { Color } from "../colors/colors";
import { IP_STATS_ENUM } from "../enums/ipStatsEnum";
import { IpStatsContext } from "../contexts/IpStatsContext";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const RecordingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.Blue};
  padding: 10px 20px 10px 20px;
  border-radius: 100px;
  color: white;
  margin-bottom: 10px;

  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 5px;
`;

const Microfon = ({ handleSend }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const { handleSendIPstats } = useContext(IpStatsContext);

  const callback = useCallback(() => {
    start();
  }, []);

  const bind = useLongPress(callback, {
    onStart: (event, meta) => {},
    onFinish: (event, meta) => {
      stop();
    },
    onTouchStart: () => {
      start();
    },
    onTouchEnd: () => {
      stop();
    },
    threshold: 100,
  });

  const start = () => {
    if (isBlocked) {
      alert("Please allow your microfon");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(() => true);
        })
        .catch((e) => alert("Please allow your microphone"));
    }
  };

  const stop = () => {
    handleSendIPstats(IP_STATS_ENUM.TALKING_IN_FOTO_MODE);
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File([blob], "to_translate.mp3", {
          type: "audio/mpeg",
          lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        formData.append("file", file);
        handleSend(formData);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Permission Granted");
        setIsBlocked(() => false);
      })
      .catch((err) => {
        console.log("Permission Denied");
        console.log(err);
        setIsBlocked(() => true);
      });
  }, []);

  return (
    <RecordingButton
      {...bind()}
      style={{
        background: isRecording ? "red" : Color.Blue,
        cursor: "pointer",
      }}
      type="button"
    >
      {isRecording ? "Recording..." : "Record"}
    </RecordingButton>
  );
};

export default Microfon;
