import Api from "../api";

const { axios: api } = Api;
const PREFIX = "lead-history";

export const createLeadHistory = async (payload) => {
  const data = await api.post(`${PREFIX}`, { utm: payload });
  return data.data;
};

export const createConversation = async (payload) => {
  const data = await api.post(
    `customer-conversation/start-conversation`,
    payload
  );
  return data.data;
};

export const createConversationWord = async (payload) => {
  const data = await api.post(
    `customer-conversation/start-conversation-word`,
    payload
  );
  return data.data;
};

export const createDailyConversation = async () => {
  const data = await api.post(
    `customer-conversation/create-daily-conversation`
  );
  return data.data;
};

export const handleDeleteLessonMessage = async (payload) => {
  const data = await api.delete(
    `customer-conversation/delete-lesson-message/${payload.lessonId}`,
    payload
  );
  return data.data;
};

export const handleDeleteLessonMessageWord = async (payload) => {
  const data = await api.delete(
    `customer-conversation/delete-lesson-message-word/${payload.lessonId}`,
    payload
  );
  return data.data;
};

export const updateLeadHistory = async (leadId, payload) => {
  const data = await api.patch(`/${PREFIX}/${leadId}`, payload);
  return data.data;
};
export const clickBunner = async (leadId) => {
  const data = await api.patch(`/${PREFIX}/banner/${leadId._id}`);
  return data.data;
};

export const startCheckout = async (leadId) => {
  const data = await api.patch(`/${PREFIX}/start-checkout/${leadId._id}`);
  return data.data;
};

export const clickStartDemoOnBuyPage = async (leadId) => {
  const data = await api.patch(
    `/${PREFIX}/start-demo-on-buy-page/${leadId._id}`
  );
  return data.data;
};

export const updateLeadHisotryClickStartTrail = async (payload) => {
  const data = await api.patch(`/${PREFIX}/start-trial/${payload._id}`);
  return data.data;
};

export const updateLeadHisotryClickReady = async (payload) => {
  const data = await api.patch(`/${PREFIX}/click-ready/${payload._id}`);
  return data.data;
};

export const addMailLeadHistory = async (payload) => {
  const data = await api.patch(`/${PREFIX}/email/${payload._id}`, payload);
  return data.data;
};

export const clickedButtonBuy = async (payload) => {
  const data = await api.patch(`/${PREFIX}/clicked-buy/${payload._id}`);
  return data.data;
};

export const sentMessageByText = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/sent-message-by-text/${payload._id}`
  );
  return data.data;
};

export const clickBuyOnBuyPage = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/click-by-on-by-page/${payload._id}`,
    { price: payload.price }
  );
  return data.data;
};

export const clickedMicrophone = async (payload) => {
  const data = await api.patch(`/${PREFIX}/clicked-microphone/${payload._id}`);
  return data.data;
};

export const wasOnBuyPage = async (payload) => {
  const data = await api.patch(`/${PREFIX}/was-on-buy/${payload._id}`);
  return data.data;
};
