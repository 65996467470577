import DemoScript from "./components/DemoScript/DemoScript";
import DemoSummarize from "./components/DemoSummarize/DemoSummarize";

export const WORKFLOW_CONFIG = [
  {
    id: 1,
    component: (setPageNumber) => <DemoScript setPageNumber={setPageNumber} />,
  },
  {
    id: 2,
    component: (setPageNumber) => (
      <DemoSummarize setPageNumber={setPageNumber} />
    ),
  },
];

export const PERMISION_OPTIONS = {
  GRANTED: "granted",
  DENIED: "denied",
  PROMT: "prompt",
};
