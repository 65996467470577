import React from "react";
import {
  StartLearningLinkMobile,
  SubTitleMobile,
  Subtitle,
  Title,
  Wrapper,
} from "./headSection.styles";

const HeadSection = () => {
  return (
    <Wrapper>
      <Title>Twój spersonalizowany plan mówienia jest już gotowy 🎉</Title>
      <SubTitleMobile>
        Pokonaj lęk przed mówieniem po angielsku z SarAI.
      </SubTitleMobile>
      <Subtitle>
        Zacznij konwersacje z SarAI zgodnie ze spersonalizowanym planem krok po
        kroku.
      </Subtitle>
      <Subtitle>SarAI jest dostosowany do Twoich wyników i potrzeb.</Subtitle>
      <StartLearningLinkMobile href={"#plan-offer"}>
        Zacznij naukę
      </StartLearningLinkMobile>
    </Wrapper>
  );
};

export default HeadSection;
