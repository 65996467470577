import React, { useContext, useEffect, useRef, useState } from "react";
import SendIcon from "@/common/images/icon-send-2.svg";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { useMutation } from "react-query";
import { updateLeadHistory } from "@/api/query/lead-history";
import { CONVERSATION_VIEWS_ENUM } from "../../demoScript.enums";
import BotMessage from "../Message/BotMessage";
import { useTypeWritterFlow } from "@/common/hooks/useTypeWritterFlow";
import { Howl } from "howler";
import {
  IconSend,
  IconSendWrapper,
  InputWrapper,
  NameInput,
} from "./nameMessage.styles";

const NameMessage = () => {
  const {
    setConversationParts,
    setData,
    data,
    RecordingSection2,
    RecordingSection2a,
  } = useContext(DemoConversationContext);
  const [showAction, setShowAction] = useState();

  const nameRef = useRef();
  const [redBorder, setRedBorder] = useState(false);

  const updateLeadMutation = useMutation({
    mutationFn: (value) => updateLeadHistory(value._id, value),
  });

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleAddName(e);
    }
  };

  const handleAddName = (e) => {
    e && e.preventDefault();
    if (data?.name) return;

    const name = nameRef?.current?.value;

    if (!name) {
      return setRedBorder(true);
    }
    localStorage.setItem("lead-history-name", name);

    window.gtag("event", "click_question_2");

    const payload = data;
    payload.name = name;
    payload._id = localStorage.getItem("lead-history-id");

    updateLeadMutation.mutate(payload);

    setRedBorder(() => false);
    setData((prev) => ({ ...prev, name }));
    setConversationParts(CONVERSATION_VIEWS_ENUM.AGE);
  };

  const {
    start,
    results: [text1, text2],
  } = useTypeWritterFlow({
    flowItems: [
      {
        text: `Great, let's start! `,
        typing: 42,
        audio: new Howl({
          src: [RecordingSection2],
        }),
      },
      {
        text: `Firstly, could you please tell me your name? It would be great to address you properly during our conversations. `,
        audio: new Howl({
          src: [RecordingSection2a],
        }),
      },
    ],
    onEnd: () => {
      const element = document.getElementById("section-1");
      element.scrollIntoView({ behavior: "smooth" });
      setShowAction(true);
    },
  });

  useEffect(() => {
    start()();
  }, []);

  useEffect(() => {
    if (showAction) {
      if (window.navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        nameRef.current.focus();
      }
    }
  }, [showAction]);

  return (
    <>
      <BotMessage
        text={text1}
        translation={`Świetnie, zaczynajmy!`}
        showTranslateIcon={showAction}
        id="section-1"
      />
      <BotMessage
        text={text2}
        translation={` Po pierwsze, czy mógłbyś mi podać swoje imię? Byłoby fajnie móc się do
        Ciebie właściwie zwracać podczas naszych rozmów.`}
        showTranslateIcon={showAction}
      />
      {showAction && (
        <InputWrapper redBorder={redBorder} className="fade__in__animation">
          <NameInput
            id="name-input"
            ref={nameRef}
            placeholder="Write your name here..."
            disabled={data?.name}
            onKeyDown={(e) => onEnterPress(e)}
          />
          <IconSendWrapper>
            <IconSend src={SendIcon} onClick={(e) => handleAddName(e)} />
          </IconSendWrapper>
        </InputWrapper>
      )}
    </>
  );
};

export default NameMessage;
