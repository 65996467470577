import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { styled } from "styled-components";

const EditButton = styled.button`
  background-color: #2196f3; /* Kolor tła przycisku edycji */
  color: white; /* Kolor tekstu */
  padding: 8px 16px; /* Wielkość przycisku */
  border: none;
  border-radius: 5px; /* Zaokrąglenie krawędzi */
  cursor: pointer;
  font-size: 14px;
`;

const CustomEditButton = ({ text, onClick = () => {} }) => {
  return (
    <EditButton type="button" onClick={onClick}>
      {text || <FontAwesomeIcon icon={faEdit} />}
    </EditButton>
  );
};

export default CustomEditButton;
