import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import { getTranslation } from "@/api/query/workflow";
import BotMessage from "../Message/BotMessage";
import TalkingMicrofon from "./TalkingMicrofon";
import UserMessage from "./UserMessage";
import BotMessageTalking from "./BotMessageTalking";

export const TALKING_ROLES = {
  USER: "user",
  BOT: "bot",
};

export const TextInformationWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0;
`;

const TalkiMessage = () => {
  const [conversationWithChatGPT, setConversationWithChatGPT] = useState([]);
  const [current, setCurrent] = useState();
  const [showMessagesTranslations, setShowMessagesTranslations] = useState([]);
  const [translationMessages, setTrnaslationMessages] = useState([]);

  const translationMutation = useMutation({
    mutationFn: (value) => getTranslation(value),
    onSuccess: (value) => {
      setShowMessagesTranslations((prev) => [...prev, value.name]);
      setTrnaslationMessages((prev) => [
        ...prev,
        { name: value.name, data: value.data },
      ]);
    },
  });

  const handleGetTranlationForBot = useCallback(
    (name, message) => {
      const translation = translationMessages.find((tm) => tm.name === name);

      if (translation) {
        if (showMessagesTranslations.includes(name)) {
          setShowMessagesTranslations((prev) => [
            ...prev.filter((name) => name !== name),
          ]);
        } else {
          setShowMessagesTranslations((prev) => [...prev, name]);
        }

        return;
      }

      const payload = {};
      payload.message = message;
      payload.name = name;

      translationMutation.mutate(payload);
    },
    [translationMessages, showMessagesTranslations]
  );

  const previousMessages = useMemo(
    () =>
      conversationWithChatGPT.map((element, i) =>
        element.role === TALKING_ROLES.BOT ? (
          <BotMessageTalking
            key={i}
            id={i}
            message={element.message}
            translationMessages={translationMessages}
            showMessagesTranslations={showMessagesTranslations}
            handleGetTranlationForBot={handleGetTranlationForBot}
          />
        ) : (
          <UserMessage message={element.message} key={i} />
        )
      ),
    [
      conversationWithChatGPT,
      translationMessages,
      handleGetTranlationForBot,
      showMessagesTranslations,
    ]
  );

  const currentRef = useRef();

  useEffect(() => {
    const element = document.getElementById(`bottom-info`);
    element?.scrollIntoView({ behavior: "smooth" });
  }, [
    current,
    conversationWithChatGPT.length,
    currentRef?.current?.clientHeight,
  ]);

  return (
    <>
      {previousMessages}
      {current && (
        <div
          ref={currentRef}
          style={{
            minHeight: "60px",
          }}
        >
          <BotMessage text={current} showTranslateIcon={false} />
        </div>
      )}
      <TalkingMicrofon
        setCurrent={setCurrent}
        setConversationWithChatGPT={setConversationWithChatGPT}
        conversationWithChatGPT={conversationWithChatGPT}
      />
    </>
  );
};

export default TalkiMessage;
