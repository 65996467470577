import { styled } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 18px;

  @media (min-height: 530px) {
    height: calc(var(--vh, 1vh) * 70);
  }

  @media (min-width: 600px) {
    flex-direction: column;
    gap: 32px;
    height: calc(var(--vh, 1vh) * 100 - 67px);
    margin: 0 auto;
  }
`;

export const Logo = styled.img`
  width: 95px;
  height: 31px;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const Card = styled.form`
  width: 370px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  gap: 36px;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: auto;

  @media (min-height: 530px) {
    align-items: center;
  }

  @media (min-width: 600px) {
    background-color: #fff;
    width: 100%;
    flex-grow: 1;
    gap: 80px;
    max-width: 1200px;
    padding: 18px;
    box-sizing: border-box;
  }
`;

export const RobotWrapper = styled.div`
  width: 18%;
  position: absolute;
  left: -84px;
  top: 50%;
  transform: translateY(-70%);

  @media (max-width: 599px) {
    display: none;
  }
`;

export const LoginDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  position: relative;
  width: 60%;
`;

export const Title = styled.h3`
  color: #05275d;
  margin: 0;
  text-align: center;
  font-family: Work Sans;
  font-size: 36px;
  font-weight: 700;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const Label = styled.label`
  color: #868686;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  gap: 8px;
  position: relative;
`;

export const Input = styled.input`
  display: flex;
  width: 320px;
  padding: 15px 40px 15px 15px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: white;
  border: none;

  @media (min-width: 600px) {
    border: 1px solid #aba9a9;
  }

  @media (max-width: 599px) {
    width: 280px;
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;

    + div {
      color: #05275d;
    }
  }
`;

export const EyeIconWrapper = styled.div`
  color: #aba9a9;
  font-size: 22px;
  position: absolute;
  right: 12px;
  bottom: 10px;
  cursor: pointer;

  &:active {
    color: #05275d;
  }

  @media (min-width: 600px) {
  }
`;

export const SubmitButton = styled.button`
  display: inline-flex;
  padding: 17px 65px 14px 65px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #3280fc;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #3280fc;
  border: none;
  color: #fff;
  flex-shrink: 0;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid #3280fc;
  width: fit-content;

  &:hover {
    color: #3280fc;
    background: #fff;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin: 0;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const RegisterLink = styled.a`
  color: #3280fc;
  font-family: Work Sans;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
`;
