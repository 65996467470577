import React from "react";
import { CONFIG } from "./whatYouWillGetSection.constants";
import {
  ContentDisplay,
  CustomImage,
  Element,
  StartLearningLink,
  TextElemenet,
  TextElemenetDown,
  Title,
  TitleElement,
  Wrapper,
} from "./whatYouWillGetSection.styles";

const WhatYouWillGetSection = () => {
  return (
    <Wrapper>
      <Title>Co otrzymujesz?</Title>
      <ContentDisplay>
        {CONFIG.map(({ image, text, title }) => (
          <Element key={title}>
            <CustomImage src={image} alt="Sarai" />
            <TitleElement>{title}</TitleElement>
            <TextElemenetDown>{text}</TextElemenetDown>
          </Element>
        ))}
      </ContentDisplay>
      <StartLearningLink href={"#plan-offer"}>Zacznij naukę</StartLearningLink>
    </Wrapper>
  );
};

export default WhatYouWillGetSection;
