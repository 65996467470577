import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "native-language";

export const createNativeLanguages = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateNativeLanguages = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const findNativeLanguages = async (payload) => {
  const data = await api.get(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const changeUserNativeLanguage = async (payload) => {
  const data = await api.patch(`/${PREFIX}/customer-lang`, payload);
  return { data: data.data };
};
