import React, { useEffect, useRef, useState } from "react";
import UserProile from "@/common/icons/user-profile.svg";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import {
  findCustomer,
  subscribe,
  unsubscribe,
  updateCustomerImage,
  updateCustomerLevel,
} from "@/api/query/customer";
import IconLogin from "@/common/images/icon-login.svg";
import { useNavigate } from "react-router-dom";
import userManager from "@/api/userManager";
import api from "@/api/api";
import moment from "moment";
import { getBase64 } from "@/common/functions/getBase64";
import {
  Card,
  ImageWrapper,
  Logout,
  LogoutWrapper,
  MainDataWrapper,
  Title,
  UserDataWrapper,
  UserNameWrapper,
  Wrapper,
} from "./profile.styles";
import styled from "styled-components";
import CancelPopUp from "./components/CancelPopUp";
import "react-input-range/lib/css/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";
import editProfileImage from "./images/profil-frame-317.svg";
import ChangeName from "./components/ChangeName";
import ChangePasswordComponent from "./components/ChangePasswordComponent";
import ArrowRight from "./images/arrow-right.svg";
import ArrowDown from "./images/arrow-down.svg";
import ArrowUp from "./subpages/images/arrow-up.svg";
import ProfileChange from "./components/ProfileChange";

const { tokenManager } = api;

const ChangePassword = styled.a`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  margin: 0;
`;

const EmialWrapper = styled.div`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  margin: -2px 0px;
`;

const Section = styled.div`
  width: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    width: 500px;
  }
`;

const SectionTittle = styled.p`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  margin: 0;
`;

const SectionSubtitle = styled.p`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 139%; /* 19.46px */
  letter-spacing: 0.28px;
  margin: 0;
`;

const Border = styled.div`
  border-bottom: 1px solid rgba(79, 167, 180, 0.2);
`;

const ImageWrapperMenu = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 100%;
    height: auto;
  }

  color: #05275d;
  text-align: right;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const FlexOne = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const SECTIONS = {
  SUBSCRIPTION: "subscription",
};

const Profile = () => {
  const navigate = useNavigate();
  const [showSection, setShowSection] = useState();

  const [showCancelPopup, setShowCancelPopup] = useState();
  const [user, setUser] = useState({});
  const [changeName, setChangeName] = useState();
  const [changePasswordValue, setChangePasswordValue] = useState();
  const [profileChange, setProfileChange] = useState();

  const loadDataMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });

  const unsubscribeMutation = useMutation({
    mutationFn: () => unsubscribe(),
    onSuccess: ({ data }) => {
      loadDataMutation.mutate();
      setShowCancelPopup(null);
    },
  });

  const updateUserImageMutation = useMutation({
    mutationFn: (value) => updateCustomerImage(value),
    onSuccess: ({ data }) => {
      setUser(() => data);
    },
  });

  const handleLogout = () => {
    tokenManager.removeToken();
    userManager.removeUser();
    navigate(getNavigationPath("/login"));
  };

  useEffect(() => {
    if (!profileChange) {
      loadDataMutation.mutate();
    }
  }, [profileChange]);

  useEffect(() => {
    window.document.title = "Sarai - profil";
  }, []);

  const getDiff = (date) => {
    const now = moment();
    const to = moment(date);

    return Math.max(to.diff(now, "days"));
  };

  return (
    <>
      {(loadDataMutation.isLoading || updateUserImageMutation.isLoading) && (
        <Loading />
      )}
      {showCancelPopup && (
        <CancelPopUp
          setClose={setShowCancelPopup}
          handleSave={unsubscribeMutation}
        />
      )}
      {changeName && <ChangeName setClose={setChangeName} />}
      {changePasswordValue && (
        <ChangePasswordComponent setClose={setChangePasswordValue} />
      )}
      {profileChange && <ProfileChange setClose={setProfileChange} />}
      <Wrapper>
        <MainDataWrapper>
          <Title>Settings</Title>
          <div
            style={{ position: "relative" }}
            onClick={() => setProfileChange(true)}
          >
            <div
              style={{
                position: "absolute",
                zIndex: "1",
                bottom: "-8px",
                right: "0",
                cursor: "pointer",
              }}
            >
              <img src={editProfileImage} />
            </div>
            <ImageWrapper>
              <img
                alt=""
                src={user?.user_profile?.base64 || UserProile}
                style={{ width: "100%", height: "auto", cursor: "pointer" }}
              />
            </ImageWrapper>
          </div>
          <UserDataWrapper>
            <UserNameWrapper>
              {user?.name} {user?.surname}{" "}
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  color: "rgba(50, 128, 252, 1)",
                  fontSize: "14px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setChangeName(true)}
              />
            </UserNameWrapper>
            <EmialWrapper>{user?.email}</EmialWrapper>
            <ChangePassword onClick={() => setChangePasswordValue(true)}>
              Change password
            </ChangePassword>
          </UserDataWrapper>
        </MainDataWrapper>
        <Card>
          <Section onClick={() => navigate("/profile/learning")}>
            <SectionTittle>Learning</SectionTittle>
            <ImageWrapperMenu>
              <img src={ArrowRight} />
            </ImageWrapperMenu>
          </Section>
          <Border />
          <Section onClick={() => navigate("/profile/tutor")}>
            <SectionTittle>Tutor</SectionTittle>
            <ImageWrapperMenu>
              {user?.speaker?.name}
              <img src={ArrowRight} />
            </ImageWrapperMenu>
          </Section>
          <Border />
          <Section onClick={() => navigate("/profile/interests")}>
            <SectionTittle>Interests</SectionTittle>
            <ImageWrapperMenu>
              <img src={ArrowRight} />
            </ImageWrapperMenu>
          </Section>
          <Border />
          <Section
            onClick={() =>
              setShowSection((prev) =>
                prev === SECTIONS.SUBSCRIPTION ? null : SECTIONS.SUBSCRIPTION
              )
            }
          >
            <SectionTittle>Subscription</SectionTittle>
            <ImageWrapperMenu>
              Active
              <img
                src={
                  showSection === SECTIONS.SUBSCRIPTION ? ArrowUp : ArrowDown
                }
              />
            </ImageWrapperMenu>
          </Section>
          {showSection === SECTIONS.SUBSCRIPTION && (
            <Section
              onClick={() =>
                setShowSection((prev) =>
                  prev === SECTIONS.SUBSCRIPTION ? null : SECTIONS.SUBSCRIPTION
                )
              }
            >
              <div style={{ textAlign: "left", marginLeft: "20px" }}>
                <SectionSubtitle>
                  Active to:{" "}
                  <b>
                    {user?.order?.expired_date &&
                      moment(user?.order?.expired_date).format("DD/MM/YYYY")}
                  </b>
                </SectionSubtitle>
                <SectionSubtitle>
                  Your plan will end in:{" "}
                  <b>{getDiff(user?.order?.expired_date)} days</b>
                </SectionSubtitle>
              </div>
            </Section>
          )}
          <Border />
          <Section onClick={() => navigate("/profile/help")}>
            <SectionTittle>Help</SectionTittle>
            <ImageWrapperMenu>
              <img src={ArrowRight} />
            </ImageWrapperMenu>
          </Section>
          <Border />
          <FlexOne style={{ flex: 1 }}></FlexOne>
          <Section>
            <LogoutWrapper onClick={() => handleLogout()}>
              <div
                style={{ width: "16px", display: "flex", alignItems: "center" }}
              >
                <img
                  alt=""
                  src={IconLogin}
                  style={{ width: "100%", height: "auto" }}
                />{" "}
              </div>
              <Logout>Log out</Logout>
            </LogoutWrapper>
          </Section>
        </Card>
      </Wrapper>
    </>
  );
};

export default Profile;
