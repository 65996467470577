import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import AdminCategoryEdit from "./components/AdminCategoryEdit";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { deleteCategory, findCategories } from "@/api/query/category";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const HEADERS = [
  "No.",
  "Name",
  "Value",
  "Lessons",
  "Image",
  "Is Block",
  "Actions",
];

const AdminCategory = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const deleteCategoryMutation = useMutation({
    mutationFn: (payload) => deleteCategory(payload),
    onSuccess: ({ data }) => {
      findLessonsMutation.mutate();
    },
  });

  const handleDeleteCategory = (d) => {
    const payload = {};
    payload._id = d._id;

    deleteCategoryMutation.mutate(payload);
  };

  const [edit, setEdit] = useState();

  const findLessonsMutation = useMutation({
    mutationFn: () => findCategories(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => [
          i + 1,
          d.name,
          d.value,
          d.lessons?.length,
          <div
            style={{
              width: "50px",
              height: "50px",
              overflow: "hidden",
              borderRadius: "50%",
            }}
          >
            <img
              alt=""
              src={d.image?.base64}
              style={{ width: "100%", height: "auto" }}
            />
          </div>,
          d.isBlock ? "true" : "false",
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton onClick={(e) => setEdit(d)} />
            <CustomDeleteButton onClick={(e) => handleDeleteCategory(d)} />
          </div>,
        ])
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findLessonsMutation.mutate();
    }
  }, [edit]);

  return (
    <Wrapper>
      {edit && <AdminCategoryEdit edit={edit} setEdit={setEdit} />}
      <Title>Category</Title>
      <TopContent>
        <CustomAddButton onClick={() => setEdit({})} />
      </TopContent>
      <TableWrapper>
        <CustomTable headers={HEADERS} data={tableData} />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminCategory;
